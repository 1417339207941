import { Checkbox, Input } from "antd";
import { SearchProps } from "antd/es/input";
import { useState } from "react";

const { Search } = Input;

interface IProps {
  formModal: any;
  isView?: boolean;
  optionRole: any;
  setSearchValue: (item: string) => void;
  fieldsRole: any;
  setFieldsRole: (item: any) => void;
}

const PermissionCheckInDrawer = ({
  formModal,
  isView,
  optionRole,
  setSearchValue,
  fieldsRole,
  setFieldsRole,
}: IProps) => {
  const [searchText, setSearchText] = useState<string>("");

  const onChangeSelect = (e: any, users: any) => {
    if (!isView) {
      const itemExists = fieldsRole?.some(
        (item: { id: any }) => item.id === e.target.value
      );
      if (itemExists) {
        // Loại bỏ phần tử có id đã tồn tại
        setFieldsRole(
          fieldsRole?.filter((item: { id: any }) => item.id !== e.target.value)
        );
      } else {
        // Thêm phần tử mới vào danh sách
        setFieldsRole([
          ...fieldsRole,
          { id: e.target.value, users: users?.map((value: any) => value?.id) },
        ]);
      }
    }
  };
  const onChangeSelectInfo = (idGroup: number, e: any) => {
    if (!isView) {
      const newPermission = fieldsRole.map((item: any) => {
        if (item.id === idGroup) {
          const isExitUser = item?.users?.includes(e.target.value);
          // Cập nhật lại giá trị users khi id = 1
          return {
            ...item,
            users: isExitUser
              ? item.users.filter((value: number) => value !== e.target.value) // Loại bỏ value nếu đã có
              : [...item.users, e.target.value], // Thêm value nếu chưa có
          };
        }
        return item; // Giữ nguyên các phần tử khác
      });
      setFieldsRole(newPermission);
    }
  };

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    setSearchValue(value);
    setSearchText(value);
  };
  const handleChangeSearch = (e: any) => {
    setSearchText(e?.target?.value);
  };

  const checkValueInUsers = (idGroup: number, value: number) => {
    // Tìm phần tử có id bằng với id truyền vào
    const item = fieldsRole.find((item: any) => item.id === idGroup);
    if (item) {
      // Kiểm tra xem giá trị có nằm trong mảng users không
      return item.users.includes(value);
    }
    return false; // Nếu không tìm thấy phần tử có id đó
  };

  return (
    <div>
      <Search
        className="mb-2"
        placeholder="Nhập tên vai trò cần tìm"
        onSearch={onSearch}
        value={searchText}
        onChange={handleChangeSearch}
      />
      {optionRole?.map((data: any) => (
        <div className="pt-2">
          <p className="bg-[#FAFAFA] p-4 mt-1 rounded-md">
            <Checkbox
              className="w-full m-0"
              checked={fieldsRole.some((item: any) => item.id === data?.value)}
              name="infoInput"
              value={data?.value}
              onChange={(e) => onChangeSelect(e, data?.users)}
            >
              <p className=" font-medium text-xl">{data?.label}</p>
            </Checkbox>
          </p>

          {fieldsRole.some((item: any) => item.id === data?.value) && (
            <div className="mt-4 overflow-y-auto max-h-[250px]">
              {data?.users?.map((item: any, index: number) => (
                <div className="py-2" key={index}>
                  <div className="max-h-[200px] overflow-auto">
                    <Checkbox
                      className="w-full m-0"
                      checked={
                        checkValueInUsers(data?.value, item?.id) ||
                        item.is_construct
                      }
                      name={item?.id}
                      value={item?.id}
                      onChange={(e) => onChangeSelectInfo(data?.value, e)}
                      disabled={item?.is_construct}
                    >
                      {item?.name}
                    </Checkbox>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PermissionCheckInDrawer;
