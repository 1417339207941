// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-fast-browsing .ant-modal-content .ant-modal-header .ant-modal-title {
  font-weight: 600;
  padding-bottom: 10px; }

.modal-fast-browsing .ant-modal-content .ant-modal-body p {
  display: flex;
  justify-content: center;
  padding-bottom: 30px; }

.modal-fast-browsing .ant-modal-content .ant-modal-body .ant-radio-group {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 15px; }

.modal-fast-browsing .ant-modal-content .ant-modal-footer {
  padding-top: 20px;
  display: flex;
  justify-content: center; }
  .modal-fast-browsing .ant-modal-content .ant-modal-footer .ant-typography {
    display: block;
    font-style: italic;
    color: red;
    margin-bottom: 20px; }
  .modal-fast-browsing .ant-modal-content .ant-modal-footer .footer-modal-button {
    display: flex;
    justify-content: center; }
`, "",{"version":3,"sources":["webpack://./src/components/course-library/ministry-content-moderation/list-exam/filter-table/filter-table.scss"],"names":[],"mappings":"AAAA;EAIQ,gBAAgB;EAChB,oBAAoB,EAAA;;AAL5B;EAYQ,aAAa;EACb,uBAAuB;EACvB,oBAAoB,EAAA;;AAd5B;EAkBQ,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,oBAAoB,EAAA;;AArB5B;EA0BM,iBAAiB;EACjB,aAAa;EACb,uBAAuB,EAAA;EA5B7B;IA+BQ,cAAc;IACd,kBAAkB;IAClB,UAAU;IACV,mBAAmB,EAAA;EAlC3B;IAsCQ,aAAa;IACb,uBAAuB,EAAA","sourcesContent":[".modal-fast-browsing {\n  .ant-modal-content {\n    .ant-modal-header {\n      .ant-modal-title {\n        font-weight: 600;\n        padding-bottom: 10px;\n      }\n    }\n\n    .ant-modal-body {\n\n      p {\n        display: flex;\n        justify-content: center;\n        padding-bottom: 30px;\n      }\n\n      .ant-radio-group {\n        width: 100%;\n        display: flex;\n        justify-content: center;\n        padding-bottom: 15px;\n      }\n    }\n\n    .ant-modal-footer {\n      padding-top: 20px;\n      display: flex;\n      justify-content: center;\n\n      .ant-typography {\n        display: block;\n        font-style: italic;\n        color: red;\n        margin-bottom: 20px;\n      }\n\n      .footer-modal-button {\n        display: flex;\n        justify-content: center;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
