import React, { useState } from "react";
import CommonModal from "../../../../../../../components/modal/common";
import { InfoCircleOutlined } from "@ant-design/icons";
import FormInput from "../../../../../../../components/form-input-login/FormInput";
import { Typography } from "antd";
import { verifyPassword } from "../../../../../../../service/auth";
import { deleteReportAttendance } from "../../../../../../../service/setting-attendance";

interface IProps {
  IsOpenDelete: boolean;
  idReport: string;
  IsConfirmDelete: boolean;
  handleConfirmDelete: () => void;
  onCloseModal: () => void;
  handleNotice: (item: string) => void;
  title: string;
}
const ReportCheckInModal = ({
  IsOpenDelete,
  idReport,
  handleConfirmDelete,
  handleNotice,
  onCloseModal,
  title,
  IsConfirmDelete,
}: IProps) => {
  const [password, setPassWord] = useState("");
  const [checkPass, setCheckPassword] = useState<boolean>(true);

  const handleChange = (value: any) => {
    setPassWord(value);
  };

  const handleSubmitDelete = async () => {
    try {
      const response = await verifyPassword({ password });
      const { data } = response;
      if (data?.statusCode === 200) {
        const res = await deleteReportAttendance(idReport)
        handleNotice("Xoá báo cáo thành công");
        onCloseModal()
      } else {
        setCheckPassword(false);
      }
    } catch (error) {
      setCheckPassword(false);
      console.log(error);
    }
  };
  const cancelModal = () => {
    setPassWord("")
    onCloseModal()
    setCheckPassword(true);
  }
  return (
    <div>
      <CommonModal
        open={IsOpenDelete}
        title={`Xoá báo cáo điểm danh ${title}`}
        desc={
          <div>
            Khoá học đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá
            báo cáo này?
          </div>
        }
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleConfirmDelete}
        closeModal={onCloseModal}
        className={""}
      />
      <CommonModal
        open={IsConfirmDelete}
        title={`Xoá báo cáo điểm danh ${title}`}
        desc={
          <div>
            <FormInput
              type="password"
              value={password}
              title="Mã UserID"
              onChange={handleChange}
              isPassword
            />
          </div>
        }
        closeIcon={true}
        okText={"Xoá báo cáo"}
        cancelText={"Huỷ"}
        icon={
          <InfoCircleOutlined
            style={{ color: "#FFC53D", marginRight: "10px" }}
          />
        }
        confirm={handleSubmitDelete}
        closeModal={cancelModal}
        className={"!w-[465px] report-delete-confirm"}
      />
    </div>
  );
};

export default ReportCheckInModal;
