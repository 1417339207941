import {Col, Empty, Row, Select, Spin, TableColumnsType} from "antd";
import TableWithTotals from "../../../../../../../components/table-with-totals/TableWithTotals";
import {BarStackedChartProps, ClassProps, DualChart,} from "../../../../../../../types/static-report";
import StatisticalReportCard from "../../../StatisticalReportCard";
import {createTableData, rateTransformData} from "../../tableConvertData";
import dayjs from "dayjs";
import {staticReportStore} from "../../../../../../../stores/stores";
import {
  exportRateClassAction,
  exportRateSequenceAction,
  exportRateUnitByClass,
  exportRateUnitByCourse,
  exportRateUnitByModule,
  exportRateUnitBySequence,
  exportUnitDiscuss,
  exportUnitReviewAndAccess,
} from "../../../../../../../service/statistical-report";
import {chartContentTypes, handleExportReport,} from "../../../../../../../utils/staticReport";

type Props = {
  loading: Record<string, boolean>;
  rateUnitByCourse: BarStackedChartProps[];
  rateUnitByModule: BarStackedChartProps[];
  rateUnitBySequence: BarStackedChartProps[];
  unitReviewAndAccess: DualChart[];
  rateSequenceAction: BarStackedChartProps[];
  rateClassAction: BarStackedChartProps[];
  unitDiscuss: BarStackedChartProps[];
  rateUnitByClass: BarStackedChartProps[];
  classList: ClassProps[];
};

const FeedbackAndEvaluationTableTab = (props: Props) => {
  const {
    loading,
    rateUnitByCourse,
    rateUnitByModule,
    rateUnitBySequence,
    unitReviewAndAccess,
    rateSequenceAction,
    rateClassAction,
    unitDiscuss,
    rateUnitByClass,
    classList,
  } = props;
  const {filterValues, setFilterValues} = staticReportStore();

  const rateColumns: TableColumnsType<any> = [
    {
      title: "1 sao",
      dataIndex: "1 sao",
      key: "1 sao",

      width: 100,
    },
    {
      title: "2 sao",
      dataIndex: "2 sao",
      key: "2 sao",

      width: 100,
    },
    {
      title: "3 sao",
      dataIndex: "3 sao",
      key: "3 sao",

      width: 100,
    },
    {
      title: "4 sao",
      dataIndex: "4 sao",
      key: "4 sao",

      width: 100,
    },
    {
      title: "5 sao",
      dataIndex: "5 sao",
      key: "5 sao",

      width: 100,
    },
    {
      title: "Tổng",
      dataIndex: "Tổng",
      key: "Tổng",

      width: 80,
    },
  ];

  const rateCourseColumns: TableColumnsType<any> = [
    {
      title: "Tên khoá học",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "1 sao",
      dataIndex: "1 sao",
      key: "1 sao",

      width: 100,
    },
    {
      title: "2 sao",
      dataIndex: "2 sao",
      key: "2 sao",

      width: 100,
    },
    {
      title: "3 sao",
      dataIndex: "3 sao",
      key: "3 sao",

      width: 100,
    },
    {
      title: "4 sao",
      dataIndex: "4 sao",
      key: "4 sao",

      width: 100,
    },
    {
      title: "5 sao",
      dataIndex: "5 sao",
      key: "5 sao",

      width: 100,
    },
    {
      title: "Tổng",
      dataIndex: "Tổng",
      key: "Tổng",

      width: 80,
    },
  ];

  const handleContentTypesChange = (value: string) => {
    let courseStructureType = "";

    setFilterValues({
      courseStructureType: courseStructureType,
      moduleGroup: value,
    });
  };

  const exportPayload = {
    from: dayjs(filterValues.dateFrom).toISOString(),
    to: dayjs(filterValues.dateTo).toISOString(),
    courseLevelIds: filterValues?.courseLevelIds,
    industryGroupIds: filterValues?.industryGroupIds,
    courseIds: filterValues?.courseIds,
    universityIds: filterValues?.universityIds,
  };

  return (
    <div>
      <div className="chart-title">Thống kê số lượng phản hồi</div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 24}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Thống kê số lượng phản hồi theo thời gian"
            content={
              <Spin spinning={loading.unitDiscuss}>
                {unitDiscuss.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={createTableData(unitDiscuss).dataSource}
                    columns={[
                      {
                        title: "Thời gian tạo",
                        dataIndex: "name",
                        key: "name",
                      },
                      ...createTableData(unitDiscuss).columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",

                      },
                    ]}
                    keyColumn="criteria"
                    height={275}
                    hasTotal={true}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={
              unitDiscuss.length > 0
                ? [
                  {
                    label: "Excel",
                    action: () =>
                      handleExportReport(
                        exportUnitDiscuss,
                        "Thống kê số lượng phản hồi theo thời gian",
                        "excel",
                        {...exportPayload, timeUnit: filterValues.timeUnit}
                      ),
                  },
                  {
                    label: "PDF",
                    action: () =>
                      handleExportReport(
                        exportUnitDiscuss,
                        "Thống kê số lượng phản hồi theo thời gian",
                        "pdf",
                        {...exportPayload, timeUnit: filterValues.timeUnit}
                      ),
                  },
                ]
                : []
            }
          />
        </Col>
      </Row>

      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}} className="mt-2">
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Thống kê số lượng phản hồi theo bài giảng"
            content={
              <Spin spinning={loading.rateSequenceAction}>
                {rateSequenceAction.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={createTableData(rateSequenceAction).dataSource}
                    columns={[
                      {
                        title: "Tên bài giảng",
                        dataIndex: "name",
                        key: "name",
                      },
                      ...createTableData(rateSequenceAction).columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",

                      },
                    ]}
                    keyColumn="criteria"
                    height={275}
                    hasTotal={true}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={
              rateSequenceAction.length > 0
                ? [
                  {
                    label: "Excel",
                    action: () =>
                      handleExportReport(
                        exportRateSequenceAction,
                        "Thống kê số lượng phản hồi theo bài giảng",
                        "excel",
                        exportPayload
                      ),
                  },
                  {
                    label: "PDF",
                    action: () =>
                      handleExportReport(
                        exportRateSequenceAction,
                        "Thống kê số lượng phản hồi theo bài giảng",
                        "pdf",
                        exportPayload
                      ),
                  },
                ]
                : []
            }
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Thống kê số lượng phản hồi theo lớp"
            content={
              <Spin spinning={loading.rateClassAction}>
                {rateClassAction.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={createTableData(rateClassAction).dataSource}
                    columns={[
                      {
                        title: "Tên lớp",
                        dataIndex: "name",
                        key: "name",
                      },
                      ...createTableData(rateClassAction).columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",

                      },
                    ]}
                    keyColumn="criteria"
                    height={275}
                    hasTotal={true}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={
              rateClassAction.length > 0
                ? [
                  {
                    label: "Excel",
                    action: () =>
                      handleExportReport(
                        exportRateClassAction,
                        "Thống kê số lượng phản hồi theo lớp",
                        "excel",
                        exportPayload
                      ),
                  },
                  {
                    label: "PDF",
                    action: () =>
                      handleExportReport(
                        exportRateClassAction,
                        "Thống kê số lượng phản hồi theo lớp",
                        "pdf",
                        exportPayload
                      ),
                  },
                ]
                : []
            }
          />
        </Col>
      </Row>

      <div className="chart-title">Thống kê số lượng đánh giá</div>
      <Col span={24}>
        <StatisticalReportCard
          chartName="Số lượng đánh giá theo thời gian"
          content={
            <Spin spinning={loading.unitReviewAndAccess}>
              {unitReviewAndAccess.length > 0 ? (
                <TableWithTotals
                  totalLabel={"Tổng"}
                  data={unitReviewAndAccess?.map((it) => ({
                    ...it,
                    total: it?.columnValue + it?.lineValue,
                  }))}
                  columns={[
                    {
                      title: "Ngày làm đánh giá",
                      dataIndex: "criteria",
                      key: "criteria",
                    },
                    {
                      title: "Lượng truy cập",
                      dataIndex: "lineValue",
                      key: "lineValue",

                    },
                    {
                      title: "Lượng làm đánh giá",
                      dataIndex: "columnValue",
                      key: "columnValue",

                    },
                    {
                      title: "Tổng",
                      dataIndex: "total",
                      key: "total",

                    },
                  ]}
                  keyColumn="criteria"
                  height={275}
                  hasTotal={true}
                />
              ) : (
                <Empty description="Không có dữ liệu"/>
              )}
            </Spin>
          }
          moreOption={
            <div>
              <Select
                value={filterValues?.moduleGroup}
                style={{width: 180}}
                options={chartContentTypes.filter(
                  (item) => item.value !== "5" && item.value !== "6"
                )}
                onSelect={(value) => {
                  handleContentTypesChange(value);
                }}
              />
            </div>
          }
          exportOptions={
            unitReviewAndAccess.length > 0
              ? [
                {
                  label: "Excel",
                  action: () =>
                    handleExportReport(
                      exportUnitReviewAndAccess,
                      "Số lượng đánh giá theo thời gian",
                      "excel",
                      {
                        ...exportPayload,
                        timeUnit: filterValues.timeUnit,
                        moduleGroup: filterValues?.moduleGroup,
                      }
                    ),
                },
                {
                  label: "PDF",
                  action: () =>
                    handleExportReport(
                      exportUnitReviewAndAccess,
                      "Số lượng đánh giá theo thời gian",
                      "pdf",
                      {
                        ...exportPayload,
                        timeUnit: filterValues.timeUnit,
                        moduleGroup: filterValues?.moduleGroup,
                      }
                    ),
                },
              ]
              : []
          }
        />
      </Col>

      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}} className="mt-2">
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Tỷ lệ kết quả làm đánh giá"
            content={
              <Spin spinning={loading.rateUnitByModule}>
                {rateUnitByModule.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={rateTransformData(rateUnitByModule)}
                    columns={[
                      {
                        title: "Nội dung",
                        dataIndex: "content",
                        key: "content",
                      },
                      ...rateColumns,
                    ]}
                    keyColumn="content"
                    height={275}
                    hasTotal={true}
                    calculatePercentage={true}
                    width={500}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={
              rateUnitByModule.length > 0
                ? [
                  {
                    label: "Excel",
                    action: () =>
                      handleExportReport(
                        exportRateUnitByModule,
                        "Tỷ lệ kết quả làm đánh giá",
                        "excel",
                        exportPayload
                      ),
                  },
                  {
                    label: "PDF",
                    action: () =>
                      handleExportReport(
                        exportRateUnitByModule,
                        "Tỷ lệ kết quả làm đánh giá",
                        "pdf",
                        exportPayload
                      ),
                  },
                ]
                : []
            }
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Tỷ lệ kết quả đánh giá khoá học"
            content={
              <Spin spinning={loading.rateUnitByCourse}>
                {rateUnitByCourse.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={rateTransformData(rateUnitByCourse)}
                    columns={rateCourseColumns}
                    keyColumn="content"
                    height={275}
                    hasTotal={true}
                    calculatePercentage={true}
                    width={500}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={
              rateUnitByCourse.length > 0
                ? [
                  {
                    label: "Excel",
                    action: () =>
                      handleExportReport(
                        exportRateUnitByCourse,
                        "Tỷ lệ kết quả đánh giá khoá học",
                        "excel",
                        exportPayload
                      ),
                  },
                  {
                    label: "PDF",
                    action: () =>
                      handleExportReport(
                        exportRateUnitByCourse,
                        "Tỷ lệ kết quả đánh giá khoá học",
                        "pdf",
                        exportPayload
                      ),
                  },
                ]
                : []
            }
          />
        </Col>
      </Row>

      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}} className="mt-2">
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Tỷ lệ kết quả đánh giá theo bài giảng"
            content={
              <Spin spinning={loading.rateUnitBySequence}>
                {rateUnitBySequence.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={rateTransformData(rateUnitBySequence)}
                    columns={[
                      {
                        title: "Tên bài giảng",
                        dataIndex: "content",
                        key: "content",
                      },
                      ...rateColumns,
                    ]}
                    keyColumn="content"
                    height={275}
                    hasTotal={true}
                    calculatePercentage={true}
                    width={500}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={
              rateUnitBySequence.length > 0
                ? [
                  {
                    label: "Excel",
                    action: () =>
                      handleExportReport(
                        exportRateUnitBySequence,
                        "Tỷ lệ kết quả đánh giá theo bài giảng",
                        "excel",
                        exportPayload
                      ),
                  },
                  {
                    label: "PDF",
                    action: () =>
                      handleExportReport(
                        exportRateUnitBySequence,
                        "Tỷ lệ kết quả đánh giá theo bài giảng",
                        "pdf",
                        exportPayload
                      ),
                  },
                ]
                : []
            }
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Tỷ lệ kết quả đánh giá học liệu theo lớp"
            content={
              <Spin spinning={loading.rateUnitByClass}>
                {rateUnitByClass.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={rateTransformData(rateUnitByClass)}
                    columns={[
                      {
                        title: "Tên học liệu",
                        dataIndex: "content",
                        key: "content",
                      },
                      ...rateColumns,
                    ]}
                    keyColumn="content"
                    height={275}
                    hasTotal={true}
                    calculatePercentage={true}
                    width={500}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            moreOption={
              <div>
                <Select
                  showSearch
                  placeholder="Chọn lớp"
                  style={{width: 170}}
                  defaultValue={filterValues?.classId}
                  onSelect={(value) => {
                    setFilterValues({classId: value});
                  }}
                  options={classList}
                />
              </div>
            }
            exportOptions={
              rateUnitByClass.length > 0
                ? [
                  {
                    label: "Excel",
                    action: () =>
                      handleExportReport(
                        exportRateUnitByClass,
                        "Tỷ lệ kết quả đánh giá học liệu theo lớp",
                        "excel",
                        {...exportPayload, classId: filterValues?.classId}
                      ),
                  },
                  {
                    label: "PDF",
                    action: () =>
                      handleExportReport(
                        exportRateUnitByClass,
                        "Tỷ lệ kết quả đánh giá học liệu theo lớp",
                        "pdf",
                        {...exportPayload, classId: filterValues?.classId}
                      ),
                  },
                ]
                : []
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default FeedbackAndEvaluationTableTab;
