import { Button, Col, Collapse, DatePicker, Drawer, Form, Input, InputRef, message, Radio, RadioChangeEvent, Row, Space, TableColumnsType, TableProps, Tag, theme, TimePicker, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { isEmpty, size } from 'lodash'
import { CloseOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import "./index.scss"

import { TweenOneGroup } from 'rc-tween-one';
import { viLocale } from '../../../../../utils/pickerType'
import dayjs, { Dayjs } from 'dayjs'
import FormInput from '../../../../form-input/FormInput'
import { formatDateTime } from '../../../../../utils/converDate'
import { getDetailSentPermission, postSentPermissionQuestion } from '../../../../../service/library/question-list'
type Props = {
  open: boolean
  getListData: () => void
  onClose: () => void
  mode: string
  idDetailData: number | undefined
}

export default function SentPermissionQuestion(props: Props) {
  const { open, onClose, mode, idDetailData, getListData } = props
  const [form] = Form.useForm()
  const { token } = theme.useToken();
  const [availableStatus, setAvailableStatus] = useState<any>(0);
  const [availableStatusSchedule, setAvailableStatusSchedule] = useState<any>(3);

  const [tags, setTags] = useState<any>([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<InputRef>(null);

  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [startTime, setStartTime] = useState<Dayjs | null>(null);

  useEffect(() => {
    if (open) {
      // getListDataDetail();
    }
  }, [open]);

  const radioItemList = [
    {
      value: 0,
      title: "Công khai",
      describe: "Nội dung này cần Cấp bộ kiểm duyệt trước khi công khai",
    },
    {
      value: 1,
      title: "Nội bộ",
      describe: "Nội dung này cần QTCS kiểm duyệt trước khi công khai nội bộ",
    },
    {
      value: 2,
      title: "Giới hạn",
      describe: "Chỉ hiển thị nội dung với đối tượng được chia sẻ",
    },
  ];

  const radioItemListSchedule = [
    {
      value: 3,
      title: "Không xuất bản",
      describe: "Nội dung này không suất bản sau khi kiểm duyệt",
    },
    {
      value: 4,
      title: "Xuất bản ngay",
      describe: "Nội dung này sẽ xuất bản ngay sau khi kiểm duyệt thành công",
    },
    {
      value: 5,
      title: "Hẹn lịch",
      describe: "Nội dung được kiểm duyệt thành công sẽ xuất bản theo lịch trình được thiết lập",
    },
  ];

  const handleRadioChange = (e: RadioChangeEvent) => {
    let value = e.target.value
    if (value < 3) {
      setAvailableStatus(e.target.value);
    } else {
      setAvailableStatusSchedule(e.target.value);
    }
  };

  const forMap = (tag: string) => (
    <span key={tag} style={{ display: 'inline-block' }}>
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    </span>
  );

  const tagChild = tags.map(forMap);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag: any) => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && tags.indexOf(inputValue) === -1) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const tagPlusStyle: React.CSSProperties = {
    background: token.colorBgContainer,
    borderStyle: 'dashed',
  };

  const handleChangeStartDate = (date: any) => {
    setStartDate(date);
    setStartTime(date);
  };

  const handleChangeStartTime = (time: any | null) => {
    setStartTime(time);
  };

  const clearData = () => {
    onClose()
    setAvailableStatus(0)
    setAvailableStatusSchedule(3)
    setTags(([]))
    setStartDate(null)
    setStartTime(null)
  };

  useEffect(() => {
    if(mode === 'edit') {
      getDetailSentPermission(idDetailData).then((res) => {
        if(res.status === 200) {
          const { data } = res.data
          setAvailableStatus(data.access === 'Giới hạn' ? 2 : (data.access === 'Công khai' ? 0 : 1))
          setTags(data?.shareUsers)
          setAvailableStatusSchedule(data.scheduleType === 1 ? 3 : (data.scheduleType === 2 ? 4 : 5))
          setStartDate(
            data?.publishDate
              ? dayjs.utc(data?.publishDate)
              : null
          );
          setStartTime(
            data?.publishDate
              ? dayjs.utc(data?.publishDate)
              : null
          );
        }
      })
    }
  }, [mode])

  return (
    <Form.Provider onFormFinish={async (name, { values, forms }) => {
      if (name === "form-detail-edit-permission") {
        const data = {
          id: idDetailData,
          scheduleType: availableStatusSchedule === 3 ? 1 : (availableStatus === 4 ? 2 : 3),
          publishDate: formatDateTime(startDate, startTime),
          contentEdit: values.content,
          access: availableStatus === 1 ? 'Công khai' : (availableStatus === 2 ? 'Nội bộ' : 'Giới hạn'),
          shareUsers: tags
        }
        await postSentPermissionQuestion(data).then((res) => {
          if(res.status === 200) {
            message.success("Lưu thông tin thành công")
            clearData()
            getListData()
          }
        })
      }
    }}>
      <Drawer
        title={"Gửi kiểm duyệt nội dung"}
        open={open}
        onClose={clearData}
        className='detail-edit-permission-drawer'
        width={736}
        bodyStyle={{ padding: "0px" }}
        extra={
          <Space>
            <Button onClick={clearData}>
              <CloseOutlined />
              Hủy
            </Button>
            <Button onClick={() => form.submit()} className='btn btn-primary'>
              <SaveOutlined />
              Gửi yêu cầu
            </Button>
          </Space>
        }
      >
        <Form
          className="detail-edit-permission"
          layout={"vertical"}
          form={form}
          name="form-detail-edit-permission"
        >
          <div className='mt-5 ml-7 mr-7'>
            {
              mode === 'edit' ?
                <><Typography.Title level={5}>Nội dung chỉnh sửa</Typography.Title><Form.Item name="content" label="">
                  <FormInput placeholder='Nhập nội dung' />
                </Form.Item></> : null
            }
            <Typography.Title level={5}>Quyền truy cập</Typography.Title>
            <Radio.Group value={availableStatus}>
              {radioItemList.map((item, index) => (
                <div key={index} className="radio-item wrapper-radio">
                  <Radio value={item.value} onChange={handleRadioChange}>
                    <Typography.Title
                      className="setting-chapter-title"
                      level={5}
                    >
                      {item.title}
                    </Typography.Title>
                  </Radio>
                  <div className="setting-chapter-description">
                    {item.describe}
                  </div>

                  {
                    availableStatus === 2 && index === 2 ?
                      <>
                        <div style={{ marginBottom: 20, marginTop: 15 }}>
                          <TweenOneGroup
                            appear={false}
                            enter={{ scale: 0.8, opacity: 0, type: 'from', duration: 100 }}
                            leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                            onEnd={(e) => {
                              if (e.type === 'appear' || e.type === 'enter') {
                                (e.target as any).style = 'display: inline-block';
                              }
                            }}
                          >
                            {tagChild}
                          </TweenOneGroup>
                        </div>
                        {inputVisible ? (
                          <Input
                            ref={inputRef}
                            type="text"
                            size="small"
                            placeholder='Nhập tên hoặc Email người dùng'
                            style={{ width: 220 }}
                            value={inputValue}
                            onChange={handleInputChange}
                            onBlur={handleInputConfirm}
                            onPressEnter={handleInputConfirm}
                          />
                        ) : (
                          <Tag onClick={showInput} style={tagPlusStyle}>
                            <PlusOutlined /> Nhập tên hoặc Email người dùng
                          </Tag>
                        )}
                      </> : null
                  }
                </div>
              ))}
            </Radio.Group>

            <Typography.Title level={5}>Lịch xuất bản dự kiến</Typography.Title>
            <Radio.Group value={availableStatusSchedule}>
              {radioItemListSchedule.map((item, index) => (
                <div key={index} className="radio-item wrapper-radio">
                  <Radio value={item.value} onChange={handleRadioChange}>
                    <Typography.Title
                      className="setting-chapter-title"
                      level={5}
                    >
                      {item.title}
                    </Typography.Title>
                  </Radio>
                  <div className="setting-chapter-description">
                    {item.describe}
                  </div>

                  {
                    availableStatusSchedule === 5 && index === 2 ?
                      <div className="time-picker flex mt-4">
                        <div className="time-picker-content">
                          <span>Ngày bắt đầu</span>
                          <DatePicker
                            onChange={handleChangeStartDate}
                            locale={viLocale}
                            format={"DD/MM/YYYY"}
                            value={startDate}
                          />
                        </div>
                        <div className="time-picker-content">
                          <span>Giờ</span>
                          <TimePicker
                            locale={viLocale}
                            value={startTime}
                            onChange={handleChangeStartTime}
                            format={"HH:mm"}
                            className='ml-4'
                          />
                        </div>
                      </div> : null
                  }
                </div>
              ))}
            </Radio.Group>
          </div>
        </Form>
      </Drawer>
    </Form.Provider>
  )
}
