import React, { ChangeEvent, FunctionComponent, KeyboardEvent, useState } from 'react';
import { Button, Col, Dropdown, Form, Modal, Radio, Row, Space, Typography } from 'antd';
import { CheckOutlined, CloseOutlined, DownOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import FormInput from '../../../../../components/form-input/FormInput';
import './filter-table.scss';
import TextArea from 'antd/es/input/TextArea';
import { useNavigate } from 'react-router-dom';
import { routesConfig } from '../../../../../config/routes';

type FilterTableProps = {
  searchValue: string,
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void,
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void,
  handleSubmitSearch: () => void,
  handleOpenFilter: () => void,
  selectedRowKeys: React.Key[];
  onFinish: (values: any) => void;
  hideSelection?: boolean;
  typeQuestion: boolean,
  setTypeQuestion: (value: boolean) => void,
}

const FilterTable: FunctionComponent<FilterTableProps> = ({
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  selectedRowKeys,
  onFinish,
  typeQuestion,
  setTypeQuestion,
  hideSelection = true,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [quickBrowe,setquickBrowe] = useState('true');
  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.submit();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="filter-table-ministry-moderation">
        <Row gutter={[8, 8]}>
          {!typeQuestion ? 
          <>
          <Col md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} xs={{ span: 7 }}>
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text> Bộ lọc </Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col md={{ span: 9 }} lg={{ span: 9 }} xl={{ span: 9 }} xs={{ span: 22 }}>
            <div className="heading-search">
              <FormInput placeholder="Nhập từ khóa cần tìm" value={searchValue}
                onChange={handleChangeInput} onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col>
          </> : 
          <>
          <Col>
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text> Bộ lọc </Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col>
            <div className="heading-search">
              <FormInput placeholder="Nhập từ khóa cần tìm" value={searchValue}
                onChange={handleChangeInput} onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col>
          </>}
          
          {!typeQuestion ?
          <>
          <Col md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 22 }}>
            <div className="heading-resigter">
              <Button className="btn btn-primary" onClick={showModal}
              disabled={!hideSelection || selectedRowKeys.length === 0}>
                <Typography.Text>Duyệt nhiều</Typography.Text>
              </Button>
            </div>
          </Col>
          <Col md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} xs={{ span: 22 }}>
            <div className="heading-history">
              <Button className="btn btn-default" onClick={() =>
              setTypeQuestion(!typeQuestion)}>
                <Typography.Text>Lịch sử kiểm duyệt</Typography.Text>
              </Button>
            </div>
          </Col>
          </> : ''}
          
        </Row>
        <Modal
          title="Duyệt nhanh"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
          <div>
            <Typography.Text>
              Nội dung sẽ được gửi lên cấp bộ sau khi duyệt
            </Typography.Text>
            <div className='footer-modal-button'>
              <Button key="cancel" onClick={handleCancel} style={{marginRight:'10px'}} icon={<CloseOutlined />} >
                Huỷ bỏ
              </Button>
              <Button key="ok" type="primary" onClick={handleOk} icon={<CheckOutlined />} style={{ marginLeft: '8px' }} >
                Xác nhận
              </Button>
            </div>
          </div>
          ]}
          className='modal-fast-browsing'
        >
          <Form form={form} onFinish={onFinish}>
          <p>Nhập nội dung kiểm duyệt</p>
            <Form.Item name='status' initialValue={true}>
              <Radio.Group>
                <Radio value={true}>
                  <Typography.Text>Duyệt</Typography.Text>
                </Radio>
            <Radio value={false}>
            <Typography.Text>Không duyệt</Typography.Text>
            </Radio>
          </Radio.Group>
          </Form.Item>
          <Form.Item name='description'>
          <TextArea 
          placeholder='Nhập ý kiến'
            style={{
              marginLeft: '60px',
              width: '80%',
              height: '45px'
            }} />
          </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default FilterTable;
