import { Col, Empty, Row, Select, Spin } from "antd";
import AreaStackedChart from "../../../../../../components/types-of-charts/AreaStackedChart";
import BarStackedChart from "../../../../../../components/types-of-charts/BarStackedChart";
import ColumnStackedPercentChart from "../../../../../../components/types-of-charts/ColumnStackedPercentChart";
import ComboDualAxesChart from "../../../../../../components/types-of-charts/ComboDualAxesChart";
import { staticReportStore } from "../../../../../../stores/stores";
import {
  BarStackedChartProps,
  ClassProps,
  DualChart,
} from "../../../../../../types/static-report";
import { chartContentTypes } from "../../../../../../utils/staticReport";
import StatisticalReportCard from "../../StatisticalReportCard";
import useChartExport from "../useChartExport";
import React, { useEffect } from "react";

type Props = {
  loading: Record<string, boolean>;
  rateUnitByCourse: BarStackedChartProps[];
  rateUnitByModule: BarStackedChartProps[];
  rateUnitBySequence: BarStackedChartProps[];
  unitReviewAndAccess: DualChart[];
  rateSequenceAction: BarStackedChartProps[];
  rateClassAction: BarStackedChartProps[];
  unitDiscuss: BarStackedChartProps[];
  rateUnitByClass: BarStackedChartProps[];
  classList: ClassProps[];
};

const FeedbackAndEvaluationChartTab = React.memo((props: Props) => {
  const {
    loading,
    rateUnitByCourse,
    rateUnitByModule,
    rateUnitBySequence,
    unitReviewAndAccess,
    rateSequenceAction,
    rateClassAction,
    unitDiscuss,
    rateUnitByClass,
    classList,
  } = props;

  const {
    tab,
    setHandleExportAllImagesAsZip,
    exportType,
    filterValues,
    setFilterValues,
  } = staticReportStore();
  const { createChartRef, handleExportImage, handleExportAllImagesAsZip } =
    useChartExport();

  useEffect(() => {
    setHandleExportAllImagesAsZip(() => handleExportAllImagesAsZip(tab));
  }, [tab]);

  const handleContentTypesChange = (value: string) => {
    let courseStructureType = "";

    setFilterValues({
      courseStructureType: courseStructureType,
      moduleGroup: value,
    });
  };

  return (
    <div>
      <div className="chart-title">Thống kê số lượng phản hồi</div>
      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
        <Col sm={{ span: 24 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Thống kê số lượng phản hồi theo thời gian"
            content={
              <Spin spinning={loading.unitDiscuss}>
                {unitDiscuss.length > 0 ? (
                  <AreaStackedChart
                    data={unitDiscuss}
                    colors={[
                      "#69C0FF",
                      "#5CDBD3",
                      "#FFC069",
                      "#FF7875",
                      "#B37FEB",
                    ]}
                    yAxisTitle="Số lượng phản hồi"
                    ref={createChartRef(
                      tab,
                      "Thống kê số lượng phản hồi theo thời gian"
                    )}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={
              unitDiscuss.length
                ? [
                    {
                      label: "Hình ảnh (PNG)",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Thống kê số lượng phản hồi theo thời gian",
                          "image/png"
                        ),
                    },
                    {
                      label: "PDF",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Thống kê số lượng phản hồi theo thời gian",
                          "application/pdf"
                        ),
                    },
                  ]
                : []
            }
          />
        </Col>
      </Row>

      {/* Section for Feedback Count By Lecture and Class */}
      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }} className="mt-2">
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Thống kê số lượng phản hồi theo bài giảng"
            content={
              <Spin spinning={loading.rateSequenceAction}>
                {rateSequenceAction.length > 0 ? (
                  <BarStackedChart
                    data={rateSequenceAction}
                    colors={["#69C0FF", "#FFC069", "#FF7875", "#5CDBD3"]}
                    ref={createChartRef(
                      tab,
                      "Thống kê số lượng phản hồi theo bài giảng"
                    )}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={
              rateSequenceAction.length > 0
                ? [
                    {
                      label: "Hình ảnh (PNG)",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Thống kê số lượng phản hồi theo bài giảng",
                          "image/png"
                        ),
                    },
                    {
                      label: "PDF",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Thống kê số lượng phản hồi theo bài giảng",
                          "application/pdf"
                        ),
                    },
                  ]
                : []
            }
          />
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Thống kê số lượng phản hồi theo lớp"
            content={
              <Spin spinning={loading.rateClassAction}>
                {rateClassAction.length > 0 ? (
                  <BarStackedChart
                    data={rateClassAction}
                    colors={["#69C0FF", "#FFC069", "#FF7875", "#5CDBD3"]}
                    ref={createChartRef(
                      tab,
                      "Thống kê số lượng phản hồi theo lớp"
                    )}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={
              rateClassAction.length > 0
                ? [
                    {
                      label: "Hình ảnh (PNG)",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Thống kê số lượng phản hồi theo lớp",
                          "image/png"
                        ),
                    },
                    {
                      label: "PDF",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Thống kê số lượng phản hồi theo lớp",
                          "application/pdf"
                        ),
                    },
                  ]
                : []
            }
          />
        </Col>
      </Row>

      <div className="chart-title">Thống kê số lượng đánh giá</div>
      <Col span={24}>
        <StatisticalReportCard
          chartName="Số lượng đánh giá theo thời gian"
          content={
            <Spin spinning={loading.unitReviewAndAccess}>
              {unitReviewAndAccess.length > 0 ? (
                <ComboDualAxesChart
                  data={unitReviewAndAccess}
                  columnName="Lượng làm đánh giá"
                  columnColor="#69C0FF"
                  lineName="Lượt truy cập (hoàn thành học liệu)"
                  lineColor="#FF7875"
                  ref={createChartRef(tab, "Số lượng đánh giá theo thời gian")}
                />
              ) : (
                <Empty description="Không có dữ liệu" />
              )}
            </Spin>
          }
          moreOption={
            <div>
              <Select
                value={filterValues?.moduleGroup}
                style={{ width: 180 }}
                onSelect={(value) => {
                  handleContentTypesChange(value);
                }}
                options={chartContentTypes.filter(
                  (item) => item.value !== "5" && item.value !== "6"
                )}
              />
            </div>
          }
          exportOptions={
            unitReviewAndAccess.length > 0
              ? [
                  {
                    label: "Hình ảnh (PNG)",
                    action: () =>
                      handleExportImage(
                        tab,
                        "Số lượng đánh giá theo thời gian",
                        "image/png"
                      ),
                  },
                  {
                    label: "PDF",
                    action: () =>
                      handleExportImage(
                        tab,
                        "Số lượng đánh giá theo thời gian",
                        "application/pdf"
                      ),
                  },
                ]
              : []
          }
        />
      </Col>

      {/* Section for Evaluation Results */}
      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }} className="mt-2">
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Tỷ lệ kết quả làm đánh giá"
            content={
              <Spin spinning={loading.rateUnitByModule}>
                {rateUnitByModule.length > 0 ? (
                  <ColumnStackedPercentChart
                    data={rateUnitByModule}
                    ref={createChartRef(tab, "Tỷ lệ kết quả làm đánh giá")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={
              rateUnitByModule.length > 0
                ? [
                    {
                      label: "Hình ảnh (PNG)",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Tỷ lệ kết quả làm đánh giá",
                          "image/png"
                        ),
                    },
                    {
                      label: "PDF",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Tỷ lệ kết quả làm đánh giá",
                          "application/pdf"
                        ),
                    },
                  ]
                : []
            }
          />
        </Col>

        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Tỷ lệ kết quả đánh giá khoá học"
            content={
              <Spin spinning={loading.rateUnitByCourse}>
                {rateUnitByCourse.length > 0 ? (
                  <ColumnStackedPercentChart
                    data={rateUnitByCourse}
                    ref={createChartRef(tab, "Tỷ lệ kết quả đánh giá khoá học")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={
              rateUnitByCourse.length > 0
                ? [
                    {
                      label: "Hình ảnh (PNG)",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Tỷ lệ kết quả đánh giá khoá học",
                          "image/png"
                        ),
                    },
                    {
                      label: "PDF",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Tỷ lệ kết quả đánh giá khoá học",
                          "application/pdf"
                        ),
                    },
                  ]
                : []
            }
          />
        </Col>
      </Row>

      {/* Section for Evaluation Results By Class */}
      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }} className="mt-2">
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Tỷ lệ kết quả đánh giá theo bài giảng"
            content={
              <Spin spinning={loading.rateUnitBySequence}>
                {rateUnitBySequence.length > 0 ? (
                  <ColumnStackedPercentChart
                    data={rateUnitBySequence}
                    ref={createChartRef(
                      tab,
                      "Tỷ lệ kết quả đánh giá theo bài giảng"
                    )}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={
              rateUnitBySequence.length > 0
                ? [
                    {
                      label: "Hình ảnh (PNG)",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Tỷ lệ kết quả đánh giá theo bài giảng",
                          "image/png"
                        ),
                    },
                    {
                      label: "PDF",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Tỷ lệ kết quả đánh giá theo bài giảng",
                          "application/pdf"
                        ),
                    },
                  ]
                : []
            }
          />
        </Col>

        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Tỷ lệ kết quả đánh giá học liệu theo lớp"
            content={
              <Spin spinning={loading.rateUnitByClass}>
                {rateUnitByClass.length > 0 ? (
                  <ColumnStackedPercentChart
                    data={rateUnitByClass}
                    ref={createChartRef(
                      tab,
                      "Tỷ lệ kết quả đánh giá học liệu theo lớp"
                    )}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            moreOption={
              <div>
                <Select
                  showSearch
                  placeholder="Chọn lớp"
                  style={{ width: 170 }}
                  onSelect={(value) => {
                    setFilterValues({ classId: value });
                  }}
                  options={classList}
                />
              </div>
            }
            exportOptions={
              rateUnitByClass.length > 0
                ? [
                    {
                      label: "Hình ảnh (PNG)",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Tỷ lệ kết quả đánh giá học liệu theo lớp",
                          "image/png"
                        ),
                    },
                    {
                      label: "PDF",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Tỷ lệ kết quả đánh giá học liệu theo lớp",
                          "application/pdf"
                        ),
                    },
                  ]
                : []
            }
          />
        </Col>
      </Row>
    </div>
  );
});

export default FeedbackAndEvaluationChartTab;
