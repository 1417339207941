import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Flex,
  Space,
  Typography,
  message
} from "antd";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DrawerCustom from "../../../components/drawer/Drawer";
import { DEFAULT_ROLE } from "../../../constants/setting-identity";
import {
  getSettingAuthorityAllAttendance,
  getSettingAuthorityById,
  updateSettingAuthority,
} from "../../../service/setting-authority";
import { useAddPermission } from "../../../stores/stores";
import PermissionCheckInDrawer from "./PermissionCheckInDrawer";

const { Title, Text } = Typography;

interface IProps {
  displayRoleEdit: boolean;
}
const RoleCheckIn = ({ displayRoleEdit }: IProps) => {
  const params = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isView, setIsView] = useState(false);
  const [idRefreshGroup, setIdRefreshGroup] = useState<number>(NaN);

  const [dataRole, setDataRole] = useState<any>([]);
  const [optionRole, setOptionRole] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const { permission, setPermission } = useAddPermission((state) => state);

  const [formModal, setFormModal] = useState({
    title: "",
    value: "",
    id: "",
  });
  const [messageApi, contextHolder] = message.useMessage();

  const handleEdit = (role: any) => {
    setIsOpen(true);
    setIsView(false);
    setFormModal({
      title: DEFAULT_ROLE[role?.type],
      value: role?.type,
      id: role?.id,
    });
  };
  const handleView = (role: any) => {
    setIsOpen(true);
    setIsView(true);
    setFormModal({
      title: DEFAULT_ROLE[role?.type],
      value: role?.type,
      id: role?.id,
    });
  };
  const fetchSettingAuthority = async () => {
    const filterParam = {
      search: searchValue,
      id: formModal?.id,
      take: 10,
      skip: 0,
      course_id: Number(params?.id),
    };
    try {
      const response = await getSettingAuthorityAllAttendance(filterParam);
      const { data } = response.data;
      setOptionRole(
        data?.map((option: any) => ({
          label: option?.name,
          value: option?.id,
          users: option?.users,
        }))
      );
      if (`${idRefreshGroup}` !== `${formModal?.id}` || !isOpen) {
        setPermission(
          data
            .filter((item: { is_active: boolean }) => item.is_active) // Lọc các phần tử có is_active bằng true
            .map((item: { id: number; users: any[] }) => ({
              id: item.id,
              users: item.users
                .filter((user: { is_active: boolean }) => user.is_active) // Lọc các user có is_active bằng true
                .map((user: { id: number }) => user.id), // Lấy ra id của user
            }))
        );
        setIdRefreshGroup(formModal?.id as any);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSettingAuthorityById = async () => {
    try {
      const response = await getSettingAuthorityById(params?.id);
      const { data } = response.data;
      setDataRole(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (formModal?.id !== "") {
      fetchSettingAuthority();
    }
    fetchSettingAuthorityById();
  }, [searchValue, formModal?.id, isOpen]);

  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleSaveRole = async () => {
    const paramRole = {
      groups: permission,
      course_id: Number(params?.id),
      type: formModal?.value,
    };

    if (isEmpty(paramRole?.groups)) {
      messageApi.open({
        type: "error",
        content: `phải chọn ít nhất 1 đối tượng ở ${formModal?.title.toLowerCase()}`,
      });
    } else {
      try {
        await updateSettingAuthority(formModal?.id, paramRole);
        setIsOpen(false);
        messageApi.open({
          type: "success",
          content: `Lưu thay đổi ${formModal?.title.toLowerCase()} thành công`,
        });
        await fetchSettingAuthorityById();
        setFormModal({
          title: "",
          value: "",
          id: "",
        });
      } catch (error) {
        console.log(error);
        messageApi.open({
          type: "error",
          content: `Lưu thay đổi ${formModal?.title.toLowerCase()} thất bại`,
        });
      }
    }
  };
  return (
    <div className="w-full lg:w-2/3 xl:w-1/2 m-auto my-12">
      {contextHolder}
      {dataRole?.map((role: any, index: number) => (
        <Card className="mt-4" key={index}>
          <Flex justify="space-between">
            <div>
              <Title level={4} className="!leading-3">
                {DEFAULT_ROLE[role?.type]}
              </Title>
              <Text className="text-[#00000073]">
                Chọn đối tượng áp dụng cho phân quyền này
              </Text>
            </div>
            <Flex align="center">
              <Button
                className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer action-remind"
                onClick={() => handleEdit(role)}
                disabled={!displayRoleEdit}
              >
                <EditOutlined />
              </Button>
              <Button
                className="border-solid border border-[#D9D9D9] rounded-lg px-2 py-1 mx-1 cursor-pointer action-remind"
                onClick={() => handleView(role)}
              >
                <EyeOutlined />
              </Button>
            </Flex>
          </Flex>
        </Card>
      ))}
      <DrawerCustom
        title={formModal?.title}
        open={isOpen}
        onClose={handleCancel}
        extraNode={
          <Space>
            {!isView && (
              <>
                <Button onClick={handleCancel}>
                  <Typography.Text>Hủy</Typography.Text>
                </Button>
                <Button
                  className="btn btn-primary"
                  htmlType="submit"
                  onClick={handleSaveRole}
                >
                  <Typography.Text>Lưu</Typography.Text>
                </Button>
              </>
            )}
          </Space>
        }
        widthDrawer={730}
      >
        <PermissionCheckInDrawer
          formModal={formModal}
          isView={isView}
          optionRole={optionRole}
          setSearchValue={setSearchValue}
          fieldsRole={permission}
          setFieldsRole={setPermission}
        />
      </DrawerCustom>
    </div>
  );
};

export default RoleCheckIn;
