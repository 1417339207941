import React, { useEffect, useMemo, useState } from "react";
import "./detail.scss";

import {
  CloseOutlined,
  CloudDownloadOutlined,
  DeleteColumnOutlined,
  DeleteFilled,
  DeleteOutlined,
  EditOutlined,
  FontColorsOutlined,
  LayoutOutlined,
  PlusOutlined,
  SaveOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  ConfigProvider,
  Dropdown,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import EditorQuill from "../editor-quill/EditorQuill";
import {
  useSurveyDetail,
  useSurveyInfo,
} from "../../../../../../stores/stores";
import { useParams } from "react-router-dom";
import { ListQuestion } from "../../../../../../types/survey-managment/survey";

import DrawerCustom from "../../../../../../components/drawer/Drawer";
import QuestionCard from "../detail-question/QuestionCard";
// import { getSuggestedSurveyQuestion } from "../../../../../../service/survey-managment/list-survey";
import SurveyQuestionSuggestion from "../survey-question-suggestion/SurveyQuestionSuggestion";
import FormItemInput from "../../../../../../components/form-input/FormInput";
import {
  MoocSurveyContentQuestion,
  MoocSurveyContentSection,
} from "../../../../../../types/survey-managment/mooc-survey-content";
import { ListSurveySection } from "../../../../../../types/survey-managment/survey";

interface RightControlProps {
  handleShowFilterQuestion?: () => void;
  surveyDescriptionContent: string;
  chooseQuestionFromCloud?: () => {};
  addNewQuestion?: (sectionNumber: number) => void;
  addNewSection?: (sectionNumber: number) => void;
  addQuestionFromCloud?: (
    sectionNumber: number,
    newQuestion?: ListQuestion
  ) => void;
  listQuestionAddMore?: MoocSurveyContentQuestion[] | [];
  setListQuestionAddMore?: React.Dispatch<
    React.SetStateAction<MoocSurveyContentQuestion[]>
  >;
  dataSection?: any;
  setUpdateDataSection?: (data: ListSurveySection[]) => void;
  setDataSection?: any;
  isShowSurveyDescription?: boolean;
  setIsShowSurveyDescription?: (data: boolean) => void;
  handleAddMoreQuestionExist?: any;
  topValueQuestionActive?: number;
  handleDeleteAllSectionsKeepQuestions?: any;
}

const RightControl: React.FC<RightControlProps> = ({
  handleShowFilterQuestion,
  surveyDescriptionContent,
  chooseQuestionFromCloud,
  addNewQuestion,
  addNewSection,
  addQuestionFromCloud,
  listQuestionAddMore,
  setListQuestionAddMore,
  dataSection,
  setDataSection,
  isShowSurveyDescription,
  setIsShowSurveyDescription,
  setUpdateDataSection,
  handleAddMoreQuestionExist,
  topValueQuestionActive,
  handleDeleteAllSectionsKeepQuestions,
}) => {
  const updateSurveyDescription = useSurveyInfo(
    (state) => state?.updateSurveyDescription
  );

  const topValueActive = useMemo(() => {
    if (topValueQuestionActive) {
      if (topValueQuestionActive == 0) { return 0 }
      if (topValueQuestionActive > 0) { return (topValueQuestionActive + 220) }
    }
    else return 0
  }, [topValueQuestionActive]);

  
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [surveyDescription, setSurveyDescription] = useState(
    surveyDescriptionContent
  );
  const [listSurveyQuestionSuggestion, setListSurveyQuestionSuggestion] =
    useState();

  const listSurveySections = useSurveyDetail(
    (state) => state.listSurveySections
  );
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(-1);

  // topValueQuestionActive && console.log(`topValueQuestionActive::`, topValueQuestionActive);

  const param = useParams();
  const handleUpdateSurveyDescription = async () => {
    updateSurveyDescription(Number(param.id), surveyDescription);
  };

  //icon PlusOutlined
  const handleAddQuestion = () => {
    if (addNewQuestion) {
      addNewQuestion(selectedSectionIndex);
    }
  };

  //icon CloudOutlined
  const [isShowModalAddQuestionCloud, setIsShowModalAddQuestionCloud] =
    useState(false);

  const handleShowModalAddQuestionCloud = () => {
    setIsShowModalAddQuestionCloud(true);
  };

  const handleCancelAddQuestionCloud = () => {
    setIsShowModalAddQuestionCloud(false);
  };

  const handleAddQuestionCloud = () => {
    if (addQuestionFromCloud) {
      addQuestionFromCloud(selectedSectionIndex);
    }
    setIsShowModalAddQuestionCloud(false);
  };

  // icon LayoutOutlined
  const handleAddSection = () => {
    if (addNewSection) {
      addNewSection(selectedSectionIndex);
    }
  };

  const [isOpenCloudQuestion, setIsOpenCloudQuestion] = useState(false);
  const [selectedItems, setSelectedItems] = useState<
    MoocSurveyContentQuestion[]
  >([]);

  const handleAddMoreQuestion = () => {
    const newData = selectedItems.map((section) => {
      return {
        ...section,
        moocSurvey_question_answer:
          section &&
          section.moocSurveyQuestionAnswer &&
          section?.moocSurveyQuestionAnswer.map(
            ({ id, surveyQuestionId, ...rest }) => {
              return {
                ...rest,
              };
            }
          ),
      };
    });
    // if (selectedItems && selectedItems.length > 0) {
    //   setListQuestionAddMore && setListQuestionAddMore([...listQuestionAddMore, ...selectedItems]);
    // }
    // console.log('listQuestionAddMore in right control')
    // console.log({ listQuestionAddMore })
    handleAddMoreQuestionExist(newData);

    // setUpdateDataSection((prev: any) => {
    //   console.log({ prev })
    //   return prev && prev.map((section: any) => ({
    //     ...section,
    //     mooc_survey_question: [...section.mooc_survey_question, ...selectedItems]
    //   }));
    // return selectedItems && selectedItems.length > 0 && selectedItems.map((item: any) => {
    //   prev[0]?.mooc_survey_question.push(item)
    // })
    // });
    setIsOpenCloudQuestion(false);
    setSelectedItems([]);
    setIsChecked(false);
  };

  const handleExitAddMoreQuestionExsit = () => {
    setIsOpenCloudQuestion(false);
    setSelectedItems([]);
    setIsChecked(false);
  };
  const showConfirmDeleteSections = () => {
    Modal.confirm({
      title: "Xóa tất cả thành phần và giữ lại toàn bộ câu hỏi",
      content: (
        <>
          Bạn có chắc chắn muốn <span style={{color: "red"}}>xóa tất cả thành phần và giữ lại toàn bộ câu
          hỏi?</span>
        </>
      ),
      cancelText: "Hủy bỏ",
      okText: "Xác nhận",
      onOk: handleDeleteAllSectionsKeepQuestions,
      centered: true,
    });
  };

  return (
    <div className="survey-managment-right-control">
      <ConfigProvider
        theme={{
          components: {
            Tooltip: {
              colorTextLightSolid: "black",
              colorBgSpotlight: "white",
            },
          },
        }}
      >
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end", position: "relative" }}
        >
          <div
            style={{
              marginRight: "-85px",
              position: "relative", 
              marginBottom: "12px"
            }}
          >
            <Space
              size="small"
              direction="vertical"
              style={{
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <Tooltip placement="top" title="Tìm kiếm câu hỏi trong khảo sát">
                <Button
                  onClick={handleShowFilterQuestion}
                  icon={<SearchOutlined />}
                />
              </Tooltip>
            </Space>
          </div>
        </div>

        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end",position: "relative"  }}
        >
          <div
            style={
              topValueActive
                ? {
                    marginRight: "-85px",
                    position: "relative", 
                    top: `${topValueActive}px`,
                    transition: "top 0.3s ease",
                  }
                : {
                    marginRight: "-85px",
                    position: "relative", 
                    top:"0",
                    marginTop:"12px",
                    transition: "top 0.3s ease",
                  }
            }
          >
            <Space
              size="small"
              direction="vertical"
              style={{
                backgroundColor: "white",
                borderRadius: "10px",
                padding: "10px",
              }}
            >
              <Tooltip placement="top" title="Thêm câu hỏi">
                <Button icon={<PlusOutlined />} onClick={handleAddQuestion} />
              </Tooltip>

              <Tooltip placement="top" title="Chọn câu hỏi sẵn có">
                <Button
                  icon={<CloudDownloadOutlined />}
                  // onClick={handleShowModalAddQuestionCloud}
                  onClick={() => setIsOpenCloudQuestion(true)}
                />

                <DrawerCustom
                  title={"Thêm mới từ danh sách câu hỏi sẵn có"}
                  open={isOpenCloudQuestion}
                  onClose={() => {
                    setIsOpenCloudQuestion(false);
                  }}
                  extraNode={
                    <div>
                      <Space>
                        <Button
                          onClick={handleExitAddMoreQuestionExsit}
                          className="btn btn-outlined"
                        >
                          <CloseOutlined />
                          <Typography.Text>Hủy</Typography.Text>
                        </Button>
                        <Button
                          className="btn btn-primary"
                          htmlType="submit"
                          onClick={handleAddMoreQuestion}
                        >
                          <SaveOutlined />
                          <Typography.Text>Chọn</Typography.Text>
                        </Button>
                      </Space>
                    </div>
                  }
                  widthDrawer={900}
                >
                  <SurveyQuestionSuggestion
                    isOpenCloudQuestion={isOpenCloudQuestion}
                    setIsOpenCloudQuestion={setIsOpenCloudQuestion}
                    setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    setDataSection={setDataSection}
                    dataSection={dataSection}
                    handleExitAddMoreQuestionExsit={
                      handleExitAddMoreQuestionExsit
                    }
                  />
                </DrawerCustom>
              </Tooltip>

              <Tooltip placement="top" title="Thêm mô tả đợt KS">
                <Button
                  icon={<FontColorsOutlined />}
                  onClick={() =>
                    setIsShowSurveyDescription &&
                    setIsShowSurveyDescription(true)
                  }
                  disabled={isShowSurveyDescription}
                />
              </Tooltip>

              <Tooltip placement="top" title="Thêm thành phần">
                <Button icon={<LayoutOutlined />} onClick={handleAddSection} />
              </Tooltip>

              <Tooltip
                placement="top"
                title="Xóa tất cả thành phần và giữ câu hỏi"
              >
                <Button
                  icon={<DeleteOutlined />}
                  onClick={showConfirmDeleteSections}
                />
              </Tooltip>
            </Space>
          </div>
        </div>
      </ConfigProvider>
    </div>
  );
};

export default RightControl;
