import { FC } from "react";
import CustomCard from "../../../../custom-card/CustomCard";
import { Col, Form, Row, Tag } from "antd";
import "../ministry-detail/ministryDetail.scss";
interface MinistryDetailProps {
    ministryRecord: any;
    academicLevel: string[];
    regency: string[];
}

const MinistryDetail: FC<MinistryDetailProps> = ({
    ministryRecord,
    academicLevel,
    regency
}) => {
    return (
        <section className="wrapper-settings category-management-detail">
            <div className="setting-user wrapper-inside">
                <CustomCard className="header-card" title="Thông tin người dùng">
                    <Row gutter={[0, 0]}>
                        <Col span={24}>
                            <Form.Item className="setting-item" name="name" label="Tên người dùng">
                                <p className="user-content">{ministryRecord?.name}</p>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                className="setting-item" name="organization" label="Đơn vị">
                                <p className="user-content">{ministryRecord.organizationName}</p>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item className="setting-item" name="gender" label="Giới tính">
                                <p className="user-content">{ministryRecord.gender}</p>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item className="setting-item" name="domicile" label="Nguyên quán">
                                <p className="user-content">{ministryRecord.provinceDto.name}</p>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item className="setting-item" name="regency" label="Chức vụ">
                                <div className="user-content">
                                    {regency?.map((item: any) => (
                                        <Tag key={item}>{item}</Tag>
                                    ))}
                                </div>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item className="setting-item" name="academicLevel" label="Trình độ học vấn">
                                <div className="user-content">
                                    {academicLevel?.map((item: any) => (
                                        <Tag key={item}>{item}</Tag>
                                    ))}
                                </div>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item className="setting-item" name="email" label="Email">
                                <p className="user-content">{ministryRecord.email}</p>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item className="setting-item" name="phone" label="Điện thoại liên hệ">
                                <p className="user-content">{ministryRecord.phoneNumber}</p>
                            </Form.Item>
                        </Col>
                    </Row>
                </CustomCard>
            </div>
        </section>
    )
}
export default MinistryDetail;