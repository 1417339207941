import {Button, Collapse, Space, Typography} from 'antd';
import React from 'react';
import CustomCard from "../../../../custom-card/CustomCard";
import DrawerCustom from "../../../../drawer/Drawer";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import './index.scss'
import {isNull} from "lodash";

type HistoryCensorshipProps = {
  isOpened: boolean
  closeForm: () => void
  dataDetail?: any | null
}

const HistoryCensorship = ({isOpened, closeForm, dataDetail}: HistoryCensorshipProps) => {
  const {Panel} = Collapse;
  const {Title, Text} = Typography;
  const {
    title,
    description,
    code,
    numberQuestion,
    topics,
    numberOfUse,
    authors,
    access,
    publishingUnit,
    tags,
    hierarchy,
    createdBy
  } = dataDetail || {};
  return (
    <DrawerCustom title={'Lịch sử kiểm duyệt'}
                  open={isOpened}
                  onClose={closeForm}
                  bodyStyle={{
                    padding: 0,
                  }}
                  extraNode={
                    <Space>
                      <Button
                        onClick={closeForm}
                        className="btn btn-outlined"
                      >
                        <CloseOutlined/>
                        <Typography.Text>Hủy</Typography.Text>
                      </Button>
                      <Button
                        className="btn btn-primary"
                        htmlType="submit"
                        // onClick={saveData}
                      >
                        <SaveOutlined/>
                        <Typography.Text>Lưu</Typography.Text>
                      </Button>
                    </Space>
                  }
                  widthDrawer={736}
    >
      <div>
        <CustomCard
          className="card-detail-exam"
        >
          <div>
            <Collapse defaultActiveKey={['1', '2']} ghost className="collapse-exam-detail">
              <Panel header="Duyệt chỉnh sửa" key="1" className="collapse-exam-detail--header">
                <div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Nội dung gửi duyệt:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {code}
                      </Text>
                    </div>
                  </div>

                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Ngày gửi duyêt:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {title}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Nội dung duyệt:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {topics}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Người duyệt:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {numberQuestion}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Ngày duyệt:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {description}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Ngày xuất bản:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {tags}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Tình trạng:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {!isNull(numberOfUse) ? numberOfUse : 0}
                      </Text>
                    </div>
                  </div>
                </div>
              </Panel>

            </Collapse>
            <Collapse defaultActiveKey={['1', '2']} ghost className="collapse-exam-detail">
              <Panel header="Duyệt mới" key="1" className="collapse-exam-detail--header">
                <div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Nội dung gửi duyệt:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {code}
                      </Text>
                    </div>
                  </div>

                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Ngày gửi duyêt:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {title}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Nội dung duyệt:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {topics}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Người duyệt:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {numberQuestion}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Ngày duyệt:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {description}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Ngày xuất bản:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {tags}
                      </Text>
                    </div>
                  </div>
                  <div className="list-item">
                    <Title level={5} className="list-item--title">
                      Tình trạng:
                    </Title>
                    <div className="list-item--description">
                      <Text className="content">
                        {!isNull(numberOfUse) ? numberOfUse : 0}
                      </Text>
                    </div>
                  </div>
                </div>
              </Panel>

            </Collapse>
          </div>
        </CustomCard>
      </div>
    </DrawerCustom>
  );
};

export default HistoryCensorship;