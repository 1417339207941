import React, { forwardRef, useImperativeHandle, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface Value {
  count: number;
  percentage: number | null;
  criteria: string;
}

interface DataItem {
  type: number;
  criteria: string;
  values: Value[];
}

interface BarStackedChartProps {
  data: DataItem[];
  title?: string;
  colors?: string[];
  yAxisTitle?: string;
  hasTotal?: boolean;
  showDataLabels?: boolean;
}

const BarStackedChart = forwardRef((props: BarStackedChartProps, ref) => {
  const {
    data,
    title,
    colors,
    yAxisTitle,
    hasTotal = true,
    showDataLabels,
  } = props;

  const categories = data.map((item) => item.criteria);

  const uniqueCriteria = Array.from(
    new Set(data.flatMap((item) => item.values.map((value) => value.criteria)))
  );

  const seriesDataMap: { [key: string]: number[] } = {};

  uniqueCriteria.forEach((criteria) => {
    seriesDataMap[criteria] = Array(data.length).fill(0);
  });

  data.forEach((item, index) => {
    item.values.forEach((value) => {
      if (seriesDataMap[value.criteria]) {
        seriesDataMap[value.criteria][index] = value.count;
      }
    });
  });
  
  const series = Object.keys(seriesDataMap).map((criteria) => ({
    name: criteria,
    data: seriesDataMap[criteria],
  }));

  const calculateTotal = () => {
    let total = 0;
    data.forEach((item) => {
      item.values.forEach((value) => {
        total += value.count;
      });
    });
    return total;
  };

  const options: Highcharts.Options = {
    chart: {
      type: "bar",
      scrollablePlotArea: {
        minHeight: categories?.length * 60,
        scrollPositionX: 1,
      },
    },
    title: {
      text: hasTotal
        ? `<div class="static-chart-total">Tổng số: <span class="static-chart-count">${calculateTotal()}</span></div>`
        : title || "",
      useHTML: true,
    },
    xAxis: {
      categories,
      labels: {
        style: {
          fontSize: "12px",
          maxWidth: "200px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        useHTML: true,
        formatter: function () {
          return `<span title="${this.value}">${this.value}</span>`;
        },
      },
      allowDecimals: false,
    },
    yAxis: {
      min: 0,
      title: {
        text: yAxisTitle,
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
          color:
            (Highcharts.defaultOptions.title?.style &&
              Highcharts.defaultOptions.title.style.color) ||
            "gray",
        },
        formatter: function () {
          return `${this.total}`;
        },
      },
      allowDecimals: false,
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        return `
          <div>
              <b style="color: #000; font-size: 12px; font-weight: 500;opacity: 0.45;">${this.series.name}</b><br/>
             <span style="color:${this.color}">\u25CF</span>  <b style="color: #000; font-size: 12px; font-weight: 500;margin-top:6px;">${this.y}</b>
          </div>`;
      },
      backgroundColor: "#ffffff",
      borderColor: "#ccc",
      borderRadius: 6,
      borderWidth: 1,
      style: {
        color: "#333",
        fontSize: "12px",
      },
      shadow: false,
      outside: true,
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      layout: "horizontal",
      itemStyle: { fontSize: "12px" },
      symbolHeight: 12,
      symbolWidth: 12,
      reversed: true,
    },
    plotOptions: {
      bar: {
        stacking: "normal",
        dataLabels: {
          enabled: showDataLabels,
        },
        pointWidth: 20,
        borderRadius: 4,
      },
    },

    series: series as Highcharts.SeriesOptionsType[],
    colors: colors,
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: true,
      allowHTML: true,
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: {
          width: 1000,
        },
        yAxis: {
          labels: {
            useHTML: true,
          },
        },
      },
    },
  };

  const chartRef = useRef<HighchartsReact.RefObject>(null);

  useImperativeHandle(ref, () => ({
    getChart: () => chartRef.current?.chart,
  }));

  return (
    <div className="mt-2">
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
});

export default BarStackedChart;
