import { DownOutlined, SearchOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Col, Row, Typography } from "antd";
import React, { ChangeEvent, FunctionComponent, KeyboardEvent } from "react";
import FormInput from "../../../form-input/FormInput";
import "./filterBankExam.scss";

type FilterBankExamProps = {
  searchValue: string;
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void;
  handleSubmitSearch: () => void;
  handleOpenFilter: () => void;
};

const FilterBankExam: FunctionComponent<FilterBankExamProps> = ({
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
}) => {
  return (
    <>
      <div className="heading-extra">
        <Row gutter={[8, 8]}>
          <Col>
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text> Bộ lọc </Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col>
            <div className="heading-search">
              <FormInput placeholder="Nhập từ khóa cần tìm" value={searchValue}
                onChange={handleChangeInput} onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FilterBankExam;
