import React, { useState } from 'react';
import { Modal, Radio, Typography, Form, DatePicker, TimePicker, RadioChangeEvent, Button, Row, Col, Space } from 'antd';
import "./publish-exam.scss";
import { CloseOutlined, UpCircleOutlined } from '@ant-design/icons';
import { Dayjs } from 'dayjs';
type SchedulePublishModalProps = {
  onClose: () => void;
  open: boolean;
  onFinish: (values: any) => void;
};

const SchedulePublishModal: React.FC<SchedulePublishModalProps> = ({  onClose,open,onFinish }) => {
  const [form] = Form.useForm();
  const [publishType, setPublishType] = useState('1');
  const onOk = () => {
    form.submit();
  }
  const handleRadioChange = (e: RadioChangeEvent) => {
    setPublishType(e.target.value);
    if (e.target.value === '2') {
      form.setFieldsValue({
        date: null,
        time: null
      });
    }
  };
  return (
    <Modal
      title="Xuất bản nội dung"
      onCancel={onClose}
      open={open}
      onOk={onOk}
      className='publish-exam-library'
      footer={
        <div className='footer'>
        <Button
          icon={<CloseOutlined />}
          onClick={onClose}
          style={{ marginRight: '10px' }}>
          Hủy
        </Button>
        <Button type="primary"
          icon={<UpCircleOutlined />}
          onClick={onOk}
          >
          Xuất bản
        </Button>
      </div>
      }>
      <Form form={form} onFinish={onFinish} initialValues={{ select: '2' }}>
        <Form.Item name='select'>
          <Radio.Group onChange={handleRadioChange}>
            <Space direction="vertical">
            <Radio value="2">
              <Typography.Text>Xuất bản ngay</Typography.Text>
            </Radio>
            <Radio value="3">
              <Typography.Text>Hẹn lịch</Typography.Text>
            </Radio>
            </Space>   
          </Radio.Group>
        </Form.Item>
        <div className='dateTime'>
        <Form.Item name="date" className='date-time-picker' rules={[
              {
                required: publishType === '3',
                message: 'Vui lòng chọn ngày!'
              }
            ]}>
          <DatePicker format="DD/MM/YYYY" style={{ marginRight: '10px' }} placeholder='Ngày' disabled={publishType === '2'}  />
        </Form.Item>
        <Form.Item name="time" rules={[
              {
                required: publishType === '3',
                message: 'Vui lòng chọn giờ!'
              }
            ]}>
          <TimePicker format="HH:mm:ss" placeholder='Giờ' disabled={publishType === '2'} />
        </Form.Item>
        </div>
        
      </Form>
      
    </Modal>
  );
};

export default SchedulePublishModal;
