import React, { useEffect, useMemo, useRef, useState } from "react";
import "./detail.scss";
import {
  Input,
  Button,
  Space,
  Card,
  Dropdown,
  Modal,
  MenuProps,
  Row,
  Col,
  Form,
  Typography,
  Empty,
  message,
} from "antd";

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ClearOutlined,
  CopyOutlined,
  DeleteOutlined,
  FilterOutlined,
  MoreOutlined,
} from "@ant-design/icons";

import {
  ListQuestion,
  ListSurveySection,
  SurveyInfo,
} from "../../../../../../types/survey-managment/survey";
import RightControl from "../right-control/RightControl";
import FormItemDropdown from "../../../../../../components/form-dropdown/FormItemDropdown";
import FormItemInput from "../../../../../../components/form-input/FormInput";
import { useForm } from "antd/es/form/Form";
import { searchQuestionInSurvey } from "../../../../../../service/survey-managment/survey-content";
import {
  useSurveyDetail,
  useSurveyInfo,
} from "../../../../../../stores/stores";
import DetailQuestion from "../detail-question/DetailQuestion";
import EditorQuill from "../editor-quill/EditorQuill";
import SectionQuestionFilter from "../detail-question/section-question-filter/SectionQuestionFilter";
import {
  MoocSurveyContentAnswer,
  MoocSurveyContentQuestion,
} from "../../../../../../types/survey-managment/mooc-survey-content";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import EditorHtml from "../editor-quill/EditorHtml";
import { getSurveyById } from "../../../../../../service/survey-managment/survey-synthesis/survey-systhesis";
import { downloadFile } from "../../../../../../service/course-plan-managment/mooc-course-construction-plan/moocCourseConstructionPlanApi";

const { TextArea } = Input;

interface DetailSectionProps {
  dataSection: ListSurveySection[]; // Assuming listSurveySection[] is the correct type
  setUpdateDataSection: (data: ListSurveySection[]) => void;
  setDataSection: (data: ListSurveySection[]) => void;
  surveyInfo?: SurveyInfo;
}

interface questionType {
  value: number;
  label: string;
}

const dataRequired = [
  {
    label: "Bắt buộc",
    value: true,
  },
  {
    label: "Tuỳ chọn",
    value: false,
  },
];

const optionsQuestionType: questionType[] = [
  {
    value: 1,
    label: "Nhiều lựa chọn văn bản",
  },
  {
    value: 2,
    label: "Nhiều lựa chọn ảnh",
  },
  {
    value: 3,
    label: "Lựa chọn đúng sai",
  },
  {
    value: 4,
    label: "Lựa chọn Dropdown",
  },
  {
    value: 5,
    label: "Nhiều trả lời văn bản",
  },
  {
    value: 6,
    label: "Nhiều trả lời ảnh",
  },
  {
    value: 7,
    label: "Câu hỏi kết nối",
  },
  {
    value: 8,
    label: "Câu trả lời ngắn",
  },
];

const options1: MenuProps["items"] = [
  {
    key: "1",
    label: "Tạo bản sao",
    icon: <CopyOutlined />,
  },
  {
    key: "2",
    label: "Chuyển lên trên",
    icon: <ArrowUpOutlined />,
  },
  {
    key: "3",
    label: "Chuyển xuống dưới",
    icon: <ArrowDownOutlined />,
  },
  {
    key: "4",
    label: "Xóa cả thành phần và câu hỏi",
    icon: <DeleteOutlined />,
    className: "dropdown-delete",
    danger: true,
  },
];

const DetailSection: React.FC<DetailSectionProps> = ({
  dataSection,
  setUpdateDataSection,
  setDataSection,
  surveyInfo,
}) => {
  const {
    listSurveySections,
    listSurveyFilterSection,
    setListSurveyFilterSection,
  } = useSurveyDetail((state) => {
    return {
      listSurveySections: state.listSurveySections,
      listSurveyFilterSection: state.listSurveyFilterSection,
      setListSurveyFilterSection: state.setListSurveyFilterSection,
    };
  });

  const updateSurveyDescription = useSurveyInfo(
    (state) => state?.updateSurveyDescription
  );
  const [isShowSurveyDescription, setIsShowSurveyDescription] = useState(false);
  const [surveyDescription, setSurveyDescription] = useState<string>(
    surveyInfo?.surveyDescription || ``
  );
  const [listQuestionAddMore, setListQuestionAddMore] = useState<
    MoocSurveyContentQuestion[]
  >([]);

  const listData = useMemo(() => dataSection, [dataSection]);

  const [descriptionList, setDescriptionList] = useState(
    dataSection.map((item: ListSurveySection) => item.sectionDescription || "")
  );

  const moveItemUp = (index: number) => {
    if (index === 0) return; // Already at the top
    let newListSurveySections = [...listData];
    // Di chuyển phần tử lên trên
    [newListSurveySections[index - 1], newListSurveySections[index]] = [
      newListSurveySections[index],
      newListSurveySections[index - 1],
    ];
    // Cập nhật lại orderNumber
    newListSurveySections[index - 1].orderNumber = index; // Cập nhật orderNumber của phần tử mới
    newListSurveySections[index].orderNumber = index + 1; // Cập nhật orderNumber của phần tử bị di chuyển

    setUpdateDataSection(newListSurveySections); //cập nhật lên page DetailSurvey
    setDescriptionList(
      newListSurveySections.map((item) => item.sectionDescription || "")
    );
  };

  const moveItemDown = (index: number) => {
    if (index === listData.length - 1) return; // Already at the bottom
    let newListSurveySections = [...listData];
    [newListSurveySections[index], newListSurveySections[index + 1]] = [
      newListSurveySections[index + 1],
      newListSurveySections[index],
    ];

    // Cập nhật lại orderNumber
    newListSurveySections[index].orderNumber = index + 1; // Cập nhật orderNumber của phần tử bị di chuyển
    newListSurveySections[index + 1].orderNumber = index + 2; // Cập nhật orderNumber của phần tử mới

    setUpdateDataSection(newListSurveySections); //cập nhật lên page DetailSurvey
    setDescriptionList(
      newListSurveySections.map((item) => item.sectionDescription || "")
    );
  };

  const [optionDropDown, setIsOption] = useState(options1);

  const handleOptionDropDown = (index: number) => {
    let newOptions = [...options1];

    if (listData.length < 2) {
      newOptions.splice(1, 2);
    }
    if (listData.length > 1) {
      if (index === 0) {
        newOptions.splice(1, 1);
      }
      if (index === listData.length - 1) {
        newOptions.splice(2, 1);
      }
    }
    setIsOption(newOptions);
  };

  const [isShowFilter, setIsShowFilter] = useState(false);
  const [isFilterQuestion, setIsFilterQuestion] = useState<boolean>(false);

  const handleShowFilterQuestion = () => {
    setIsShowFilter(!isShowFilter);
  };
  const [form] = useForm();

  const handleResetFieldFilter = () => {
    setIsFilterQuestion(false);
    form.resetFields();
    setListSurveyFilterSection([]);
  };

  function filterSectionsAndQuestions(sections: any, criteria: any) {
    const isRequiredCheck = Array.isArray(criteria.isRequired)
      ? criteria.isRequired
      : null;
    const questionName =
      typeof criteria.questionName === "string"
        ? criteria.questionName.toLowerCase()
        : "";
    const questionType = Array.isArray(criteria.questionType)
      ? criteria.questionType
      : null;

    return sections.reduce((filteredSections: any, section: any) => {
      const filteredQuestions = section.moocSurveyQuestion.filter(
        (question: any) => {
          const matchesRequired =
            isRequiredCheck === null ||
            isRequiredCheck.includes(question.isRequired);
          const matchesName = question.questionName
            .toLowerCase()
            .includes(questionName);
          const matchesType =
            questionType === null ||
            questionType.includes(question.questionType);

          return matchesRequired && matchesName && matchesType;
        }
      );

      if (filteredQuestions.length > 0) {
        filteredSections.push({
          ...section,
          moocSurveyQuestion: filteredQuestions,
        });
      }
      return filteredSections;
    }, []);
  }
  const handleDeleteAllSectionsKeepQuestions = () => {
    let newSectionWithOnlyQuestions = {
      sectionName: "Phần mới chỉ chứa câu hỏi",
      isDeleted: false,
      orderNumber: 1,
      moocSurveyQuestion: [] as ListQuestion[],
    };

    dataSection.forEach((section: ListSurveySection) => {
      section.moocSurveyQuestion.forEach((question) => {
        newSectionWithOnlyQuestions.moocSurveyQuestion.push({
          ...question,
          orderNumber: question.orderNumber + section.orderNumber * 1000,
        });
      });
    });

    newSectionWithOnlyQuestions.moocSurveyQuestion.sort(
      (a, b) => a.orderNumber - b.orderNumber
    );

    setUpdateDataSection([newSectionWithOnlyQuestions]);

    setDescriptionList(["Phần mới chỉ chứa câu hỏi"]);

    message.success(
      "Xóa tất cả thành phần và giữ lại toàn bộ câu hỏi thành công!"
    );
  };

  const onFinish = async (values: any) => {
    setIsFilterQuestion(true);
    if (values.questionType && values.questionType.length > 0) {
      const questionTypeNumbers = values.questionType.map((item: string) =>
        Number(item)
      );
      values.questionType = questionTypeNumbers;
    }
    if (values && values.isRequired && values.isRequired.length === 0) {
      values.isRequired = undefined;
    }
    if (values && values.questionType && values.questionType.length === 0) {
      values.questionType = undefined;
    }
    if (values && values.questionName && values.questionName.length === 0) {
      values.questionName = undefined;
    }

    if (
      values.isRequired === null &&
      values.questionType === null &&
      values.questionName === ""
    ) {
      setListSurveyFilterSection([]);
    }
    if (listSurveySections && listSurveySections.length > 0) {
      const newList = filterSectionsAndQuestions(listSurveySections, values);
      if (newList.length > 0) {
        setListSurveyFilterSection(newList);
      } else {
        setListSurveyFilterSection([]);
      }
    }
  };

  const handleCopySection = (sectionNumber: number) => {
    let newSection: ListSurveySection = {
      ...listData[sectionNumber],
      id: undefined,
    };
    let updatedListData = [...listData];
    updatedListData.splice(sectionNumber, 0, newSection);

    // Cập nhật lại orderNumber cho các phần tử sau vị trí được copy
    for (let i = sectionNumber; i < updatedListData.length; i++) {
      updatedListData[i].orderNumber = i + 1;
    }
    setUpdateDataSection(updatedListData);
    setDescriptionList(
      updatedListData.map((item) => item.sectionDescription || "")
    );
  };

  const handleDeleteSection = (sectionNumber: number) => {
    let updatedListData: ListSurveySection[] = listData.map((item, index) => {
      if (index === sectionNumber) {
        return {
          ...item,
          isDeleted: true,
        };
      }

      return item;
    });

    // Cập nhật lại orderNumber cho các phần tử sau vị trí bị xóa
    for (let i = sectionNumber + 1; i < updatedListData.length; i++) {
      updatedListData[i].orderNumber = i;
    }
    message.success("Xóa thành công thành phần câu hỏi đợt khảo sát");

    setUpdateDataSection(updatedListData);
    setDescriptionList(
      updatedListData.map((item) => item.sectionDescription || "")
    );
  };

  const [indexQuestionPosition, setIndexQuestionPosition] = useState(0);
  const [indexSectionPosition, setIndexSectionPosition] = useState(0);
  const [isCheckQuestionPosition, setIsCheckQuestionPosition] = useState(false);

  const checkSectionActive = () => {
    var positionSection = 0;
    var elementTopMax = -500;
    var indexElementTopMax = 0;
    var allElementTopLessThanZero = true;

    listData.forEach((item, index) => {
      const element = document.getElementById(`sectionElement-${index}`);
      if (element) {
        const rect = element.getBoundingClientRect();
        const elementTop = rect.top;
        const elementBottom = rect.bottom;

        //case1:
        if (elementTop <= 600 && elementTop >= 0) {
          positionSection = index;
          allElementTopLessThanZero = false; // At least one elementTop is not less than zero
          return positionSection;
        }
        //case2: all <0
        if (elementTop > elementTopMax) {
          elementTopMax = elementTop;
          indexElementTopMax = index;
        }
        //case3: all out of 0-600
        if (elementTop < 0 && elementBottom > 600) {
          positionSection = index;
          allElementTopLessThanZero = false; // At least one elementTop is not less than zero
          return positionSection;
        }
      }
    });

    // If all elementTop values are less than zero, set positionSection to the index of the element with the largest elementTop
    if (allElementTopLessThanZero) {
      positionSection = indexElementTopMax;
    }
    return positionSection;
  };

  const checkQuestionActive = () => {
    var positionQuestion = 0;
    listData.forEach((item, index) => {
      item.moocSurveyQuestion.forEach((question, ind) => {
        const sectionElement = document.getElementById(
          `sectionElement-${index}`
        );
        if (sectionElement) {
          const questionElement = sectionElement.querySelector(
            `#questionElement-${ind}`
          );
          if (questionElement) {
            const rect = questionElement.getBoundingClientRect();
            const elementTop = rect.top;
            if (elementTop <= 500 && elementTop >= 0) {
              positionQuestion = ind;
              return positionQuestion;
            }
          }
        }
      });
    });
    return positionQuestion;
  };

  const handleAddNewSection = () => {
    var positionSection = checkSectionActive();

    const newSection: ListSurveySection = {
      sectionName: `Phần mới`,
      isDeleted: false,
      orderNumber: positionSection + 1,
      moocSurveyQuestion: [
        {
          questionName: `Câu hỏi mới`,
          questionType: 1,
          orderNumber: 1,
          isDeleted: false,
          isRequired: false,
          moocSurveyQuestionAnswer: [],
        },
      ],
    };
    const updatedListData = [...listData];
    updatedListData.splice(positionSection + 1, 0, newSection);
    for (let i = positionSection; i < updatedListData.length; i++) {
      updatedListData[i].orderNumber = i + 1;
    }
    setUpdateDataSection(updatedListData);
    setDescriptionList(
      updatedListData.map((item) => item.sectionDescription || "")
    );
  };

  const handleAddNewQuestion = () => {
    var positionQuestion = checkQuestionActive();
    var positionSection = checkSectionActive();

    const newQuestion: ListQuestion = {
      questionName: `Câu hỏi mới`,
      questionType: 1,
      isDeleted: false,
      isRequired: false,
      orderNumber: 1,
      moocSurveyQuestionAnswer: [],
    };

    const updatedListData = [...listData];
    // Chèn câu hỏi vào vị trí indexQuestionPosition
    updatedListData[positionSection].moocSurveyQuestion.splice(
      positionQuestion + 1,
      0,
      newQuestion
    );

    // Cập nhật lại orderNumber cho các câu hỏi sau
    updatedListData[positionSection].moocSurveyQuestion.forEach(
      (question, index) => {
        question.orderNumber = index + 1;
      }
    );

    setUpdateDataSection(updatedListData);
    setDescriptionList(
      updatedListData.map((item) => item.sectionDescription || "")
    );
  };

  const handleAddMoreQuestion = (listQuestion: ListQuestion[]) => {
    var positionQuestion = checkQuestionActive();
    var positionSection = checkSectionActive();

    let newQuestion: ListQuestion;
    for (const question of listQuestion) {
      newQuestion = {
        questionName: question.questionName,
        questionDescription: question.questionDescription,
        questionType: Number(question.questionType),
        isDeleted: false,
        isRequired: question.isRequired,
        orderNumber: question.orderNumber,
        moocSurveyQuestionAnswer: question.moocSurveyQuestionAnswer,
      };
      const updatedListData = [...listData];
      // Chèn câu hỏi vào vị trí indexQuestionPosition
      updatedListData[positionSection].moocSurveyQuestion.splice(
        positionQuestion + 1,
        0,
        newQuestion
      );

      // Cập nhật lại orderNumber cho các câu hỏi sau
      updatedListData[positionSection].moocSurveyQuestion.forEach(
        (question, index) => {
          question.orderNumber = index + 1;
        }
      );

      setUpdateDataSection(updatedListData);
      setDescriptionList(
        updatedListData.map((item) => item.sectionDescription || "")
      );
    }
  };

  const [isShowDetailSection, setIsShowDetailSection] = useState(
    new Array(listData.length).fill(true)
  );

  const [isShowDescriptionList, setIsShowDescriptionList] = useState(
    new Array(listData.length).fill(false)
  );

  const handleUpdateSectionName = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newValue = e.target.value;
    const updatedListData = listData.map((item, idx) => {
      if (idx === index) {
        return { ...item, sectionName: newValue };
      }
      return item;
    });
    setUpdateDataSection(updatedListData);
    setDescriptionList(
      updatedListData.map((item) => item.sectionDescription || "")
    );
  };

  const param = useParams();

  const addClassToMediaParagraphs = (htmlString: any) => {
    // Parse the HTML string into a DOM structure
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    // Find all <p> elements
    const paragraphs = doc.querySelectorAll("p");

    // Iterate through the <p> elements and check if they contain <img> tags
    paragraphs.forEach((p) => {
      if (p.querySelector("img")) {
        // Add the class "list-media" to <p> elements containing <img> tags
        p.classList.add("file-media");
      }
    });

    // Serialize the DOM structure back into an HTML string
    return doc.body.innerHTML;
  };
  const handleUpdateSurveyDescription = async (value: any) => {
    const updatedDescription = addClassToMediaParagraphs(value);
    updateSurveyDescription(Number(param.id), updatedDescription);
  };

  const [topValueActive, setTopValueActive] = useState(0);

  // Hàm kiểm tra top value của câu hỏi
  const checkTopValueQuestionActive = () => {
    let topValue = 0;


    // Lấy phần tử của câu hỏi đầu tiên (section 0, question 0)
    const firstSectionElement = document.getElementById("sectionElement-0");
    let firstQuestionElementTop = 0;

    if (firstSectionElement) {
      const firstQuestionElement =
        firstSectionElement.querySelector("#questionElement-0");
      if (firstQuestionElement) {
        firstQuestionElementTop =
          firstQuestionElement.getBoundingClientRect().top;
      }
    }

    // Duyệt qua tất cả câu hỏi để tính toán topValue
    listData.forEach((item, index) => {
      item.moocSurveyQuestion.forEach((question, ind) => {
        const sectionElement = document.getElementById(
          `sectionElement-${index}`
        );
        if (sectionElement) {
          const questionElement = sectionElement.querySelector(
            `#questionElement-${ind}`
          );
          if (questionElement) {
            const rect = questionElement.getBoundingClientRect();
            const elementTop = rect.top;

            // Kiểm tra nếu phần tử đang nằm giữa màn hình (trong khoảng từ 0 đến 500)
            if (elementTop <= 500 && elementTop >= 0) {
              // Tính toán khoảng cách từ câu hỏi đầu tiên đến câu hỏi hiện tại
              topValue = elementTop - firstQuestionElementTop;
            }
          }
        }
      });
    });

    return topValue;
  };

  // Gọi hàm updateTopValueActive mỗi 500ms để kiểm tra liên tục
  setTimeout(() => {
    setTopValueActive(checkTopValueQuestionActive());
  }, 200);

  const updateDescription = (newDescription: string, index: number) => {
    var updatedListData = listData.map((item, idx) => {
      if (idx === index) {
        return { ...item, sectionDescription: newDescription };
      }
      return item;
    });

    setUpdateDataSection(updatedListData);
  };

  const handleToggleDescription = (index: number) => {
    if (listData.length > 1) {
      const newIsShowDescriptionList = [...isShowDescriptionList];
      newIsShowDescriptionList[index] = !newIsShowDescriptionList[index];
      setIsShowDescriptionList(newIsShowDescriptionList);
    } else {
      setIsShowDetailSection([true]);
      setDescriptionList(listData.map((item) => item.sectionDescription || ""));
    }
  };

  const handleToggleShowDetailSection = (index: number) => {
    if (listData.length > 1) {
      const newIsShowDetailSection = [...isShowDetailSection];
      newIsShowDetailSection[index] = !newIsShowDetailSection[index];
      setIsShowDetailSection(newIsShowDetailSection);
    } else {
      setIsShowDetailSection([true]);
    }
  };
  function getAllSrcValues(html: string) {
    const srcPattern = /src="([^"]*)"/g;
    const filePattern = /bucket\/(.*?)\?X-Amz-Algorithm/;
    const matches = [];
    let match;
    while ((match = srcPattern.exec(html)) !== null) {
      const srcValue = match[1];
      const fileMatch = filePattern.exec(srcValue);
      if (fileMatch) {
        matches.push({
          file: fileMatch[1],
        });
      }
    }
    return matches;
  }
  useEffect(() => {
    let arr: any = [];
    if (surveyInfo && surveyInfo?.surveyDescription) {
      arr = getAllSrcValues(surveyInfo.surveyDescription);

      const fetchAllUrls = async () => {
        try {
          const promises = arr.map((item: any) => fetchUrl(item.file));
          const results = await Promise.all(promises);

          let parser: any = new DOMParser();
          let doc: any = parser.parseFromString(
            surveyInfo?.surveyDescription,
            "text/html"
          );
          let elements: any = doc.querySelectorAll("iframe, img");

          results.forEach((newDesc, index) => {
            let element = elements[index];
            if (element) {
              if (
                element.tagName.toLowerCase() === "img" &&
                isImage(newDesc.data)
              ) {
                element.src = newDesc.data;
              } else if (
                element.tagName.toLowerCase() === "iframe" &&
                isVideo(newDesc.data)
              ) {
                element.src = newDesc.data;
              }
            }
          });

          let updatedDescription = doc.body.innerHTML;
          setSurveyDescription(updatedDescription);
          // setListUrl(arr);
        } catch (error) {}
      };

      fetchAllUrls();
    }
  }, []);

  const fetchUrl = async (url: string) => {
    const res = await downloadFile({ filePath: url });
    return res;
  };
  const imagePattern = /\.(jpg|jpeg|png)(\?.*)?$/i;
  const videoPattern = /\.(mp4|mov|wmv|flv|avi|mkv|webm)(\?.*)?$/i;

  const isImage = (url: any) => {
    return imagePattern.test(url);
  };

  const isVideo = (url: any) => {
    return videoPattern.test(url);
  };

  return (
    <div className="survey-managment-section">
      <div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div>
            {isShowFilter && (
              <div style={{ marginBottom: "20px" }}>
                <Form name="filterForm" onFinish={onFinish} form={form}>
                  <Row gutter={16}>
                    <Col className="gutter-row" span={6}>
                      <Form.Item name="questionName">
                        <FormItemInput placeholder="Nhập tên câu hỏi" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <Form.Item name="questionType">
                        <FormItemDropdown
                          modeDropdown="multiple"
                          placeholder="Loại câu hỏi"
                          options={optionsQuestionType}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <Form.Item name="isRequired">
                        <FormItemDropdown
                          modeDropdown="multiple"
                          placeholder="Bắt buộc, tuỳ chọn"
                          options={dataRequired}
                        />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <div className="group-btn--filter">
                        <Button
                          className="btn btn-primary filter-btn"
                          htmlType="submit"
                        >
                          <FilterOutlined />
                          <Typography.Text>Lọc</Typography.Text>
                        </Button>
                        <Button
                          className="btn btn-outlined clear-filter-btn"
                          onClick={handleResetFieldFilter}
                        >
                          <ClearOutlined />
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}

            {isFilterQuestion ? (
              <div style={{ marginBottom: "20px" }}>
                {listSurveyFilterSection &&
                listSurveyFilterSection.length > 0 ? (
                  <SectionQuestionFilter
                    setUpdateDataSection={setUpdateDataSection}
                  />
                ) : (
                  <Card
                    className="wrapper-settings--card form-setting--common "
                    style={{ marginBottom: "20px", width: "685px" }}
                  >
                    <Empty
                      description={`Không tìm thấy kết quả với dữ liệu lọc như trên`}
                    />
                  </Card>
                )}
              </div>
            ) : (
              <div style={{ marginBottom: "200px" }}>
                <div
                  style={
                    isShowSurveyDescription
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <Card
                    title="Mô tả đợt khảo sát"
                    styles={{
                      header: { backgroundColor: "#e6f7ff" },
                    }}
                    className="wrapper-settings--card form-setting--common"
                    extra={
                      <>
                        <Row></Row>
                      </>
                    }
                  >
                    <Space
                      direction="vertical"
                      size="middle"
                      style={{ display: "flex" }}
                    >
                      <div className="file-media">
                        <EditorHtml
                          valueInitial={surveyDescription}
                          handleOnChange={(value: any) => {
                            setSurveyDescription(value);
                            handleUpdateSurveyDescription(value);
                          }}
                          //handleOnBlur={handleUpdateSurveyDescription}
                          //  handleOnChangeSelection={handleUpdateSurveyDescription}
                          sizeContainer={200}
                        />
                      </div>
                    </Space>
                  </Card>
                </div>
                {listData.map((item, index) => {
                  const {
                    sectionName,
                    sectionDescription,
                    moocSurveyQuestion,
                  }: ListSurveySection = item;
                  const updateNewListSectionQuestions = (
                    newList: ListQuestion[]
                  ) => {
                    // Tạo một mảng mới để cập nhật lại listSurveySections
                    const updatedListSurveySections = listData.map(
                      (section, position) => {
                        // Tìm section có id tương ứng với section đang xem xét
                        if (position === index) {
                          // Trả về một bản sao của section với moocSurveyQuestion được cập nhật
                          return {
                            ...section,
                            moocSurveyQuestion: newList,
                          };
                        }
                        // Trả về các section khác không cần cập nhật
                        return section;
                      }
                    );

                    // Cập nhật state hoặc dữ liệu cha với mảng mới đã được cập nhật
                    setUpdateDataSection(updatedListSurveySections); //cập nhật lên page DetailSurvey
                    setDescriptionList(
                      updatedListSurveySections.map(
                        (item) => item.sectionDescription || ""
                      )
                    );
                  };

                  const shortSectionName = sectionName
                    ? sectionName.length > 50
                      ? sectionName?.slice(0, 50)
                      : sectionName
                    : "";
                  return (
                    <div key={index} id={`sectionElement-${index}`}>
                      {/* Section detail */}

                      <div
                        style={
                          listData.length === 1
                            ? { display: "none" }
                            : { display: "block" }
                        }
                      >
                        <Card
                          title={
                            listData.length < 2
                              ? ``
                              : `Phần ${index + 1}/${
                                  listData.length
                                } : ${shortSectionName} . . .` //`Phần ${index + 1}/${listSurveySections.length}`
                          }
                          styles={{
                            header: { backgroundColor: "#e6f7ff" },
                            body: {
                              display: isShowDetailSection ? "block" : "none",
                            },
                          }}
                          className="wrapper-settings--card form-setting--common"
                          extra={
                            <>
                              <Row>
                                <Space>
                                  <Col>
                                    <Button
                                      onClick={() =>
                                        handleToggleShowDetailSection(index)
                                      }
                                    >
                                      {isShowDetailSection[index]
                                        ? `Thu gọn`
                                        : `Hiển thị chi tiết`}
                                    </Button>
                                  </Col>
                                  <Col>
                                    <Dropdown
                                      onOpenChange={() =>
                                        handleOptionDropDown(index)
                                      }
                                      menu={{
                                        items: optionDropDown,
                                        onClick: ({ key }) => {
                                          if (key === "1") {
                                            Modal.confirm({
                                              title: `Tạo bản sao thành phần ${
                                                index + 1
                                              }`,
                                              content: (
                                                <>
                                                  Bạn có chắc chắn muốn tạo bản
                                                  sao của thành phần này trong
                                                  khảo sát?
                                                </>
                                              ),
                                              onOk: () =>
                                                handleCopySection(index),
                                              okText: "Đồng ý",
                                              cancelText: "Huỷ",
                                              centered: true,
                                            });
                                          }

                                          if (key === "2") {
                                            moveItemUp(index);
                                          }
                                          if (key === "3") {
                                            moveItemDown(index);
                                          }
                                          if (key === "4") {
                                            Modal.confirm({
                                              title: `Xóa phần ${index + 1}`,
                                              content: (
                                                <>
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    Cả thành phần và câu hỏi
                                                  </span>{" "}
                                                  khảo sát sẽ được xóa khỏi đợt
                                                  khảo sát. <br /> Bạn có chắc
                                                  chắn muốn{" "}
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    xóa cả thành phần và câu
                                                    hỏi?
                                                  </span>
                                                </>
                                              ),
                                              okText: "Xác nhận",
                                              cancelText: "Huỷ bỏ",
                                              centered: true,
                                              onOk: () =>
                                                handleDeleteSection(index),
                                            });
                                          }
                                        },
                                      }}
                                      placement="bottomRight"
                                    >
                                      <Button>
                                        <MoreOutlined />
                                      </Button>
                                    </Dropdown>
                                  </Col>
                                </Space>
                              </Row>
                            </>
                          }
                        >
                          {sectionName != null && (
                            <Space
                              direction="vertical"
                              size="middle"
                              style={{ display: "flex" }}
                            >
                              <Input
                                placeholder="Nhập tiêu đề"
                                style={{ fontSize: "20px", color: "red" }}
                                value={sectionName}
                                onChange={(e) =>
                                  handleUpdateSectionName(e, index)
                                }
                              />
                              {/* check here */}
                              {sectionDescription === "" ||
                              sectionDescription === null ||
                              !sectionDescription ? (
                                <>
                                  {isShowDescriptionList[index] &&
                                  isShowDetailSection[index] ? (
                                    <EditorHtml
                                      valueInitial={descriptionList[index]}
                                      handleOnChange={(content) => {
                                        var updatedDescriptionList = [
                                          ...descriptionList,
                                        ];
                                        updatedDescriptionList[index] = content;
                                        setDescriptionList(
                                          updatedDescriptionList
                                        );
                                      }}
                                      handleOnBlur={(content) =>
                                        updateDescription(content, index)
                                      }
                                      handleOnChangeSelection={(content) =>
                                        updateDescription(content, index)
                                      }
                                    />
                                  ) : (
                                    isShowDetailSection[index] && (
                                      <EditorHtml
                                        valueInitial={descriptionList[index]}
                                        handleOnChange={(content) => {
                                          var updatedDescriptionList = [
                                            ...descriptionList,
                                          ];
                                          updatedDescriptionList[index] =
                                            content;
                                          setDescriptionList(
                                            updatedDescriptionList
                                          );
                                        }}
                                        handleOnBlur={(content) =>
                                          updateDescription(content, index)
                                        }
                                        handleOnChangeSelection={(content) =>
                                          updateDescription(content, index)
                                        }
                                      />
                                    )
                                  )}
                                </>
                              ) : (
                                <>
                                  {isShowDetailSection[index] && (
                                    <EditorHtml
                                      valueInitial={descriptionList[index]}
                                      handleOnChange={(content) => {
                                        var updatedDescriptionList = [
                                          ...descriptionList,
                                        ];
                                        updatedDescriptionList[index] = content;
                                        setDescriptionList(
                                          updatedDescriptionList
                                        );
                                      }}
                                      handleOnBlur={(content) =>
                                        updateDescription(content, index)
                                      }
                                      handleOnChangeSelection={(content) =>
                                        updateDescription(content, index)
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </Space>
                          )}
                        </Card>
                      </div>

                      <DetailQuestion
                        checkIndexQuestionPosition={setIndexQuestionPosition}
                        listSectionQuestions={moocSurveyQuestion}
                        updateListSectionQuestions={
                          updateNewListSectionQuestions
                        }
                        showDetailSection={isShowDetailSection[index]}
                      />
                      {/* End of List Question */}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <RightControl
            handleShowFilterQuestion={handleShowFilterQuestion}
            surveyDescriptionContent={surveyInfo?.surveyDescription || ""}
            addNewSection={handleAddNewSection}
            addNewQuestion={handleAddNewQuestion}
            listQuestionAddMore={listQuestionAddMore}
            setListQuestionAddMore={setListQuestionAddMore}
            dataSection={dataSection}
            setDataSection={setDataSection}
            isShowSurveyDescription={isShowSurveyDescription}
            setUpdateDataSection={setUpdateDataSection}
            setIsShowSurveyDescription={setIsShowSurveyDescription}
            handleAddMoreQuestionExist={handleAddMoreQuestion}
            topValueQuestionActive={topValueActive}
            handleDeleteAllSectionsKeepQuestions={
              handleDeleteAllSectionsKeepQuestions
            }
          />
          </div>
      </div>
    </div>
  );
};

export default DetailSection;
