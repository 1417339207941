import { DownOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd';
import React, { ChangeEvent, KeyboardEvent } from 'react';
import FormInput from '../../../../form-input/FormInput';

type FilterExamTableProps = {
  handleOpen: () => void,
  searchValue: string,
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void,
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void,
  setTypeQuestion: (value: boolean) => void,
  typeQuestion: boolean,
  handleSubmitSearch: () => void,
  handleOpenFilter: () => void,
  countChoose: number,
}


const FilterExamTable = ({
  handleOpen,
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  typeQuestion,
  setTypeQuestion,
  countChoose
}: FilterExamTableProps) => {
  return (
    <>
      <div className="heading-extra">
        <Row gutter={8} wrap={false}>
          <Col md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 5 }}>
            <div className="heading-filter">
              <Button className="filter-button w-100" onClick={handleOpenFilter}>
                <Typography.Text>
                  Bộ lọc
                </Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col md={{ span: 14 }} lg={{ span: 14 }} xl={{ span: 14 }} xs={{ span: 14 }}>
            <div className="heading-search">
              <FormInput placeholder="Nhập từ khoá cần tìm" value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col>
          <Col md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 5 }}>
            <div className="heading-resigter">
              <Button className="btn">
                <Typography.Text>Đã chọn ({countChoose})</Typography.Text>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FilterExamTable;