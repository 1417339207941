// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey-managment-right-control .ant-tooltip-inner {
  color: black; }

.survey-managment-right-control .ant-space-item {
  width: 100%; }

.survey-managment-right-control .ant-checkbox-wrapper {
  width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/pages/survey-managment/survey-construction/detail-survey/component/right-control/detail.scss"],"names":[],"mappings":"AAGA;EAGQ,YAAY,EAAA;;AAHpB;EAOQ,WAAW,EAAA;;AAPnB;EAWQ,WAAW,EAAA","sourcesContent":["@import \"../../../../../../styles/variables\";\n\n\n.survey-managment-right-control {\n\n    .ant-tooltip-inner {\n        color: black;\n    }\n\n    .ant-space-item {\n        width: 100%;\n    }\n\n    .ant-checkbox-wrapper {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
