import React, { ChangeEvent, FunctionComponent, KeyboardEvent, useState } from 'react';
import { Button, Col, Dropdown, Row, Space, Typography } from 'antd';
import { DownOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import FormInput from '../../../../../components/form-input/FormInput';
import './filter-table.scss';

type FilterTableProps = {
  searchValue: string,
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void,
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void,
  handleSubmitSearch: () => void,
  handleOpenFilter: () => void,
  typeQuestion: boolean,
  setTypeQuestion: (value: boolean) => void,
}

const FilterTable: FunctionComponent<FilterTableProps> = ({
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  typeQuestion,
  setTypeQuestion,
}) => {
  return (
    <>
      <div className="filter-table-ministry-moderation">
        <Row gutter={[8, 8]}>
          {!typeQuestion ?
          <>
          <Col md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} xs={{ span: 7 }}>
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text> Bộ lọc </Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col md={{ span: 9 }} lg={{ span: 9 }} xl={{ span: 9 }} xs={{ span: 22 }}>
            <div className="heading-search">
              <FormInput placeholder="Nhập từ khóa cần tìm" value={searchValue}
                onChange={handleChangeInput} onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col>
          </> :
          <>
          <Col>
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text> Bộ lọc </Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          <Col>
            <div className="heading-search">
              <FormInput placeholder="Nhập từ khóa cần tìm" value={searchValue}
                onChange={handleChangeInput} onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col>
          </>}
          
          {!typeQuestion ?
          <>
          <Col md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 22 }}>
            <div className="heading-resigter">
              <Button className="btn btn-primary">
                <Typography.Text>Duyệt nhiều</Typography.Text>
              </Button>
            </div>
          </Col>
          <Col md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }} xs={{ span: 22 }}>
            <div className="heading-history">
              <Button className="btn btn-default" onClick={() =>
              setTypeQuestion(!typeQuestion)}>
                <Typography.Text>Lịch sử kiểm duyệt</Typography.Text>
              </Button>
            </div>
          </Col>
          </> : ''}
        </Row>
      </div>
    </>
  );
};

export default FilterTable;
