import React from 'react';

const FeedbackSample = () => {
  return (
    <div>
      Feedback Sample
    </div>
  );
};

export default FeedbackSample;