import React, { useState } from 'react'
import { Col, Form, Row, Spin } from 'antd'
import CustomCard from '../../../../custom-card/CustomCard';
import './UniversityDetail.scss'
interface UniversityDetailProps {
    loadingDetail: boolean;
    universityDetail: {
        name: string;
        nameEn: string;
        typeSchool: string;
        code: string;
        type: string;
        provinceName: string;
        organizationName: string;
        districtName: string;
        principal: string;
        wardName: string;
        trainingLevel: string;
        address: string;
        addressWebsite: string;
    };
}
function UniversityDetail({ loadingDetail, universityDetail }: UniversityDetailProps) {
    return (
        <section className="wrapper-settings category-management-detail">
            <div className="setting-university wrapper-inside">
                <CustomCard className="header-card" title="Thông tin cơ sở đào tạo">
                    {loadingDetail ?
                        <div className="statistic-course-content statistic-course-loading">
                            <Spin size="large" />
                        </div>
                        :
                        <section>
                            <Form.Item
                                className="setting-item"
                                name="universityName"
                                label="Tên cơ sở đào tạo">
                                <p className="university-content">{universityDetail?.name}</p>
                            </Form.Item>
                            <Form.Item className="setting-item" name="englishName" label="Tên tiếng Anh">
                                <p className="university-content">{universityDetail?.nameEn}</p>
                            </Form.Item>
                            <Form.Item className="setting-item" name="trainingLevel" label="Cấp độ đào tạo">
                                <p className="university-content">{universityDetail?.trainingLevel}</p>
                            </Form.Item>
                            <Form.Item
                                className="setting-item"
                                name="modalType"
                                label="Loại hình">
                                <p className="university-content">{universityDetail?.typeSchool}</p>
                            </Form.Item>
                            <Form.Item
                                className="setting-item"
                                name="universityCode"
                                label="Mã trường">
                                <p className="university-content">{universityDetail?.code}</p>
                            </Form.Item>
                            <Form.Item
                                className="setting-item"
                                name="trainingType"
                                label="Loại trường">
                                <p className="university-content">{universityDetail?.type}</p>
                            </Form.Item>
                            <Form.Item className="setting-item" name="province" label="Tỉnh/TP">
                                <p className="university-content">{universityDetail?.provinceName}</p>
                            </Form.Item>
                            <Form.Item
                                className="setting-item"
                                name="managementAgency"
                                label="Cơ quan quản lý trực tiếp">
                                <p className="university-content">{universityDetail?.organizationName}</p>
                            </Form.Item>
                            <Form.Item
                                className="setting-item"
                                name="district"
                                label="Quận/Huyện">
                                <p className="university-content">{universityDetail?.districtName}</p>
                            </Form.Item>
                            <Form.Item
                                className="setting-item"
                                name="principal"
                                label="Hiệu trưởng">
                                <p className="university-content">{universityDetail?.principal}</p>
                            </Form.Item>
                            <Form.Item
                                className="setting-item"
                                name="ward"
                                label="Phường/Xã/Thị trấn">
                                <p className="university-content">{universityDetail?.wardName}</p>
                            </Form.Item>
                            <Form.Item className="setting-item" name="address" label="Địa chỉ">
                                <p className="university-content">{universityDetail?.address}</p>
                            </Form.Item>
                            <Form.Item className="setting-item" name="website" label="Website">
                                <p className="university-content">{universityDetail?.addressWebsite}</p>
                            </Form.Item>
                        </section>
                    }
                </CustomCard>
            </div>
        </section>
    )
}

export default UniversityDetail
