import { Button, Col, Collapse, Drawer, Form, Radio, Row, Space, TableColumnsType, TableProps, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { isEmpty, size } from 'lodash'
import { getHistoryApproveQuestion } from '../../../../../service/library/question-list'
import { formatOriginTimev3 } from '../../../../../utils/converDate'

type Props = {
  open: boolean
  onClose: () => void
  idDetailData: number | undefined
  dataOptions: any
}

export default function HistoryQuestion(props: Props) {
  const { open, onClose, idDetailData, dataOptions } = props
  const [form] = Form.useForm()
  const { Panel } = Collapse;

  const [listTableDataDetail, setListTableDataDetail] = useState<any>([])
  const [status, setStatus] = useState<any>([])
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [totalRecords, setTotalRecords] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [statusOrder, setStatusOrder] = useState<string>("")
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("")
  const [dataDetail, setDataDetail] = useState<any>({})
  const [arrNewApprove, setArrNewApprove] = useState<any>({})
  const [arrEditApprove, setArrEditApprove] = useState<any>({})

  const getDetailHistoryDetail = async () => {
    const data = {
      questionBankId: idDetailData
    }
    await getHistoryApproveQuestion(data).then((res) => {
      if (res.status === 200) {
        console.log(res)
        const { data } = res.data?.data
        const newApprove = data?.filter((item: any) => item.action === 'Duyệt mới')
        const editApprove = data?.filter((item: any) => item.action === 'Duyệt chỉnh sửa')
        setArrNewApprove(newApprove)
        setArrEditApprove(editApprove)
      }
    })
  }

  const renderEditLayout = !isEmpty(arrEditApprove) && arrEditApprove?.map((item: any) => {
    const arr: any = dataOptions?.dataStatus?.filter((option: any) => Number(option.id) === Number(item?.publishStatus))
    return (
      <Panel
        header="Duyệt chỉnh sửa"
        key="1"
        className="setting-chapter-title">
        <div className='m-5'>
          <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Nội dung gửi duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{item?.action}</p></span>
          <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Ngày gửi duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{formatOriginTimev3(item?.createdDate)}</p></span>
          <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Nội dung duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{`${item.status ? 'Duyệt' : "Không duyệt"} - <${item?.description}>`}</p></span>
          <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Người duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{item?.actionByName}</p></span>
          <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Ngày duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{formatOriginTimev3(item?.approvedDate)}</p></span>
          <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Ngày xuất bản</Typography.Title> <p className='mt-0.5 ml-2'>{formatOriginTimev3(item?.publishDate)}</p></span>
          <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Tình trạng</Typography.Title> <p className='mt-0.5 ml-2'>{arr?.[0]?.name}</p></span>
        </div>
      </Panel>
    )
  })

  const renderNewLayout = !isEmpty(arrNewApprove) && arrNewApprove?.map((item: any) => {
    const arr: any = dataOptions?.dataStatus?.filter((option: any) => Number(option.id) === Number(item?.publishStatus))
    return (
      <Panel
        header="Duyệt mới"
        key="2"
        className="setting-chapter-title">
        <div className='m-5'>
          <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Nội dung gửi duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{item?.action}</p></span>
          <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Ngày gửi duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{formatOriginTimev3(item?.createdDate)}</p></span>
          <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Nội dung duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{`${item.status ? 'Duyệt' : "Không duyệt"} - <${item?.description}>`}</p></span>
          <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Người duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{item?.actionByName}</p></span>
          <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Ngày duyệt</Typography.Title> <p className='mt-0.5 ml-2'>{formatOriginTimev3(item?.approvedDate)}</p></span>
          <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Ngày xuất bản</Typography.Title> <p className='mt-0.5 ml-2'>{formatOriginTimev3(item?.publishDate)}</p></span>
          <span className='flex mt-2'><Typography.Title level={5} className='min-w-[160px]'>Tình trạng</Typography.Title> <p className='mt-0.5 ml-2'>{arr?.[0]?.name}</p></span>
        </div>
      </Panel>
    )
  })

  useEffect(() => {
    if (open) {
      getDetailHistoryDetail();
    }
  }, [open]);

  return (
    <Drawer
      title={"Lịch sử kiểm duyệt"}
      open={open}
      onClose={onClose}
      className='detail-question-bank-drawer'
      width={736}
      bodyStyle={{ padding: "0px" }}
      extra={
        <Space>
          <Button onClick={onClose}>Đóng</Button>
        </Space>
      }
    >
      <Form
        className="detail-question-bank"
        layout={"vertical"}
        form={form}
        name="form-detail-question-bank"
      >
        <div>
          <Collapse defaultActiveKey={["1", "2"]}>
            {renderEditLayout}
            {renderNewLayout}
          </Collapse>
        </div>
      </Form>
    </Drawer>
  )
}
