import { Button, Checkbox, Col, ColorPicker, ColorPickerProps, DatePicker, Divider, Drawer, Form, Input, Modal, Radio, RadioChangeEvent, Row, Select, Space, Switch, Typography, Upload, UploadProps, message, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import FormInput from '../../form-input/FormInput'
import FormRadio from '../../form-radio/FormRadio'
import { CloseOutlined, CopyOutlined, ExclamationCircleOutlined, HeatMapOutlined, InfoCircleOutlined, LoadingOutlined, PicCenterOutlined, PlayCircleOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import FormItemDropdown from '../../form-dropdown/FormItemDropdown'
// import "./index.scss"
import CommonModal from '../../modal/common'
import { useLocation, useNavigate } from 'react-router-dom'
import { FileType } from '../../../utils/uploadImage'
import { createPortalAdministration, getAllUniversity } from '../../../service/portal-administration';
import { isEmpty } from 'lodash';
import { uploadFileImageQuiz } from '../../../service/course-construct'
import { getDetailAdminInterface, putSettingAdminInterface } from '../../../service/admin-interface'
import { getUniversities } from '../../../service/university'
import { optionAccuracyData, optionShareData, optionSyncData, typeOptionManagementData } from '../../../constants/management-data'
import { postSettingManagementData, putSettingManagementData } from '../../../service/management-data'
import moment from 'moment'
import { formatOriginTime, formatOriginTimev2 } from '../../../utils/converDate'
var indexDB = require("idbcache").default;

type Props = {
  open: boolean
  handleClose: () => void
  getListData: () => void
  setDataUpdate: (data: any) => void
  mode: string
  dataUpdate: any
}

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: FileType) => {
  return false
}

export default function SettingManagementDataDrawer(props: Props) {
  const { open, handleClose, getListData, dataUpdate, mode, setDataUpdate } = props
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<boolean>(false);
  const [typeOption, setTypeOption] = useState<string>("");
  const [typeInfoOption, setTypeInfoOption] = useState<any>([]);

  const [filterData, setFilterData] = useState<any>({
    status: null,
    approvedDate: null,
  });

  const validation = {
    nameMNData: {
      required: true,
      message: "Vui lòng nhập tên hệ thống kết nối"
    },
    ipMNData: {
      required: true,
      message: "Vui lòng nhập IP hệ thống kết nối"
    },
    typeMNData: {
      required: true,
      message: "Vui lòng chọn loại kết nối"
    },
    typeInfoMNData: {
      required: true,
      message: "Vui lòng chọn loại thông tin kết nối"
    },
    apiMNData: {
      required: true,
      message: "Vui lòng nhập API key"
    },
    approvedDate: {
      required: true,
      message: "Vui lòng nhập thời hạn sử dụng api key"
    },
    apiSecretMNData: {
      required: true,
      message: "Vui lòng nhập API secret key"
    },
  }

  const getDetailData = () => {
    if (dataUpdate?.id) {
      setTypeOption(dataUpdate.type)
      setStatus(dataUpdate.status)
      setFilterData({ ...filterData, approvedDate: [formatOriginTimev2(dataUpdate.createdDate), formatOriginTimev2(dataUpdate.endDate)] })
      form.setFieldsValue({
        "nameMNData": dataUpdate.name,
        "ipMNData": dataUpdate.ipAddress,
        "typeMNData": dataUpdate.type,
        "typeInfoMNData": dataUpdate.typeInformation,
        "apiMNData": dataUpdate.apiKey,
        "apiSecretMNData": dataUpdate.apiSecretKey,
        "approvedDate": [
          moment(dataUpdate.createdDate), moment(dataUpdate.endDate)
        ]
      })
    }
  }

  useEffect(() => {
    getDetailData()
  }, [dataUpdate, open])

  useEffect(() => {
    if (Number(typeOption) === 1) {
      setTypeInfoOption(optionSyncData)
    } else if (Number(typeOption) === 2) {
      setTypeInfoOption(optionShareData)
    } else {
      setTypeInfoOption(optionAccuracyData)
    }
  }, [typeOption])

  const clearData = () => {
    form.resetFields()
    handleClose()
  }


  return (
    <Form.Provider onFormFinish={async (name, { values, forms }) => {
      if (name === 'form-setting-management-data') {
        const data = {
          name: values.nameMNData,
          type: values.typeMNData,
          typeInformation: values.typeInfoMNData,
          ipAddress: values.ipMNData,
          apiKey: values.apiMNData,
          apiSecretKey: values.apiSecretMNData,
          status: status,
          startDate: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[0]) ? `${filterData.approvedDate?.[0]}T00:00:00.000Z` : null,
          endDate: !isEmpty(filterData.approvedDate) && !isEmpty(filterData.approvedDate?.[1]) ? `${filterData.approvedDate?.[1]}T00:00:00.000Z` : null,
        }
        if (dataUpdate?.id) {
          await putSettingManagementData(dataUpdate?.id, data).then((res) => {
            if (res.status === 200) {
              message.success("Lưu thông tin thành công")
              clearData()
              getListData()
              setDataUpdate({})
            }
          })
        } else {
          await postSettingManagementData(data).then((res) => {
            if (res.status === 200) {
              message.success("Lưu thông tin thành công")
              clearData()
              getListData()
              setDataUpdate({})
            }
          })
        }
        form.resetFields()
      }
    }}>

      <Drawer
        title={`${dataUpdate?.id ? 'Sửa' : 'Thêm mới'} hệ thống kết nối`}
        placement={"right"}
        className='setting-management-data-drawer'
        width={600}
        onClose={clearData}
        open={open}
        extra={
          <Space>
            <Button onClick={clearData} className="btn btn-outlined">
              <CloseOutlined />
              <Typography.Text>
                Hủy
              </Typography.Text>
            </Button>
            <Button className="btn btn-primary" disabled={loading}
              onClick={() => form.submit()
              }>
              <SaveOutlined />
              <Typography.Text>
                Lưu
              </Typography.Text>
            </Button>
          </Space>
        }
      >
        <>
          <Form layout={"vertical"} form={form} name="form-setting-management-data">
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name="nameMNData" rules={[validation.nameMNData]} label="Tên hệ thống kết nối">
                  <FormInput
                    placeholder="Nhập tên hệ thống kết nối"
                  />
                </Form.Item>
              </Col>
              <Col span={24} className='mt-1'>
                <Form.Item name="ipMNData" rules={[validation.ipMNData]} label="IP hệ thống kết nối">
                  <FormInput
                    placeholder="Nhập IP hệ thống kết nối"
                  />
                </Form.Item>
              </Col>
              <Col span={24} className='mt-1'>
                <Form.Item name="typeMNData" rules={[validation.typeMNData]} label="Loại kết nối">
                  <FormItemDropdown placeholder="Loại kết nối" onChange={e => {
                    setTypeOption(e)
                    form.setFieldValue("typeInfoMNData", null)
                    if (e === 1) {
                      setTypeInfoOption(optionSyncData)
                    } else if (e === 2) {
                      setTypeInfoOption(optionShareData)
                    } else {
                      setTypeInfoOption(optionAccuracyData)
                    }
                  }
                  }
                    options={typeOptionManagementData} />
                </Form.Item>
              </Col>
              {
                typeOption ?
                  <Col span={24} className='mt-1'>
                    <Form.Item name="typeInfoMNData" rules={[validation.typeInfoMNData]}
                      label={`Thông tin ${typeOption === "1" ? 'Đồng bộ' : (typeOption === "2" ? 'Chia sẻ' : 'Xác thực')}`}>
                      <FormItemDropdown placeholder={`Chọn thông tin ${typeOption === "1" ? 'Đồng bộ' : (typeOption === "2" ? 'Chia sẻ' : 'Xác thực')}`}
                        options={typeInfoOption} />
                    </Form.Item>
                  </Col>
                  : null
              }
              <Col span={24} className='mt-1'>
                <Form.Item name="apiMNData" rules={[validation.apiMNData]} label="API key">
                  <FormInput
                    placeholder="Nhập API key"
                  />
                </Form.Item>
              </Col>
              <Col span={24} className='mt-1'>
                <Form.Item name="apiSecretMNData" rules={[validation.apiSecretMNData]} label="API secret key">
                  <FormInput
                    placeholder="Nhập API secret key"
                  />
                </Form.Item>
              </Col>
              <Col span={24} className='mt-1'>
                <Form.Item name="approvedDate" label="Thời hạn API key" rules={[validation.approvedDate]}>
                  <RangePicker
                    placeholder={["Từ ngày", "Đến ngày"]}
                    format="YYYY-MM-DD"
                    value={filterData.approvedDate}
                    onChange={(_value, dateString: any) =>
                      setFilterData({ ...filterData, approvedDate: dateString })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={24} className='mt-1'>
                <Form.Item name="statusMNData" label="Trạng thái">
                  <Switch defaultChecked value={status} onChange={setStatus} className='mr-2' />
                  Cho phép
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>
      </Drawer>
    </Form.Provider >
  )
}