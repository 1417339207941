export const routesConfig = {
  home: "/",
  sso: "/sso",
  login: "/login",
  changePassword: "/change-password",
  schoolManagementParticipates: "school-management-participate", //quản lý trường tham gia nền tảng
  listUsers: "list-account-users",
  listTeachers: "list-teachers",
  listMinistryLevels: "list-ministry-levels",
  listStudents: "list-students",
  accountRegistrationReport: "account-registration-report",
  detail: "/info-detail",
  roleManagement: "/role-management",
  account: "/account",
  accountSystem: "account-system",
  profile: "/profile",
  userInfo: "/user-info",
  pageBuilder: "/page-builder",
  pageBuilderView: "/page-builder-view",
  universityPageBuilderView: "/university-page-builder-view",
  universityPageBuilderViewCourseList:
    "/university-page-builder-view-course-list",
  universityPageBuilderViewCourseListPage:
    "/university-page-builder-view-course-list-page",
  courseInfo: "/course-info", // Thông tin khoá học
  searchCourseInfo: "/course-info/search", // Thông tin khoá học
  courseTopics: "/course-topics", // Các chủ đề
  statisticsCourses: "/statistics/courses", // Thống kê khoá học
  courseEnrollment: "/course-enrollment",
  learningCourseStructure: "/learning-course-structure", //xem hoc lieu
  adminInterface: "admin-interface", //xem giao dien quan tri
  detailAdminInterface: "detail-admin-interface", //xem giao dien quan tri
  certificate: "/certificate", // xem chung chi, chung nhan
  certificatePageViewer: "/certificate-page-viewer",
  accountConfiguration: "account-configuration",

  //quản lí kết nối, chia sẻ dữ liệu
  managementData: "management-data",
  settingManagementData: "setting-management-data",
  historyManagementData: "history-management-data",

  // Quản lý xấy dựng khoá học
  courseConstructionManagement: "course-construction-management",

  // Phân công xây dựng khoá học
  assignCourseConstruction: "assign-course-construction",
  detailAssignCourseConstruction: "content-construction-detail",
  detailAssignedCourseConstruction: "content-assigned-construction-detail",
  addLesson: "add-lesson",
  historyCourseConstruction: "history-course-construction",
  detailAssignment: "detail-assignment",
  registrationClass: "registration-class",

  // Quản lý danh mục
  categoryManagement: "category-management",
  sharedCategoriesManagement: "shared-categories-management",
  sharedCategoriesManagementDetail: "shared-categories-management-detail",
  portfolioValueManagement: "portfolio-value-management",
  portfolioValueManagementDetail: "portfolio-value-management-detail",
  universityManagement: "university-management",
  universityManagementDetail: "university-management-detail",
  lecturerManagement: "lecturer-management",
  lecturerManagementDetail: "lecturer-management-detail",
  studentManagement: "student-management",
  studentManagementDetail: "student-management-detail",
  ministryUsersManagement: "ministry-users-management",
  ministryUsersManagementDetail: "ministry-users-management-detail",

  // Xây dựng nội dung
  courseConstruction: "course-construction",
  contentConstruction: "content-construction",
  contentListCourse: "content-list-course",
  detailContentCourse: "detail-content-course",
  contentCourseDetail: "content-course-detail",
  questionCourseBuilder: "question-course-builder",
  surveyPlan: "survey-plan",
  surveyPlanDetail: "survey-plan-detail",
  previewPlan: "preview-plan",
  addSurvey: "add-survey",

  //Thư viện
  courseLibrary: "course-library",
  personalExamRepository: 'personal-exam-repository',
  bankExam: "bank-exam",
  bankExamList: "bank-exam-list",
  bankQuestion: "bank-question",
  feedbackSample: 'feedback-sample',
  listExamRepository: 'list-exam',
  previewExam: 'preview-exam',
  listArchiveRepository: 'list-archive',
  listQuestionRepository: 'list-question',
  createQuestionRepository:'create-question',
  ministryContentModeration:'ministry-content-moderation',
  universityContentModeration:'university-content-moderation',
  contentModerationHistory: 'history',

  // Duyệt khoá học
  courseBrowse: "course-browse",
  contentBrowse: "content-browse",
  courseEvaluation: "course-evaluation",
  courseApproval: "course-approval",
  publishCourse: "publish-course",
  detailCourseBrowse: "detail-course-browse",
  detailCourseEvaluation: "detail-course-evaluation",
  detailCourseApproval: "detail-course-approval",
  detailPublishCourse: "detail-course-publish",
  appraisedHistory: "appraised-history",

  // Xem trước khóa học
  coursePreview: "course-preview",

  //Tổng hợp kết quá khảo sát
  surveyResult: "survey-result",
  surverList: "surver-list",
  detailSurvey: "detail-survey",
  //Kế hoạch khóa học
  coursePlan: "course-plan",
  assignPlan: "asign-plan",
  registerCoursePlan: "register-course-plan",
  organizeCoursePlan: "organize-course-plan",
  specializedGroupPlan: "specialized-group",
  specializedGroupDetailPlan: "specialized-group-detail",
  specializedGroupList: "specialized-group-list",
  browseSubscriptionPlans: "browse-subscription-plans",
  planningAssignment: "planning-assignment",
  planningApproval: "planning-approval",
  organizeSpecializedGroup: "organize-specialized-group",
  organizeAppraisalBoard: "organize-appraisal-board",
  submitApprovalPlan: "submit-approval-plan",
  historySubmitApprovalPlan: "history-operation-submit-approval-plan",
  historyApprovalSubmitApprovalPlan: "history-approval-submit-approval-plan",
  submitAppraisalPlan: "submit-appraisal-plan",
  historySubmitAppraisalPlan: "history-operation-submit-appraisal-plan",
  historyApprovalSubmitAppraisalPlan: "history-approval-submit-appraisal-plan",
  approval: "approval",
  appraisal: "appraisal",
  planningAssignmentHistory: "planning-assignment-history",
  assignmentHistory: "assignment-history",

  // Khảo sát sinh viên
  surveyStudent: "survey-student",
  trainingNeed: "training-need",
  surveyRespond: "survey-respond",

  //Lập tổ chuyên môn
  detailSpecializedGroup: "detail-specialized-group",
  createSpecializedGroup: "create-specialized-group",
  editSpecializedGroup: "edit-specialized-group",
  specializedGroupSearch: "specialized-group-search",
  specializedGroupMemberList: "specialized-group-member-list",
  organizeAppraisalBoardMemberList: "organize-appraisal-board-member-list",
  organizationHistoryList: "organization-history-list",
  detailInfoMemberSpecializedGroup: "detail-info-member-specialized-group",
  // Lập hội đồng thẩm định
  detailOrganizeAppraisalBoard: "detail-organize-appraisal-board",
  detailInfoMemberAppraisalBoard: "detail-info-member-appraisal-board",

  //Quản trị cổng thông tin
  portalAdministration: "portal-administration",
  detailPortalAdministration: "detail-portal-administration",
  settingIntroCourse: "setting-intro-course",
  previewSettingIntroCourse: "preview-setting-intro-course",
  //gc
  moocActionHistory: "mooc-action-history-list",
  moocActionHistoryDetail: "mooc-action-history-detail",
  historyMoocActionHistory: "mooc-action-history-history",
  moocActionHistoryTypes: "mooc-action-history-types-list",
  moocActionHistoryTypesDetail: "mooc-action-history-types-detail",
  historyMoocActionHistoryTypes: "mooc-action-history-types-history",
  moocApproval: "mooc-approval-list",
  approvalDetail: "approval-detail",
  moocApprovalDetail: "mooc-approval-detail",
  historyMoocApproval: "mooc-approval-history",
  moocApprovalConfiguration: "mooc-approval-configuration-list",
  moocApprovalConfigurationDetail: "mooc-approval-configuration-detail",
  historyMoocApprovalConfiguration: "mooc-approval-configuration-history",
  moocConstructionPlanCollaborator: "mooc-construction-plan-collaborator-list",
  moocConstructionPlanCollaboratorDetail:
    "mooc-construction-plan-collaborator-detail",
  historyMoocConstructionPlanCollaborator:
    "mooc-construction-plan-collaborator-history",
  moocConstructionPlanIndustry: "mooc-construction-plan-industry-list",
  moocConstructionPlanIndustryDetail: "mooc-construction-plan-industry-detail",
  historyMoocConstructionPlanIndustry:
    "mooc-construction-plan-industry-history",
  moocConstructionPlanIndustryGroup:
    "mooc-construction-plan-industry-group-list",
  moocConstructionPlanIndustryGroupDetail:
    "mooc-construction-plan-industry-group-detail",
  historyMoocConstructionPlanIndustryGroup:
    "mooc-construction-plan-industry-group-history",
  moocCourseConstructionPlan: "mooc-course-construction-plan-list",
  planningCourseConstruction: "planning-course-construction-list",
  planningCourseConstructionDetail: "planning-course-construction-detail",
  moocCourseConstructionPlanDetail: "mooc-course-construction-plan-detail",
  historyMoocCourseConstructionPlan: "mooc-course-construction-plan-history",
  moocCourseDescriptionDocument: "mooc-course-description-document-list",
  moocCourseDescriptionDocumentDetail:
    "mooc-course-description-document-detail",
  historyMoocCourseDescriptionDocument:
    "mooc-course-description-document-history",
  moocCoursePlanAssignment: "mooc-course-plan-assignment-list",
  moocCoursePlanAssignmentDetail: "mooc-course-plan-assignment-detail",
  historyMoocCoursePlanAssignment: "mooc-course-plan-assignment-history",
  moocCourseStructure: "mooc-course-structure-list",
  moocCourseStructureDetail: "mooc-course-structure-detail",
  historyMoocCourseStructure: "mooc-course-structure-history",
  moocOrganization: "mooc-organization-list",
  moocOrganizationDetail: "mooc-organization-detail",
  historyMoocOrganization: "mooc-organization-history",
  moocOrganizationGroup: "mooc-organization-group-list",
  moocOrganizationGroupDetail: "mooc-organization-group-detail",
  historyMoocOrganizationGroup: "mooc-organization-group-history",
  moocOrganizationMember: "mooc-organization-member-list",
  moocOrganizationMemberDetail: "mooc-organization-member-detail",
  historyMoocOrganizationMember: "mooc-organization-member-history",
  moocSurvey: "mooc-survey-list",
  moocSurveyDetail: "mooc-survey-detail",
  historyMoocSurvey: "mooc-survey-history",
  moocSurveyCollaborator: "mooc-survey-collaborator-list",
  moocSurveyCollaboratorDetail: "mooc-survey-collaborator-detail",
  historyMoocSurveyCollaborator: "mooc-survey-collaborator-history",
  moocSurveyIndustry: "mooc-survey-industry-list",
  moocSurveyIndustryDetail: "mooc-survey-industry-detail",
  historyMoocSurveyIndustry: "mooc-survey-industry-history",
  moocSurveyIndustryGroup: "mooc-survey-industry-group-list",
  moocSurveyIndustryGroupDetail: "mooc-survey-industry-group-detail",
  historyMoocSurveyIndustryGroup: "mooc-survey-industry-group-history",
  moocSurveyObjectsFilter: "mooc-survey-objects-filter-list",
  moocSurveyObjectsFilterDetail: "mooc-survey-objects-filter-detail",
  historyMoocSurveyObjectsFilter: "mooc-survey-objects-filter-history",
  moocSurveyParticipation: "mooc-survey-participation-list",
  moocSurveyParticipationDetail: "mooc-survey-participation-detail",
  historyMoocSurveyParticipation: "mooc-survey-participation-history",
  moocSurveyQuestion: "mooc-survey-question-list",
  moocSurveyQuestionDetail: "mooc-survey-question-detail",
  historyMoocSurveyQuestion: "mooc-survey-question-history",
  moocSurveyQuestionAnswer: "mooc-survey-question-answer-list",
  moocSurveyQuestionAnswerDetail: "mooc-survey-question-answer-detail",
  historyMoocSurveyQuestionAnswer: "mooc-survey-question-answer-history",
  moocSurveyResult: "mooc-survey-result-list",
  moocSurveyResultDetail: "mooc-survey-result-detail",
  historyMoocSurveyResult: "mooc-survey-result-history",
  moocSurveySection: "mooc-survey-section-list",
  moocSurveySectionDetail: "mooc-survey-section-detail",
  historyMoocSurveySection: "mooc-survey-section-history",
  moocApprovalCourseHistory: "mooc-approval-course-history",
  planningApprovalDetail: "planning-approval-detail",
  approvalCourseRegister: "approval-course-register",
  ApproveCouresConsrtuctionHistory: "approve-coures-consrtuction-history",

  //Quản lý khóa học
  settingIdentify: "setting-identify",
  settingRemindCheckIn: "setting-remind-checkin",
  settingRemindCheckInDetail: "setting-remind-checkin-detail",
  roleCheckIn: "role-checkin",
  course: "course",
  reportCheckIn: "report-checkin",
  reportCheckInDetail: "report-checkin-detail",
  reportQualityEvaluation: "report-quality-evaluation",
  reportCourseDocument: "report-course-document",

  //Cài đặt giám sát thi cử
  settingSupervisionDetail: "setting-supervision-detail",
  automaticMarkDetail: "automatic-mark-detail",
  autoMarkActionDetail: "auto-mark-action-detail",
  autoMarkPermissionDetail: "auto-mark-permission-detail",
  examStudentList: "exam-student-list",
  managementListScore: "management-list-score",
  managementScoreDetail: "management-score-detail",
  rateAutoScore: "rate-auto-score",
  managementScoreAuto: "management-score-auto",

  //Kiểm soát chất lượng tiết học video
  controlQualityLesson: "control-quality-lesson",
  controlQualityVideo: "control-quality-video",

  //Hệ thống gợi ý khoá học
  learningDocumentationSystem: "learning-documentation-system",
  infoCourseDetailSystem: "info-course-detail",
  synthesisReportSystem: "synthesis-report-system",
  listStudentSystem: "list-student-system",
  favoriteStudentSystem: "favorite-student-system",
  infoSuggestSystem: "info-suggest-system",

  //Mô hình dự đoán
  managementGroupContent: "management-group-content",
  settingPrediction: "setting-prediction",
  settingDetailModel: "setting-detail-model",
  viewSettingDetailModel: "view-setting-detail-model",
  infoCourseListModel: "info-course-list-model",
  profileStudentModel: "profile-student-model",
  studentBeforeJoin: "student-before-join-model",
  studentAfterJoin: "student-after-join-model",
  studentJoin: "student-join-model",

  // student page
  curriculumPrograms: "/curriculum-programs", //tim kiem chuong trinh hoc

  configuration: "configuration",
  approvalConfiguration: "approval-configuration",

  // blog & forum
  blogAndForum: "blog-and-forum",
  blogContent: "blog-content",
  designThemeBlogContent: "design-theme-blog-content",
  manageContentForum: "manage-content-forum",
  blogPreview: "blog-preview",

  // chấm điểm khoá học đào tạo
  scoringListCourse: "/scoring-course-list",
  exercises: "exercise-list",
  scoring: "scoring",
  scoringManagement: "scoring-management",

  // thông báo
  notifications: "/notifications",
  myNotification: "/my-notifications",

  // blog
  detailContentBlog: "detail-content-blog",

  //Cài đặt nâng cao
  managementAutoScoring: "management-auto-scoring",
  controlQualityCourse: "control-quality-course",
  roleCourse: "role-course-detail",
  managementRoleCourse: "management-role-course",
  logsAudit: "log-audit",

  //Cài đặt cấu trúc bài giảng
  settingStructureCourse: "setting-structure-course",
  //Quản lý khoá học
  courseManagement: "/course-management",
  courseList: "course-list",
  courseStudentList: "course-student-list",
  transcriptList: "transcript-list",

  //Quản lý thống kê
  statisticalReport: "statistical-report",
  statisticalReportForInstructors: "/statistical-report-for-instructors",
  statisticalReportForMinistry: "/statistical-report-for-ministry",
  statisticalReportForUniversity: "/statistical-report-for-university",

  //Xác nhận ghi danh
  enrollmentConfirmation: "/transactions",
  trainingLocations: '/training-locations'
};
