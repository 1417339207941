import { Col, Empty, Row, Select, Spin } from "antd";
import AreaStackedChart from "../../../../../../components/types-of-charts/AreaStackedChart";
import BarChart from "../../../../../../components/types-of-charts/BarChart";
import LineChart from "../../../../../../components/types-of-charts/LineChart";
import { staticReportStore } from "../../../../../../stores/stores";
import {
  BarStackedChartProps,
  LineChartProps,
  PieChart,
} from "../../../../../../types/static-report";
import { chartContentTypes } from "../../../../../../utils/staticReport";
import StatisticalReportCard from "../../StatisticalReportCard";
import useChartExport from "../useChartExport";
import React, { useEffect } from "react";

type Props = {
  loading: Record<string, boolean>;
  staticDownloadUnit: LineChartProps[];
  staticViewUnit: LineChartProps[];
  staticShareUnit: LineChartProps[];
  searchHistory: BarStackedChartProps[];
  searchKeywordCount: PieChart[];
};

const UserBehaviorChartTab = React.memo((props: Props) => {
  const {
    loading,
    staticDownloadUnit,
    staticViewUnit,
    staticShareUnit,
    searchHistory,
    searchKeywordCount,
  } = props;
  const {
    tab,
    setHandleExportAllImagesAsZip,
    exportType,
    filterValues,
    setFilterValues,
  } = staticReportStore();

  const { createChartRef, handleExportImage, handleExportAllImagesAsZip } =
    useChartExport();
  useEffect(() => {
    setHandleExportAllImagesAsZip(() => handleExportAllImagesAsZip(tab));
  }, [tab, exportType]);

  const handleParamsChange = (
    value: string,
    courseTypeKey: string,
    moduleGroupKey: string
  ) => {
    let courseStructureType = "";
    let moduleGroup = null;

    switch (value) {
      case "5":
        courseStructureType = value;
        moduleGroup = null;
        break;
      case "6":
        courseStructureType = value;
        moduleGroup = null;
        break;
      case "1":
      case "2":
      case "3":
      case "4":
        courseStructureType = value;
        moduleGroup = Number(value);
        break;
      default:
        break;
    }

    setFilterValues({
      [courseTypeKey]: courseStructureType,
      [moduleGroupKey]: moduleGroup,
    });
  };

  return (
    <div>
      <>
        <div className="chart-title">Thống kê số lượng tìm kiếm</div>
        <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Số lượng tìm kiếm theo từ khoá"
              content={
                <Spin spinning={loading.searchKeywordCount}>
                  {searchKeywordCount.length > 0 ? (
                    <BarChart
                      data={searchKeywordCount}
                      colors={["#69C0FF"]}
                      ref={createChartRef(
                        tab,
                        "Số lượng tìm kiếm theo từ khoá"
                      )}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              moreOption={
                <div>
                  <Select
                    style={{ width: 180 }}
                    value={filterValues?.searchKeywordCourseStructureType}
                    onChange={(value) =>
                      handleParamsChange(
                        value,
                        "searchKeywordCourseStructureType",
                        "searchKeywordModuleGroup"
                      )
                    }
                    options={chartContentTypes}
                  />
                </div>
              }
              exportOptions={
                searchKeywordCount.length > 0
                  ? [
                      {
                        label: "Hình ảnh (PNG)",
                        action: () =>
                          handleExportImage(
                            tab,
                            "Số lượng tìm kiếm theo từ khoá",
                            "image/png"
                          ),
                      },
                      {
                        label: "PDF",
                        action: () =>
                          handleExportImage(
                            tab,
                            "Số lượng tìm kiếm theo từ khoá",
                            "application/pdf"
                          ),
                      },
                    ]
                  : []
              }
            />
          </Col>
          <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Thống kê số lượng tìm kiếm toàn hệ thống theo thời gian"
              content={
                <Spin spinning={loading.searchHistory}>
                  {searchHistory.length > 0 ? (
                    <AreaStackedChart
                      data={searchHistory}
                      colors={[
                        "#69C0FF",
                        "#5CDBD3",
                        "#FFC069",
                        "#FF7875",
                        "#B37FEB",
                      ]}
                      yAxisTitle="Số lượng tìm kiếm"
                      ref={createChartRef(
                        tab,
                        "Thống kê số lượng tìm kiếm toàn hệ thống theo thời gian"
                      )}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              exportOptions={
                searchHistory.length > 0
                  ? [
                      {
                        label: "Hình ảnh (PNG)",
                        action: () =>
                          handleExportImage(
                            tab,
                            "Thống kê số lượng tìm kiếm toàn hệ thống theo thời gian",
                            "image/png"
                          ),
                      },
                      {
                        label: "PDF",
                        action: () =>
                          handleExportImage(
                            tab,
                            "Thống kê số lượng tìm kiếm toàn hệ thống theo thời gian",
                            "application/pdf"
                          ),
                      },
                    ]
                  : []
              }
            />
          </Col>
        </Row>
      </>

      <>
        <div className="chart-title">Thống kê số lượng tải về</div>
        <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
          <Col sm={{ span: 8 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Thống kê lượng tải về theo thời gian"
              content={
                <Spin spinning={loading.staticDownloadUnit}>
                  {staticDownloadUnit.length > 0 ? (
                    <LineChart
                      data={staticDownloadUnit}
                      lineColor="#FF7875"
                      lineName="Lượt tải về"
                      ref={createChartRef(
                        tab,
                        "Thống kê lượng tải về theo thời gian"
                      )}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              moreOption={
                <div>
                  <Select
                    value={filterValues?.downloadCourseStructureType}
                    style={{ width: 180 }}
                    onChange={(value) =>
                      handleParamsChange(
                        value,

                        "downloadCourseStructureType",
                        "downloadModuleGroup"
                      )
                    }
                    options={chartContentTypes}
                  />
                </div>
              }
              exportOptions={
                staticDownloadUnit.length > 0
                  ? [
                      {
                        label: "Hình ảnh (PNG)",
                        action: () =>
                          handleExportImage(
                            tab,
                            "Thống kê lượng tải về theo thời gian",
                            "image/png"
                          ),
                      },
                      {
                        label: "PDF",
                        action: () =>
                          handleExportImage(
                            tab,
                            "Thống kê lượng tải về theo thời gian",
                            "application/pdf"
                          ),
                      },
                    ]
                  : []
              }
            />
          </Col>
          <Col sm={{ span: 8 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Thống kê lượng xem theo thời gian"
              content={
                <Spin spinning={loading.staticViewUnit}>
                  {staticViewUnit.length > 0 ? (
                    <LineChart
                      data={staticViewUnit}
                      lineColor="#69C0FF"
                      lineName="Lượt xem"
                      ref={createChartRef(
                        tab,
                        "Thống kê lượng xem theo thời gian"
                      )}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              moreOption={
                <div>
                  <Select
                    value={filterValues?.viewCourseStructureType}
                    style={{ width: 180 }}
                    onChange={(value) =>
                      handleParamsChange(
                        value,

                        "viewCourseStructureType",
                        "viewModuleGroup"
                      )
                    }
                    options={chartContentTypes}
                  />
                </div>
              }
              exportOptions={
                staticViewUnit.length > 0
                  ? [
                      {
                        label: "Hình ảnh (PNG)",
                        action: () =>
                          handleExportImage(
                            tab,
                            "Thống kê lượng xem theo thời gian",
                            "image/png"
                          ),
                      },
                      {
                        label: "PDF",
                        action: () =>
                          handleExportImage(
                            tab,
                            "Thống kê lượng xem theo thời gian",
                            "application/pdf"
                          ),
                      },
                    ]
                  : []
              }
            />
          </Col>
          <Col sm={{ span: 8 }} xs={{ span: 24 }}>
            <StatisticalReportCard
              chartName="Thống kê lượng chia sẻ theo thời gian"
              content={
                <Spin spinning={loading.staticShareUnit}>
                  {staticShareUnit.length > 0 ? (
                    <LineChart
                      data={staticShareUnit}
                      lineColor="#5CDBD3"
                      lineName="Lượt chia sẻ"
                      ref={createChartRef(
                        tab,
                        "Thống kê lượng chia sẻ theo thời gian"
                      )}
                    />
                  ) : (
                    <Empty description="Không có dữ liệu" />
                  )}
                </Spin>
              }
              moreOption={
                <div>
                  <Select
                    value={filterValues?.shareCourseStructureType}
                    style={{ width: 180 }}
                    onChange={(value) =>
                      handleParamsChange(
                        value,

                        "shareCourseStructureType",
                        "shareModuleGroup"
                      )
                    }
                    options={chartContentTypes}
                  />
                </div>
              }
              exportOptions={
                staticShareUnit.length > 0
                  ? [
                      {
                        label: "Hình ảnh (PNG)",
                        action: () =>
                          handleExportImage(
                            tab,
                            "Thống kê lượng chia sẻ theo thời gian",
                            "image/png"
                          ),
                      },
                      {
                        label: "PDF",
                        action: () =>
                          handleExportImage(
                            tab,
                            "Thống kê lượng chia sẻ theo thời gian",
                            "application/pdf"
                          ),
                      },
                    ]
                  : []
              }
            />
          </Col>
        </Row>
      </>
    </div>
  );
});

export default UserBehaviorChartTab;
