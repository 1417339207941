import { Col, Empty, Row, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import AreaStackedChart from "../../../../../../components/types-of-charts/AreaStackedChart";
import LineChart from "../../../../../../components/types-of-charts/LineChart";
import BarChart from "../../../../../../components/types-of-charts/BarChart";
import StatisticalReportCard from "../../StatisticalReportCard";
import { BarStackedChartProps, LineChartProps, PieChart } from "../../../../../../types/static-report";
import useChartExport from "../../../../for-instructors/components/chart/useChartExport";
import { staticReportStore } from "../../../../../../stores/stores";

type Props = {
  loading: Record<string, boolean>,
  searchKeywordUniversity: PieChart[],
  searchHistoryUniversity: BarStackedChartProps[],
  unitDownloadResource: LineChartProps[],
  unitPreviewResource: LineChartProps[],
  unitShareResource: LineChartProps[],
  setActionModuleMapping: (value: any) => void,
};

const UserBehaviorChartTab = (props: Props) => {
  const {
    loading,
    searchKeywordUniversity,
    searchHistoryUniversity,
    unitDownloadResource,
    unitPreviewResource,
    unitShareResource,
    setActionModuleMapping,
  } = props;

  // Hook for managing chart exports
  const { createChartRef, handleExportImage, handleExportAllImagesAsZip } =
    useChartExport();

  const { tab, setHandleExportAllImagesAsZip, exportType } =
    staticReportStore();

  useEffect(() => {
    setHandleExportAllImagesAsZip(() => handleExportAllImagesAsZip(tab));
  }, [tab, exportType]);

  return (
    <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
      <Col sm={{ span: 12 }} xs={{ span: 24 }}>
        <StatisticalReportCard
          chartName="Số lượng tìm kiếm theo từ khoá"
          content={
            <Spin spinning={loading.searchKeywordUniversity}>
              {searchKeywordUniversity.length > 0 ? (
                <BarChart
                  data={searchKeywordUniversity}
                  colors={["#69C0FF"]}
                  xAxisTitle="Từ khoá"
                  ref={createChartRef(tab, "Số lượng tìm kiếm theo từ khoá")}
                />
              ) : (
                <Empty description="Không có dữ liệu" />
              )}
            </Spin>
          }
          exportOptions={[
            {
              label: "Hình ảnh (PNG)",
              action: () =>
                handleExportImage(tab, "Số lượng tìm kiếm theo từ khoá", "image/png"),
            },
            {
              label: "PDF",
              action: () =>
                handleExportImage(
                  tab,
                  "Số lượng tìm kiếm theo từ khoá",
                  "application/pdf"
                ),
            },
          ]}
        />
      </Col>
      <Col sm={{ span: 12 }} xs={{ span: 24 }}>
        <StatisticalReportCard
          chartName="Số lượt tìm kiếm tài nguyên"
          content={
            <Spin spinning={loading.searchHistoryUniversity}>
              {searchHistoryUniversity.length > 0 ? (
                <AreaStackedChart
                  data={searchHistoryUniversity}
                  colors={["#8FD0FF", " #85E4DE", "#FFD08F", "#FF9A98", "#C69FF0"]}
                  yAxisTitle="Số lượng phản hồi"
                  ref={createChartRef(tab, "Số lượt tìm kiếm tài nguyên")}
                />
              ) : (
                <Empty description="Không có dữ liệu" />
              )}
            </Spin>
          }
          exportOptions={[
            {
              label: "Hình ảnh (PNG)",
              action: () =>
                handleExportImage(tab, "Số lượt tìm kiếm tài nguyên", "image/png"),
            },
            {
              label: "PDF",
              action: () =>
                handleExportImage(
                  tab,
                  "Số lượt tìm kiếm tài nguyên",
                  "application/pdf"
                ),
            },
          ]}
        />
      </Col>

      <Col sm={{ span: 8 }} xs={{ span: 24 }}>
        <StatisticalReportCard
          chartName="Số lượt tải về tài nguyên"
          content={
            <Spin spinning={loading.unitDownloadResource}>
              {unitDownloadResource.length > 0 ? (
                <LineChart
                  yAxisTitle="Số lượng"
                  data={unitDownloadResource}
                  lineColor="#FF7875"
                  lineName="Lượt tải về"
                  ref={createChartRef(tab, "Số lượt tải về tài nguyên")}
                />
              ) : (
                <Empty description="Không có dữ liệu" />
              )}
            </Spin>
          }
          exportOptions={[
            {
              label: "Hình ảnh (PNG)",
              action: () =>
                handleExportImage(tab, "Số lượt tải về tài nguyên", "image/png"),
            },
            {
              label: "PDF",
              action: () =>
                handleExportImage(
                  tab,
                  "Số lượt tải về tài nguyên",
                  "application/pdf"
                ),
            },
          ]}
          moreOption={
            <Select
              className="mr-2"
              defaultValue={3}
              style={{ minWidth: 160 }}
              onSelect={(value) => {
                let type;
                switch (value) {
                  case 1:
                    type = "unit";
                    break;
                  case 2:
                    type = "unit";
                    break;
                  case 3:
                    type = "unit";
                    break;
                  case 4:
                    type = "sequence";
                    break;
                  case 5:
                    type = "course";
                    break;
                  default:
                    type = "course";
                }
                setActionModuleMapping({
                  courseStructureType: type,
                  actionType: 3,
                  moduleGroup: value
                })
              }}
              options={[
                { value: 3, label: 'Bài kiểm tra' },
                { value: 2, label: 'Tài liệu tham khảo' },
                { value: 1, label: 'Đa phương tiện' },
                { value: 4, label: 'Bài giảng' },
                { value: 5, label: 'Khoá học' },
              ]}
            />
          }
        />
      </Col>
      <Col sm={{ span: 8 }} xs={{ span: 24 }}>
        <StatisticalReportCard
          chartName="Số lượt xem tài nguyên"
          content={
            <Spin spinning={loading.unitPreviewResource}>
              {unitPreviewResource.length > 0 ? (
                <LineChart
                  yAxisTitle="Số lượng"
                  data={unitPreviewResource}
                  lineColor="#69C0FF"
                  lineName="Lượt xem"
                  ref={createChartRef(tab, "Số lượt xem tài nguyên")}
                />
              ) : (
                <Empty description="Không có dữ liệu" />
              )}
            </Spin>
          }
          exportOptions={[
            {
              label: "Hình ảnh (PNG)",
              action: () =>
                handleExportImage(tab, "Số lượt xem tài nguyên", "image/png"),
            },
            {
              label: "PDF",
              action: () =>
                handleExportImage(
                  tab,
                  "Số lượt xem tài nguyên",
                  "application/pdf"
                ),
            },
          ]}
          moreOption={
            <Select
              className="mr-2"
              defaultValue={3}
              style={{ minWidth: 160 }}
              onSelect={(value) => {
                let type;
                switch (value) {
                  case 1:
                    type = "unit";
                    break;
                  case 2:
                    type = "unit";
                    break;
                  case 3:
                    type = "unit";
                    break;
                  case 4:
                    type = "sequence";
                    break;
                  case 5:
                    type = "course";
                    break;
                  default:
                    type = "course";
                }
                setActionModuleMapping({
                  courseStructureType: type,
                  actionType: 1,
                  moduleGroup: value
                })
              }}
              options={[
                { value: 3, label: 'Bài kiểm tra' },
                { value: 2, label: 'Tài liệu tham khảo' },
                { value: 1, label: 'Đa phương tiện' },
                { value: 4, label: 'Bài giảng' },
                { value: 5, label: 'Khoá học' },
              ]}
            />
          }
        />
      </Col>
      <Col sm={{ span: 8 }} xs={{ span: 24 }}>
        <StatisticalReportCard
          chartName="Số lượt chia sẻ tài nguyên"
          content={
            <Spin spinning={loading.unitShareResource}>
              {unitShareResource.length > 0 ? (
                <LineChart
                  yAxisTitle="Số lượng"
                  data={unitShareResource}
                  lineColor="#5CDBD3"
                  lineName="Lượt chia sẻ"
                  ref={createChartRef(tab, "Số lượt chia sẻ tài nguyên")}
                />
              ) : (
                <Empty description="Không có dữ liệu" />
              )}
            </Spin>
          }
          exportOptions={[
            {
              label: "Hình ảnh (PNG)",
              action: () =>
                handleExportImage(tab, "Số lượt chia sẻ tài nguyên", "image/png"),
            },
            {
              label: "PDF",
              action: () =>
                handleExportImage(
                  tab,
                  "Số lượt chia sẻ tài nguyên",
                  "application/pdf"
                ),
            },
          ]}
          moreOption={
            <Select
              className="mr-2"
              defaultValue={3}
              style={{ minWidth: 160 }}
              onSelect={(value) => {
                let type;
                switch (value) {
                  case 1:
                    type = "unit";
                    break;
                  case 2:
                    type = "unit";
                    break;
                  case 3:
                    type = "unit";
                    break;
                  case 4:
                    type = "sequence";
                    break;
                  case 5:
                    type = "course";
                    break;
                  default:
                    type = "course";
                }
                setActionModuleMapping({
                  courseStructureType: type,
                  actionType: 2,
                  moduleGroup: value
                })
              }}
              options={[
                { value: 3, label: 'Bài kiểm tra' },
                { value: 2, label: 'Tài liệu tham khảo' },
                { value: 1, label: 'Đa phương tiện' },
                { value: 4, label: 'Bài giảng' },
                { value: 5, label: 'Khoá học' },
              ]}
            />
          }
        />
      </Col>
    </Row>
  );
};

export default UserBehaviorChartTab;
