// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-detail-exam {
  padding: 0; }
  .card-detail-exam .collapse-exam-detail .ant-collapse-header {
    background-color: #fafafa; }
  .card-detail-exam .collapse-exam-detail .collapse-exam-detail--header {
    padding: 0; }
  .card-detail-exam .collapse-exam-detail .ant-collapse-content {
    padding: 0 48px; }
  .card-detail-exam .collapse-exam-detail .list-item {
    padding: 8px 0; }
`, "",{"version":3,"sources":["webpack://./src/components/course-library/personal-exam-repository/list-exam/history-censorship/index.scss"],"names":[],"mappings":"AAAA;EACE,UAAU,EAAA;EADZ;IAKM,yBAAyB,EAAA;EAL/B;IAUM,UAAU,EAAA;EAVhB;IAcM,eAAe,EAAA;EAdrB;IAkBM,cAAc,EAAA","sourcesContent":[".card-detail-exam {\n  padding: 0;\n\n  .collapse-exam-detail {\n    .ant-collapse-header {\n      background-color: #fafafa;\n\n    }\n\n    .collapse-exam-detail--header {\n      padding: 0;\n    }\n\n    .ant-collapse-content {\n      padding: 0 48px;\n    }\n\n    .list-item {\n      padding: 8px 0;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
