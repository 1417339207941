import React, { useState } from 'react'
import DrawerCustom from '../../../../drawer/Drawer'
import { Button, DatePicker, DatePickerProps, Form, Input, Radio, RadioChangeEvent, Space, TimePicker, TimePickerProps, Typography } from 'antd'
import { CloseOutlined, SaveOutlined } from '@ant-design/icons'
import './submitReview.scss'

type SubmitReviewProps = {
    isOpened: boolean,
    closeForm: () => void,
    status: string
    onSubmit: (access: string, contentEdit?: string, publishDate?: string | null) => void;
    onFinish: (values: any) => void;
}

function SubmitReview({ isOpened, closeForm, status, onSubmit, onFinish }: SubmitReviewProps) {
    const [form] = Form.useForm();
    const [access, setAccess] = useState<string>(''); 
    const [scheduleType, setScheduleType] = useState(1); 
    const [publishDate, setPublishDate] = useState<string | null>(null);

    const onChangeRadio = (e: RadioChangeEvent) => {
        setAccess(e.target.value);
    };

    const onChangeRadio2 = (e: RadioChangeEvent) => {
        setScheduleType(e.target.value);
    };

    const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
        if (typeof dateString === 'string') {
            setPublishDate(dateString); 
        }
    };

    const onChangeTime: TimePickerProps['onChange'] = (time, timeString) => {
        if (typeof timeString === 'string') {
            setPublishDate(prev => prev ? `${prev}T${timeString}` : timeString); 
        }
    };

    const handleSubmit = () => {
        form.submit();
    };

    return (
        <div>
            <DrawerCustom
                title={'Gửi kiểm duyệt nội dung'}
                open={isOpened}
                onClose={closeForm}
                extraNode={
                    <Space>
                        <Button
                            onClick={closeForm}
                            className="btn btn-outlined"
                        >
                            <CloseOutlined />
                            <Typography.Text>Hủy</Typography.Text>
                        </Button>
                        <Button
                            className="btn btn-primary"
                            onClick={handleSubmit}
                        >
                            <SaveOutlined />
                            <Typography.Text>Gửi yêu cầu</Typography.Text>
                        </Button>
                    </Space>
                }
                widthDrawer={736}
            >
                <Form
                    form={form}
                    className="form-submit-review"
                    onFinish={onFinish}
                >   
                    {status === 'Đề xuất chỉnh sửa' && (
                        <div className="container-form">
                            <Typography className="container-form-title">Nội dung chỉnh sửa</Typography>
                            <Form.Item name="contentEdit">
                                <Input placeholder="Nhập nội dung" />
                            </Form.Item>
                        </div>
                    )}
                    <div className="container-form">
                        <Typography className="container-form-title">Quyền truy cập</Typography>
                        <Form.Item name="access">
                            <Radio.Group onChange={onChangeRadio}>
                                <Space direction="vertical">
                                    <Radio value={'Công khai'}>
                                        Công khai
                                        <Typography style={{ fontWeight: 'normal', color: '#8C8C8C' }}>Nội dung này cần Cấp bộ kiểm duyệt trước khi công khai
                                        </Typography>
                                    </Radio>
                                    <Radio value={'Nội bộ'}>
                                        Nội bộ
                                        <Typography style={{ fontWeight: 'normal', color: '#8C8C8C' }}>Nội dung này cần QTCS kiểm duyệt trước khi công khai nội bộ
                                        </Typography>
                                    </Radio>
                                    <Radio value={'Giới hạn'}>
                                        Giới hạn
                                        <Typography style={{ fontWeight: 'normal', color: '#8C8C8C' }}>Chỉ hiển thị nội dung với đối tượng được chia sẻ
                                        </Typography>
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        {access === 'Giới hạn' ? (
                            <Form.Item name="infoUser">
                                <div className="form-input" style={{ width: '100%' }}>
                                    <Input placeholder="Nhập Tên hoặc Email người dùng" style={{ width: '100%' }} />
                                </div>
                            </Form.Item>
                        ) : null}
                    </div>
                    <div className="container-form">
                        <Typography className="container-form-title">Lịch xuất bản dự kiến </Typography>
                        <Form.Item name='scheduleType'>
                            <Radio.Group onChange={onChangeRadio2}>
                                <Space direction="vertical">
                                    <Radio value={1}>
                                        Không xuất bản
                                        <Typography style={{ fontWeight: 'normal', color: '#8C8C8C' }}>Nội dung này không xuất bản sau khi kiểm duyệt
                                        </Typography>
                                    </Radio>
                                    <Radio value={2}>
                                        Xuất bản ngay
                                        <Typography style={{ fontWeight: 'normal', color: '#8C8C8C' }}>Nội dung này sẽ xuất bản ngay sau khi kiểm duyệt thành công
                                        </Typography>
                                    </Radio>
                                    <Radio value={3}>
                                        Hẹn lịch
                                        <Typography style={{ fontWeight: 'normal', color: '#8C8C8C' }}>Nội dung được kiểm duyệt thành công sẽ xuất bản theo lịch trình được thiết lập
                                        </Typography>
                                        {
                                            scheduleType === 3 ? (
                                                <div className="time-input">
                                                    <Form.Item 
                                                        name='date'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Vui lòng chọn ngày xuất bản!',
                                                            },
                                                        ]}
                                                    >
                                                        <DatePicker onChange={onChangeDate} format="DD/MM/YYYY" placeholder="Chọn ngày" />
                                                    </Form.Item>
                                                    <Form.Item 
                                                        name='time'
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Vui lòng chọn thời gian xuất bản!',
                                                            },
                                                        ]}
                                                    >
                                                        <TimePicker onChange={onChangeTime} placeholder="Chọn thời gian" />
                                                    </Form.Item>
                                                </div>
                                            ) : null
                                        }
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </Form>
            </DrawerCustom>
        </div>
    )
}

export default SubmitReview;
