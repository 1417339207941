import { Button, Col, Row } from "antd";
import QuizLayout from "../../../pages/preview-quiz/structure/quiz";
import FormInput from "../../../components/form-input/FormInput";
import ExamLayout from "./exam-quiz-preview";
import { useEffect, useState } from "react";
import { GroupsExam, QuizProps } from "../../../types/course";
import { dummyData } from "..";
import "./styles.scss";

import MoocMenu from "../../menu/MoocMenu";
import { SearchOutlined } from "@ant-design/icons";
import SettingExam from "./right-menu";
import { getUnitDetail } from "../../../service/course-construct";

type QuizExamScreenProps = {
  unitId?: string;
  mode?: string
};

function QuizExamScreen(props: QuizExamScreenProps) {
  const { unitId } = props;
  const [currentQuestion, setCurrentQuestion] = useState<QuizProps>();
  const [groups, setGroups] = useState<GroupsExam[]>([]);
  const [quizzesFlat, setQuizzesFlat] = useState<QuizProps[]>([]);
  const [active, setActive] = useState<number>(0);
  const [activeParent, setActiveParent] = useState<string[]>([]);

  const initialQuiz = (groups: GroupsExam[]) => {
    if (groups && groups?.length > 0) {
      for (let i = 0; i < groups?.length; i++) {
        if (groups[i]?.quizzes && groups[i]?.quizzes?.length > 0) {
          setCurrentQuestion(groups[i]?.quizzes[0]);
        }
      }
    }
  };

  const handleSetActiveParent = (parentKey: string) => {
    setActiveParent([`parent-${parentKey}`]);
  };

  const handleChangeQuizPanel = (quiz: QuizProps) => {
    setCurrentQuestion(quiz);
  };

  const handleNextQuiz = () => {
    if (quizzesFlat && currentQuestion) {
      // save quiz has time is completed
      // if (
      //   quizzesCompleted?.find(
      //     (item: QuizRequest) => item.quizId === currentQuestion?.id
      //   )
      // ) {
      //   setQuizzesHasTimeCompleted((prevState: number[]) => [
      //     ...prevState,
      //     currentQuestion?.id,
      //   ]);
      // }

      // next quiz
      const currentIndexQuiz = quizzesFlat?.findIndex(
        (quiz: QuizProps) => quiz.id === currentQuestion?.id
      );
      if (currentIndexQuiz !== -1) {
        const nextQuiz = quizzesFlat[currentIndexQuiz + 1];
        if (nextQuiz) setCurrentQuestion(nextQuiz);
      }
    }
  };

  const handlePreviousQuiz = () => {
    if (quizzesFlat && currentQuestion) {
      // save quiz has time is completed
      // if (
      //   quizzesCompleted?.find(
      //     (item: QuizRequest) => item.quizId === currentQuestion?.id
      //   )
      // ) {
      //   setQuizzesHasTimeCompleted((prevState: number[]) => [
      //     ...prevState,
      //     currentQuestion?.id,
      //   ]);
      // }
      // prev quiz
      const currentIndexQuiz = quizzesFlat?.findIndex(
        (quiz: QuizProps) => quiz.id === currentQuestion?.id
      );
      if (currentIndexQuiz !== -1) {
        const prevQuiz = quizzesFlat[currentIndexQuiz - 1];
        if (prevQuiz) setCurrentQuestion(prevQuiz);
      }
    }
  };

  const disableNextBtn = () => {
    return currentQuestion?.id === quizzesFlat[quizzesFlat?.length - 1]?.id;
  };

  const disablePrevBtn = () => {
    return currentQuestion?.id === quizzesFlat[0]?.id;
  };

  const handleFlatQuestion = (quizzes: any) => {
    for (let i = 0; i < quizzes?.length; i++) {
      if (quizzes[i]?.quizzes) {
        setQuizzesFlat((prevState: QuizProps[]) => [
          ...prevState,
          ...quizzes[i]?.quizzes,
        ]);
      }
    }
  };

  useEffect(() => {
    // Get unit info trước khi start
    const getUnitInfo = async () => {
      // setLoading(true);
      // setDirty(false);
      try {
        const resAPI = await getUnitDetail(Number(unitId)).then((res) => {
          // change later
          setGroups(res.data.data.quizGroups);
          initialQuiz(res.data.data.quizGroups);
          setCurrentQuestion(res.data.data.quizGroups[1].quizzes[0]);
          handleFlatQuestion(res.data.data.quizGroups);
        });

        //  nếu là bài tập mở và không giới hạn thời gian => view
      } catch (err) {
        console.error(err);
      } finally {
        // setLoading(false);
      }
    };
    if(unitId) getUnitInfo();
  }, [unitId]);

  const renderContainer = () => {
    return (
      <div className="structure-content flex">
        <div>
          <div className="pv-16 search-container">
            <Row gutter={[{ xs: 8, xl: 8, md: 8 }, 8]} className="mt-2">
              <Col
                xl={{ span: 24 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <FormInput
                  placeholder="Tìm câu hỏi"
                  onChange={(e: any) => {
                    // setSearchValue(e.target.value);
                    // if (e.target.value === '') {
                    //   getAllQuizByUnitId(e.target.value);
                    // }
                  }}
                  onKeyPress={() => {
                    {
                      console.log("enter pressed");
                    }
                  }}
                  afterPrefixIcon={
                    <SearchOutlined
                      onClick={() => {
                        // getAllQuizByUnitId();
                      }}
                    />
                  }
                />
              </Col>
            </Row>
          </div>
          <MoocMenu
            // change later
            data={groups as any}
            mode="small"
            active={active}
            activeParent={activeParent}
            setActive={setActive}
            setActiveParent={handleSetActiveParent}
            onClick={handleChangeQuizPanel}
          />
        </div>
        <div className="train-layout flex-1">
          <ExamLayout
            quiz={currentQuestion}
            quizzes={quizzesFlat}
            disableNextBtn={disableNextBtn()}
            disablePrevBtn={disablePrevBtn()}
            onNext={handleNextQuiz}
            onPrevious={handlePreviousQuiz}
          />
        </div>
        <SettingExam />
      </div>
    );
  };
  return (
    <div className="course-structure-exam">
      <div className="structure-header w-full flex gap-24">
        <h3 className="font-size-24 line-height-32 mb-2"></h3>
      </div>

      {renderContainer()}
    </div>
  );
}

export default QuizExamScreen;
