import { Button, Col, ColorPicker, ColorPickerProps, Divider, Drawer, Form, Input, Modal, Radio, RadioChangeEvent, Row, Select, Space, Typography, Upload, UploadProps, message, theme } from 'antd'
import React, { useEffect, useState } from 'react'
import FormInput from '../../form-input/FormInput'
import FormRadio from '../../form-radio/FormRadio'
import { cyan, generate, green, presetPalettes, red } from '@ant-design/colors';
import { CloseOutlined, CopyOutlined, ExclamationCircleOutlined, HeatMapOutlined, InfoCircleOutlined, LoadingOutlined, PicCenterOutlined, PlayCircleOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import FormItemDropdown from '../../form-dropdown/FormItemDropdown'
import "./index.scss"
import CommonModal from '../../modal/common'
import { useLocation, useNavigate } from 'react-router-dom'
import { FileType } from '../../../utils/uploadImage'
import { createPortalAdministration, getAllUniversity } from '../../../service/portal-administration';
import { isEmpty, values } from 'lodash';
import ReactQuill from 'react-quill';
import { FORMAT_REACT_QUILL, TOOLBAROPTIONS } from '../../../constants';
import { publishCourse } from '../../../service/course-construct';
import { createContentBlog, updateContentBlog } from '../../../service/blog-and-forum';
var indexDB = require("idbcache").default;

type Props = {
  open: boolean
  handleClose: () => void
  getListData: () => void
  detailData: any
}

type Presets = Required<ColorPickerProps>['presets'][number];

const genPresets = (presets = presetPalettes) =>
  Object.entries(presets).map<Presets>(([label, colors]) => ({
    label,
    colors,
  }));

const getBase64 = (img: FileType, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: FileType) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('Bạn chỉ có thể upload file ảnh');
    return isJpgOrPng
  }
  return false
}

export default function AddBlogContentDrawer(props: Props) {
  const { open, handleClose, getListData, detailData } = props
  const [form] = Form.useForm();
  const [value, setValue] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [listSchool, setListSchool] = useState<any>([]);
  const [openCorfirmModal, setOpenCorfirmModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [color, setColor] = React.useState<string>('#1677ff');
  // const [value, setValue] = useState("");

  const validation = {
    titleBlog: {
      required: true,
      validator: (_: any, value: any) => {
        if (value.replace(/<p><br><\/p>/g, "") !== "") {
          return Promise.resolve();
        } else {
          return Promise.reject('Some message here');
        }
      },
      message: "Vui lòng nhập tiêu đề blog"
    },
    logoBlog: {
      required: detailData?.imagePath && imageUrl ? false : true,
      message: "Vui lòng tải logo blog"
    },
    descriptionBlog: {
      required: true,
      validator: (_: any, value: any) => {
        if (value.replace(/<p><br><\/p>/g, "") !== "") {
          return Promise.resolve();
        } else {
          return Promise.reject('Some message here');
        }
      },
      message: "Vui lòng nhập mô tả blog"
    },
  }

  const dataStatus = [
    {
      value: true,
      label: "Hoạt động"
    },
    {
      value: false,
      label: "Không hoạt động"
    }
  ]

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const OPTIONS = [
    {
      value: 1,
      label: <PlayCircleOutlined />
    },
    {
      value: 2,
      label: <PicCenterOutlined />
    },
    {
      value: 3,
      label: <CopyOutlined />
    },
    {
      value: 4,
      label: <HeatMapOutlined />
    }
  ];

  const listVideo = [
    {
      id: 4,
      name: 'Mẫu đánh giá 1'
    },
    {
      id: 5,
      name: 'Mẫu đánh giá 2'
    },
    {
      id: 6,
      name: 'Mẫu đánh giá 3'
    }
  ]

  const gotoAddLessonPage = () => {
    form.submit()
  }

  const getUniversity = async () => {
    await getAllUniversity(searchValue).then((res) => {
      setListSchool(res.data.data)
    })
  }

  useEffect(() => {
    form.setFieldValue("typeStatus", 1)
  }, [])

  useEffect(() => {
    getUniversity()
  }, [open])

  const handleChange: UploadProps['onChange'] = (info) => {
    setLoading(true);
    if (info.fileList?.[0]?.type === 'image/jpeg' || info.fileList?.[0]?.type === 'image/png') {
      getBase64(info.fileList?.[0]?.originFileObj as FileType, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    } else {
      form.setFieldValue("universityLogo", null)
      setImageUrl("")
      setLoading(false);
    }
    // }
  };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const modules = {
    toolbar: TOOLBAROPTIONS,
    clipboard: {
      matchVisual: false,
    }
  };

  const resetData = () => {
    getListData()
    handleClose()
    form.resetFields()
    setImageUrl("")
  }

  const saveData = async (values: any) => {
    const formData = new FormData();
    formData.append("name", values.titleBlog.replaceAll(/<\/?p[^>]*>/g, '').replace('<br>', ''));
    formData.append("description", values.descriptionBlog.replace(/<p><br><\/p>/g, ""));

    if (!isEmpty(values.universityLogo?.fileList)) {
      formData.append("imagePath", values.universityLogo?.fileList[0].originFileObj ?? null);
    }
    if (detailData?.id) {
      formData.append("id", detailData?.id ?? "");
    }

    if (detailData?.id) {
      formData.append("status", detailData.status);
      await updateContentBlog(formData).then(res => {
        if (res.status === 200) {
          message.success("Sửa thông tin thành công")
          resetData()
          form.resetFields()
        }
      })
    } else {
      formData.append("status", "1");
      await createContentBlog(formData).then(res => {
        if (res.status === 200) {
          message.success("Lưu thông tin thành công")
          resetData()
          form.resetFields()
        }
      })
    }
  }

  useEffect(() => {
    if (detailData) {
      form.setFieldValue("titleBlog", detailData?.name ?? '')
      // form.setFieldValue("universityLogo", detailData?.imagePath ?? '')
      form.setFieldValue("descriptionBlog", detailData?.description ?? '')
      setImageUrl(detailData?.imagePath)
    }
  }, [detailData])

  return (
    <Form.Provider onFormFinish={async (name, { values, forms }) => {
      if (name === 'form-add-blog-content') {
        Modal.confirm({
          title: `Xác nhận lưu bài đăng`,
          content: `Đảm bảo tất cả các thông tin liên quan đã được kiểm tra và cập nhật đầy đủ trước khi lưu bài đăng.`,
          onOk: () => {
            saveData(values)
          },
          okText: "Xác nhận",
          cancelText: "Huỷ",
          centered: true,
          icon: <ExclamationCircleOutlined style={{ color: "#1677FF" }} />,
          footer: (_, { OkBtn, CancelBtn }) => (
            <>
              <CancelBtn />
              <OkBtn />
            </>
          ),
        });
      }
    }}>

      <Drawer
        title={`${detailData?.id ? 'Sửa' : "Thêm"} bài đăng`}
        placement={"right"}
        className='add-blog-content-drawer'
        width={600}
        onClose={handleClose}
        open={open}
        extra={
          <Space>
            <Button onClick={handleClose} className="btn btn-outlined">
              <CloseOutlined />
              <Typography.Text>
                Hủy
              </Typography.Text>
            </Button>
            <Button className="btn btn-primary"
              onClick={() => form.submit()
              }>
              <SaveOutlined />
              <Typography.Text>
                Lưu
              </Typography.Text>
            </Button>
          </Space>
        }
      >
        <>
          <Form layout={"vertical"} form={form} name="form-add-blog-content">
            <Row gutter={24}>
              <Col span={24}>
                <Typography.Title level={5}>Tiêu đề bài đăng <span className='span-red'>*</span></Typography.Title>
                <p className='text-secondary'>Nhập tiêu đề cho bài đăng của bạn</p>
                <Form.Item name="titleBlog" rules={[validation.titleBlog]} label="">
                  <ReactQuill
                    className='mt-1'
                    modules={modules}
                    formats={FORMAT_REACT_QUILL}
                    theme="snow"
                    // onChange={setValue}
                    placeholder="Nhập text" />
                </Form.Item>
              </Col>
              <Col span={24} className='mt-2'>
                <Typography.Title level={5}>Ảnh đại diện <span className='span-red'>*</span></Typography.Title>
                <p className='text-secondary'>Tải ảnh đại diện</p>
                <Form.Item name="universityLogo" rules={[validation.logoBlog]} label="" hasFeedback>
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader mt-1 h-full"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    accept='.png,.svg,.jpg,.jpeg'
                    onChange={handleChange}
                    maxCount={1}
                  >
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={24} className='mt-2'>
                <Typography.Title level={5}>Mô tả <span className='span-red'>*</span></Typography.Title>
                <p className='text-secondary'>Nhập mô tả cho bài đăng của bạn</p>
                <Form.Item name="descriptionBlog" rules={[validation.descriptionBlog]} label="">
                  <ReactQuill
                    className='mt-1'
                    modules={modules}
                    formats={FORMAT_REACT_QUILL}
                    theme="snow"
                    // onChange={setValue}
                    placeholder="Nhập text" />
                </Form.Item>
              </Col>

              <Col span={24} className='flex mt-2 gap-6'>
                <Typography.Title level={5}>Trạng thái <span className='span-red'>*</span></Typography.Title>
                <div className="radio-group-list">
                  <div className="wrapper-radio">
                    <Radio checked>Nháp</Radio>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </>
      </Drawer>
    </Form.Provider >
  )
}
