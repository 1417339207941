import React, {useEffect, useMemo, useState, useTransition} from "react";
import {staticReportStore} from "../../../../../stores/stores";
import {BarStackedChartProps, LineChartProps, PieChart} from "../../../../../types/static-report";
import dayjs from "dayjs";
import {
  getSearchHistoryForMinistry,
  getSearchKeywordCountForMinistry,
  getUnitActionForMinistry
} from "../../../../../service/statistical-report";
import {debounce} from "lodash";
import UserBehaviorChartTab from "../chart/user-behavior-tab/UserBehaviorChartTab";
import BehaviorTable from "../table/behavior-table";

type Props = {
  dataType: string;
};

const UserBehaviorTab = ({dataType}: Props) => {

  const [isPending, startTransition] = useTransition()
  const {staticReportType, filterValues, tab} = staticReportStore();
  const [actionModuleMapping, setActionModuleMapping] = useState({
    courseStructureType: null,
    actionType: null,
    moduleGroup: null
  });

  const [chartData, setChartData] = useState({
    searchKeywordCountForMinistry: [] as PieChart[],
    searchHistoryForMinistry: [] as BarStackedChartProps[],
    unitDownloadResource: [] as LineChartProps[],
    unitPreviewResource: [] as LineChartProps[],
    unitShareResource: [] as LineChartProps[],
  });

  const [loading, setLoading] = useState({
    searchKeywordCountForMinistry: false,
    searchHistoryForMinistry: false,
    unitDownloadResource: false,
    unitPreviewResource: false,
    unitShareResource: false,
  });

  const payload = useMemo(
    () => ({
      from: dayjs(filterValues.dateFrom).toISOString(),
      to: dayjs(filterValues.dateTo).toISOString(),
      courseLevelIds: filterValues?.courseLevelIds,
      industryGroupIds: filterValues?.industryGroupIds,
      courseIds: filterValues?.courseIds,
      universityIds: filterValues?.universityIds,
      timeUnit: filterValues.timeUnit
    }),
    [
      filterValues.dateFrom,
      filterValues.dateTo,
      filterValues?.courseLevelIds,
      filterValues?.industryGroupIds,
      filterValues?.courseIds,
      filterValues?.universityIds,
      filterValues.timeUnit,
    ]
  );

  const fetchDataChart = async () => {
    const listApi = [
      {
        key: 'searchKeywordCountForMinistry',
        api: getSearchKeywordCountForMinistry,
        params: payload,
      },
      {
        key: 'searchHistoryForMinistry',
        api: getSearchHistoryForMinistry,
        params: payload,
      },
      {
        key: 'unitDownloadResource',
        api: getUnitActionForMinistry,
        params: {
          ...payload,
          courseStructureType: "unit",
          moduleGroup: 3,
          unitActionType: 3
        },
      },
      {
        key: 'unitPreviewResource',
        api: getUnitActionForMinistry,
        params: {
          ...payload,
          courseStructureType: "unit",
          moduleGroup: 3,
          unitActionType: 1
        },
      },
      {
        key: 'unitShareResource',
        api: getUnitActionForMinistry,
        params: {
          ...payload,
          courseStructureType: "unit",
          moduleGroup: 3,
          unitActionType: 2
        },
      }
    ]

    await Promise.allSettled(
      listApi.map(async ({key, api, params}) => {
        setLoading((prev) => ({...prev, [key]: true}));
        try {
          const response = await api(params);
          startTransition(() => {
            setChartData((prevState) => ({
              ...prevState,
              [key]: response?.data?.data?.statistic || response?.data?.data,
            }));
          })
        } catch (error) {
          console.error(`Error fetching data for ${key}:`, error);
          startTransition(() => {
            setChartData(prevState => ({
              ...prevState,
              [key]: [], // Provide fallback empty data
            }));
          });
        } finally {
          setLoading((prev) => ({...prev, [key]: false}));
        }
      })
    );
  }

  const fetchUnitActionForMinistry = async (dataActionModuleMapping: any) => {
    switch (dataActionModuleMapping?.actionType) {
      case 1:
        setLoading((prev) => ({...prev, unitPreviewResource: true}));
        break;
      case 2:
        setLoading((prev) => ({...prev, unitShareResource: true}));
        break;
      case 3:
        setLoading((prev) => ({...prev, unitDownloadResource: true}));
        break;
      default:
        break;
    }

    try {
      const data = await getUnitActionForMinistry({
        ...payload,
        courseStructureType: dataActionModuleMapping?.courseStructureType,
        moduleGroup: dataActionModuleMapping?.moduleGroup,
        unitActionType: dataActionModuleMapping?.actionType,
      });
      switch (dataActionModuleMapping?.actionType) {
        case 1:
          setChartData((prevState) => ({
            ...prevState,
            unitPreviewResource: data?.data?.data,
          }));
          break;
        case 2:
          setChartData((prevState) => ({
            ...prevState,
            unitShareResource: data?.data?.data,
          }));
          break;
        case 3:
          setChartData((prevState) => ({
            ...prevState,
            unitDownloadResource: data?.data?.data,
          }));
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Error fetching unitReviewAndAccessForMinistry data:", error);
    } finally {
      switch (dataActionModuleMapping?.actionType) {
        case 1:
          setLoading((prev) => ({...prev, unitPreviewResource: false}));
          break;
        case 2:
          setLoading((prev) => ({...prev, unitShareResource: false}));
          break;
        case 3:
          setLoading((prev) => ({...prev, unitDownloadResource: false}));
          break;
        default:
          break;
      }
    }
  };

  const debouncedFetchData = useMemo(
    () => debounce(fetchDataChart, 500),
    [filterValues]
  );

  const debouncedFetchUnitActionForMinistry = useMemo(
    () => debounce(fetchUnitActionForMinistry, 300),
    [filterValues]
  );

  useEffect(() => {
    if (tab === "4") {
      debouncedFetchData();
    }
    return () => {
      debouncedFetchData.cancel();
    };
  }, [filterValues, tab]);

  useEffect(() => {
    if (tab === "4" && actionModuleMapping.actionType && actionModuleMapping.moduleGroup) {
      debouncedFetchUnitActionForMinistry(actionModuleMapping);
    }
    return () => {
      debouncedFetchUnitActionForMinistry.cancel();
    };
  }, [actionModuleMapping]);

  const memoizedChartData = useMemo(() => {
    return {
      searchKeywordCountForMinistry: chartData.searchKeywordCountForMinistry,
      searchHistoryForMinistry: chartData.searchHistoryForMinistry,
      unitDownloadResource: chartData.unitDownloadResource,
      unitPreviewResource: chartData.unitPreviewResource,
      unitShareResource: chartData.unitShareResource,
    };
  }, [chartData]);

  return (dataType === "1") ?
    <UserBehaviorChartTab  {...memoizedChartData} loading={loading} setActionModuleMapping={setActionModuleMapping}/> :
    <BehaviorTable {...memoizedChartData} loading={isPending} actionModuleMapping={actionModuleMapping}
                   setActionModuleMapping={setActionModuleMapping}/>
};

export default UserBehaviorTab;
