import { Button, Col, DatePicker, Form, FormInstance, Row, Typography } from 'antd';
import React from 'react';
import FormItemDropdown from '../../../../form-dropdown/FormItemDropdown';
import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { FilterData } from "./filter";

type FilterGroupTypes = {
  form: FormInstance,
  showFilter: boolean,
  onFinish?: () => void;
  filterData: FilterData
  setFilterData: (dateString: string[], planCreationDate: string) => void
  clearFilter: () => void;
  dataOptions?: any;
  typeQuestion?: boolean
}


const FilterGroup = ({
  form, showFilter,
  filterData,
  setFilterData,
  dataOptions,
  onFinish,
  clearFilter,
  typeQuestion
}: FilterGroupTypes) => {
  const { RangePicker } = DatePicker;

  return (
    <div className={`wrapper-filter ${showFilter ? "open" : ""}`}>
      <Form form={form} name="filterForm" onFinish={onFinish}>
        <Row gutter={[{ xs: 8, xl: 24, md: 16 }, 12]}>
          <Col
            xl={{ span: 4 }}
            md={{ span: 5 }}
            sm={{ span: 5 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>Câu hỏi</Typography.Title>
            <Form.Item name="questionType">
              <FormItemDropdown
                placeholder="Chọn loại câu hỏi"
                options={dataOptions.dataTopic}
                value={filterData.questionId}
                onChange={(option) => setFilterData(option, "questionId")}
              />
            </Form.Item>
          </Col>
          <Col
            xl={{ span: 4 }}
            md={{ span: 5 }}
            sm={{ span: 5 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>Độ khó</Typography.Title>
            <Form.Item name="difficulty">
              <FormItemDropdown
                placeholder="Chọn độ khó"
                options={dataOptions.dataDifficulty}
                value={filterData.difficulty}
                onChange={(option) => setFilterData(option, "difficulty")}
              />
            </Form.Item>
          </Col>
          {
            !typeQuestion ?
              <>
                <Col
                  xl={{ span: 4 }}
                  md={{ span: 5 }}
                  sm={{ span: 5 }}
                  xs={{ span: 24 }}
                >
                  <Typography.Title level={5}>Quyền truy cập</Typography.Title>
                  <Form.Item name="permission">
                    <FormItemDropdown
                      modeDropdown="multiple"
                      placeholder="Chọn quyền truy cập"
                      options={dataOptions.dataPermission}
                      value={filterData.permission}
                      onChange={(option) => setFilterData(option, "permission")}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xl={{ span: 4 }}
                  md={{ span: 5 }}
                  sm={{ span: 5 }}
                  xs={{ span: 24 }}
                >
                  <Typography.Title level={5}>Tình trạng</Typography.Title>
                  <Form.Item name="status">
                    <FormItemDropdown
                      placeholder="Chọn tình trạng"
                      modeDropdown="multiple"
                      options={dataOptions.dataStatus}
                      value={filterData.status}
                      onChange={(option) => setFilterData(option, "status")}
                    />
                  </Form.Item>
                </Col>
              </> :
              <>
                <Col
                  xl={{ span: 4 }}
                  md={{ span: 5 }}
                  sm={{ span: 5 }}
                  xs={{ span: 24 }}
                >
                  <Typography.Title level={5}>Chọn phân cấp</Typography.Title>
                  <Form.Item name="hierarchy">
                    <FormItemDropdown
                      modeDropdown="multiple"
                      placeholder="Chọn phân cấp"
                      options={dataOptions.dataTypeProduction}
                      onChange={(option) => setFilterData(option, "typeProduction")}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xl={{ span: 4 }}
                  md={{ span: 5 }}
                  sm={{ span: 5 }}
                  xs={{ span: 24 }}
                >
                  <Typography.Title level={5}>Chọn Đơn vị xuất bản</Typography.Title>
                  <Form.Item name="status">
                    <FormItemDropdown
                      modeDropdown="multiple"
                      placeholder="Chọn đơn vị xuất bản"
                      options={dataOptions.dataUnit}
                      onChange={(option) => setFilterData(option, "unit")}
                    />
                  </Form.Item>
                </Col>
              </>
          }
          <Col
            xl={{ span: 4 }}
            md={{ span: 5 }}
            sm={{ span: 5 }}
            xs={{ span: 24 }}
          >
            <Typography.Title level={5}>Ngày cập nhật</Typography.Title>
            <Form.Item name="updatedDate">
              <RangePicker
                placeholder={["Bắt đầu", "Kết thúc"]}
                format="DD-MM-YYYY"
                value={filterData.updatedDate}
                onChange={(_value, dateString: any) => {
                  if(dateString?.[0]) {
                    const convertedDates = dateString.map((date: String) => {
                      const [day, month, year] = date.split("-");
                      return `${year}-${month}-${day}`;
                    });
                    setFilterData(convertedDates, "updatedDate");
                  } else {
                    setFilterData([], "updatedDate");
                  }
                }}
              />
            </Form.Item>
          </Col>

          <Col
            xl={{ span: 3 }}
            md={{ span: 9 }}
            sm={{ span: 9 }}
            xs={{ span: 24 }}
            className="flex align-end"
          >
            <div className="group-btn--filter">
              <Button
                className="btn btn-primary filter-btn"
                htmlType="submit"
                onClick={() => form.submit()}
              >
                <FilterOutlined />
                <Typography.Text>Lọc</Typography.Text>
              </Button>
              <Button
                className="btn btn-outlined clear-filter-btn"
                onClick={clearFilter}
              >
                <DeleteOutlined />
                <Typography.Text>Xóa bộ lọc</Typography.Text>
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FilterGroup;