import { Layout } from "antd";
import { SearchProps } from "antd/es/input";
import { Input } from "antd/lib";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeaderCommon from "../../../../components/page-header-common/PageHeaderCommon";
import { routesConfig } from "../../../../config/routes";
import { getStudentFavoriteSuggest, getSystemSuggestCourseAll } from "../../../../service/system-suggest-course";
import TableFavoriteStudent from "./TableInfoSystem";
const { Content } = Layout;
const imgPath = "/images/";

const { Search } = Input;

const SearchFilter = ({ onSearch }: any) => {
  const [searchText, setSearchText] = useState<string>("");
  const handleChangeSearch = (e: any) => {
    setSearchText(e?.target?.value);
  };
  return (
    <div>
      <Search
        placeholder="Nhập thẻ tag để tìm kiếm "
        onSearch={onSearch}
        value={searchText}
        onChange={handleChangeSearch}
      />
    </div>
  );
};

const InfoSystemSuggest = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [listInfoSystemSuggest, setListInfoSystemSuggest] = useState<any[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");

  const fetchRateData = async (searchParam: any) => {
    try {
      const res = await getStudentFavoriteSuggest(searchParam);
      const { data } = res.data;
      setTotalPage(data?.count?._count?.id);
      setListInfoSystemSuggest(data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchRateData({
      name: searchValue,
      id: params?.id,
      take: pageSize,
      skip: (pageNumber - 1) * pageSize,
    });
  }, [pageSize, pageNumber, searchValue]);

  const listBreadcrumb = [
    {
      title: "Hệ thống gợi ý khoá học",
    },
    {
      title: "Danh sách sinh viên",
    },
    {
      title: "Thông tin đã gợi ý cho sinh viên",
    },
  ];

  const handleBack = () => {
    navigate(`/system-suggest-course/${routesConfig.listStudentSystem}`);
  };

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    setSearchValue(value);
  };

  return (
    <Content>
      <PageHeaderCommon
        listBreadcrumb={listBreadcrumb}
        title="Thông tin đã gợi ý cho sinh viên"
        arrowLeft={imgPath + "arrowLeft.svg"}
        positionItem="column"
        breadcrumb={true}
        handleBack={handleBack}
        childrenGroupButton={<SearchFilter onSearch={onSearch} />}
        headerGroupButton
      />
      <TableFavoriteStudent
        qualityAutoScore={listInfoSystemSuggest}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        pageSize={pageSize}
        loading={loading}
        totalPage={totalPage}
      />
    </Content>
  );
};

export default InfoSystemSuggest;
