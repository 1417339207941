import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal, unstable_batchedUpdates } from 'react-dom';
import {
  CancelDrop,
  closestCenter,
  CollisionDetection,
  defaultDropAnimationSideEffects,
  DndContext,
  DragOverlay,
  DropAnimation,
  getFirstCollision,
  KeyboardCoordinateGetter,
  KeyboardSensor,
  MeasuringStrategy,
  Modifiers,
  MouseSensor,
  pointerWithin,
  rectIntersection,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  AnimateLayoutChanges,
  arrayMove,
  defaultAnimateLayoutChanges,
  horizontalListSortingStrategy,
  SortableContext,
  SortingStrategy,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { coordinateGetter as multipleContainersCoordinateGetter } from './multipleContainersKeyboardCoordinates';
import { Container, ContainerProps, Item } from '../components';
import { UnitQuiz } from '../../../types/course-construction';
import { convertQuillToString } from '../../../utils/convertQuillToString';
import { BookOutlined, PlusOutlined } from '@ant-design/icons';

export default { title: 'Presets/Sortable/Multiple Containers' };
export type ItemsDraggerMulti = Record<UniqueIdentifier, UniqueIdentifier[]>;
export const TRASH_ID = 'void';
const PLACEHOLDER_ID = 'placeholder';
const empty: UniqueIdentifier[] = [];
const animateLayoutChanges: AnimateLayoutChanges = (args) => defaultAnimateLayoutChanges({ ...args, wasDragging: true });
const dropAnimation: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

interface SortableItemProps {
  containerId: UniqueIdentifier;
  id: UniqueIdentifier;
  index: number;
  handle: boolean;
  disabled?: boolean;

  style(args: any): React.CSSProperties;

  getIndex(id: UniqueIdentifier): number;

  renderItem(): React.ReactElement;

  wrapperStyle({ index }: { index: number }): React.CSSProperties;

  content: string,
  onClick?: (id: any) => void;
  onDropDownClick?: (key: string, id: any) => void;
  activeQuestion?: number;
}

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);
    return () => clearTimeout(timeout);
  }, []);
  return isMounted;
}

function DroppableContainer({
  collapsible,
  children,
  columns = 1,
  disabled,
  id,
  items,
  style,
  handleAddQuestion = (id: any) => ({}),
  handleAddQuestionFromBank = (id: any) => ({}),
  handleEditNameGroup = (id: any) => ({}),
  handleDeleteGroup = (id: any) => ({}),
  ...props
}: ContainerProps & {
  disabled?: boolean;
  id: UniqueIdentifier;
  items: UniqueIdentifier[];
  style?: React.CSSProperties;
  handleAddQuestion?: (id: any) => void;
  handleAddQuestionFromBank?: (id: any) => void;
  handleEditNameGroup?: (id: any) => void;
  handleDeleteGroup?: (id: any) => void;
}) {
  const {
    active,
    attributes,
    isDragging,
    listeners,
    over,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id,
    data: {
      type: 'container',
      children: items,
    },
    animateLayoutChanges,
  });


  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== 'container') ||
    items.includes(over.id)
    : false;
  return (
    <Container
      collapsible={collapsible}
      ref={disabled ? undefined : setNodeRef}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      hover={isOverContainer}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      columns={columns}
      idGroup={id}
      onEditNameGroup={handleEditNameGroup}
      onDeleteGroup={handleDeleteGroup}
      {...props}
    >
      {children}
      {items.length === 0 ? <span className="no-question">Không có câu hỏi</span> : ''}
      <p className="cursor-pointer add-question p-1" onClick={() => {
        const numberId = id.toString().replace('quiz-', '');
        handleAddQuestion(Number(numberId))
      }}>
        <PlusOutlined className="mr-2" /> Thêm câu hỏi
      </p>
      <p className="cursor-pointer add-question p-1" onClick={() => {

        const numberId = id.toString().replace('quiz-', '');
        handleAddQuestionFromBank(Number(numberId))
      }}>
        <BookOutlined className="mr-2" /> Thêm từ ngân hàng câu hỏi
      </p>
    </Container>
  );
}

function SortableItem({
  disabled,
  id,
  index,
  handle,
  renderItem,
  style,
  containerId,
  getIndex,
  wrapperStyle,
  content,
  onClick = (id: any) => ({}),
  onDropDownClick = (key: string, id: any) => ({}),
  activeQuestion,
}: SortableItemProps) {
  const {
    setNodeRef,
    setActivatorNodeRef,
    listeners,
    isDragging,
    isSorting,
    over,
    overIndex,
    transform,
    transition,
  } = useSortable({
    id,
  });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;
  return (
    <Item
      isActiveQuestion={Number(id) === activeQuestion}
      content={content}
      ref={disabled ? undefined : setNodeRef}
      value={id}
      dragging={isDragging}
      sorting={isSorting}
      handle={handle}
      handleProps={handle ? { ref: setActivatorNodeRef } : undefined}
      index={index}
      wrapperStyle={wrapperStyle({ index })}
      style={style({
        index,
        value: id,
        isDragging,
        isSorting,
        overIndex: over ? getIndex(over.id) : overIndex,
        containerId,
      })}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      renderItem={renderItem}
      onClick={(id) => {
        onClick(id)
      }}
      onDropDownClick={(key, id) => {
        onDropDownClick(key, id)
      }}
    />
  );
}

interface MultipleContainersProps {
  collapsible?: boolean;
  adjustScale?: boolean;
  handle?: boolean;
  minimal?: boolean;
  trashable?: boolean;
  scrollable?: boolean;
  vertical?: boolean;
  cancelDrop?: CancelDrop;
  columns?: number;
  containerStyle?: React.CSSProperties;
  coordinateGetter?: KeyboardCoordinateGetter;

  getItemStyles?(args: {
    value: UniqueIdentifier;
    index: number;
    overIndex: number;
    isDragging: boolean;
    containerId: UniqueIdentifier;
    isSorting: boolean;
    isDragOverlay: boolean;
  }): React.CSSProperties;

  wrapperStyle?(args: { index: number }): React.CSSProperties;

  renderItem?: any;
  strategy?: SortingStrategy;
  modifiers?: Modifiers;
  dataItemsRender?: ItemsDraggerMulti;
  activeQuestion?: number;
  dataBase?: UnitQuiz[];
  handelChangePostionParent?: (val: any, cb?: any) => void;
  handelChangePostionChild?: (val: any, cb?: any) => void;
  handleAddGroupQuestion?: (val: any) => void;
  handleAddQuestionFromBank?: (val: any) => void;
  handleEditNameGroup?: (val: any) => void;
  handleDeleteGroup?: (val: any) => void;
  handleClickChild?: (val: any) => void;
  handleClickDropDownChild?: (key: string, val: any) => void;
}

export function MultipleContainers({
  collapsible = false,
  adjustScale = false,
  handle = false,
  minimal = false,
  scrollable,
  trashable = false,
  vertical = false,
  cancelDrop,
  columns,
  containerStyle,
  coordinateGetter = multipleContainersCoordinateGetter,
  getItemStyles = () => ({}),
  wrapperStyle = () => ({}),
  modifiers,
  renderItem,
  strategy = verticalListSortingStrategy,
  dataItemsRender = {},
  dataBase = [],
  activeQuestion = -1,
  handelChangePostionParent = (val: any, cb?: any) => ({}),
  handelChangePostionChild = (val: any, cb?: any) => ({}),
  handleAddGroupQuestion = (val: any) => ({}),
  handleAddQuestionFromBank = (val: any) => ({}),
  handleEditNameGroup = (val: any) => ({}),
  handleDeleteGroup = (val: any) => ({}),
  handleClickChild = (val: any) => ({}),
  handleClickDropDownChild = (key: string, val: any) => ({}),
}: MultipleContainersProps) {
  const [itemsRender, setItemsRender] = useState<ItemsDraggerMulti>(() => dataItemsRender);
  const [containers, setContainers] = useState(Object.keys(dataItemsRender) as UniqueIdentifier[]);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);
  const isSortingContainer = activeId ? containers.includes(activeId) : false;
  const [isGroupEdit, setIsGroupEdit] = useState<number>(1);

  useEffect(() => {
    setItemsRender(dataItemsRender);
    setContainers(Object.keys(dataItemsRender));
  }, [dataItemsRender]);

  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      if (activeId && activeId in itemsRender) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) => container.id in itemsRender
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
          pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        if (overId === TRASH_ID) {
          // If the intersecting droppable is the trash, return early
          // Remove this if you're not using trashable functionality in your app
          return intersections;
        }

        if (overId in itemsRender) {
          const containerItems = itemsRender[overId];

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  containerItems.includes(container.id)
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, itemsRender]
  );
  const [clonedItems, setClonedItems] = useState<ItemsDraggerMulti | null>(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );
  const findContainer = (id: UniqueIdentifier) => {
    if (id in itemsRender) {
      return id;
    }

    return Object.keys(itemsRender).find((key) => itemsRender[key].includes(id));
  };

  const getIndex = (id: UniqueIdentifier) => {
    const container = findContainer(id);

    if (!container) {
      return -1;
    }

    const index = itemsRender[container].indexOf(id);

    return index;
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItemsRender(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [itemsRender]);

  function renderSortableItemDragOverlay(id: UniqueIdentifier) {
    const child = findQuizById(dataBase, Number(id));
    let contentChild = convertQuillToString(child?.question);
    return (
      <Item
        content={contentChild}
        value={id}
        handle={handle}
        style={getItemStyles({
          containerId: findContainer(id) as UniqueIdentifier,
          overIndex: -1,
          index: getIndex(id),
          value: id,
          isSorting: true,
          isDragging: true,
          isDragOverlay: true,
        })}
        wrapperStyle={wrapperStyle({ index: 0 })}
        renderItem={renderItem}
        dragOverlay
      />
    );
  }

  function renderContainerDragOverlay(containerId: UniqueIdentifier) {
    const title = dataBase.find((x: UnitQuiz) => {
      return x.groupId === Number(containerId.toString().replace('quiz-', ''))
    })?.title;
    return (
      <Container
        label={`${title}`}
        columns={columns}
        style={{
          height: '100%',
        }}
        shadow
        unstyled={false}
      >
        {itemsRender[containerId].map((item, index) => {
          const child = findQuizById(dataBase, Number(item));
          let contentChild = convertQuillToString(child?.question);
          return (
            <Item
              content={contentChild}
              key={item}
              value={item}
              handle={handle}
              style={getItemStyles({
                containerId,
                overIndex: -1,
                index: getIndex(item),
                value: item,
                isDragging: false,
                isSorting: false,
                isDragOverlay: false,
              })}
              wrapperStyle={wrapperStyle({ index })}
              renderItem={renderItem}
            />
          )

        })}
      </Container>
    );
  }

  function handleRemove(containerID: UniqueIdentifier) {
    setContainers((containers) =>
      containers.filter((id) => id !== containerID)
    );
  }

  function getNextContainerId() {
    const containerIds = Object.keys(itemsRender);
    const lastContainerId = containerIds[containerIds.length - 1];
    return String.fromCharCode(lastContainerId.charCodeAt(0) + 1);
  }

  function findQuizById(data: any, quizId: number) {
    for (const group of data) {
      for (const quiz of group.quizzes) {
        if (quiz.id === quizId) {
          return quiz;
        }
      }
    }
    return null;  // Return null if not found
  }

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        setActiveId(active.id);
        setClonedItems(itemsRender);
      }}
      onDragOver={({ active, over }) => {
        const overId = over?.id;

        if (overId == null || overId === TRASH_ID || active.id in itemsRender) {
          return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          setItemsRender((items) => {
            const activeItems = items[activeContainer];
            const overItems = items[overContainer];
            const overIndex = overItems.indexOf(overId);
            const activeIndex = activeItems.indexOf(active.id);

            let newIndex: number;

            if (overId in items) {
              newIndex = overItems.length + 1;
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top >
                over.rect.top + over.rect.height;

              const modifier = isBelowOverItem ? 1 : 0;

              newIndex =
                overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }

            recentlyMovedToNewContainer.current = true;

            return {
              ...items,
              [activeContainer]: items[activeContainer].filter(
                (item) => item !== active.id
              ),
              [overContainer]: [
                ...items[overContainer].slice(0, newIndex),
                items[activeContainer][activeIndex],
                ...items[overContainer].slice(
                  newIndex,
                  items[overContainer].length
                ),
              ],
            };
          });
        }
      }}
      onDragEnd={({ active, over }) => {
        let isChangeContainer = false;
        if (active.id in itemsRender && over?.id) {
          isChangeContainer = true;
          setContainers((containers) => {
            const activeIndex = containers.indexOf(active.id);
            const overIndex = containers.indexOf(over.id);
            const newContainers = arrayMove(containers, activeIndex, overIndex)
            if (activeIndex !== overIndex) {
              handelChangePostionParent(newContainers);
            }
            return newContainers;
          });
        }

        const activeContainer = findContainer(active.id);

        if (!activeContainer) {
          setActiveId(null);
          return;
        }

        const overId = over?.id;

        if (overId == null) {
          setActiveId(null);
          return;
        }

        if (overId === TRASH_ID) {
          setItemsRender((items) => ({
            ...items,
            [activeContainer]: items[activeContainer].filter(
              (id) => id !== activeId
            ),
          }));
          setActiveId(null);
          return;
        }

        if (overId === PLACEHOLDER_ID) {
          const newContainerId = getNextContainerId();

          unstable_batchedUpdates(() => {
            setContainers((containers) => [...containers, newContainerId]);
            setItemsRender((items) => ({
              ...items,
              [activeContainer]: items[activeContainer].filter(
                (id) => id !== activeId
              ),
              [newContainerId]: [active.id],
            }));
            setActiveId(null);
          });
          return;
        }

        const overContainer = findContainer(overId);

        if (overContainer && !isChangeContainer) {
          const activeIndex = itemsRender[activeContainer].indexOf(active.id);
          const overIndex = itemsRender[overContainer].indexOf(overId);

          // if (activeIndex !== overIndex) {
          const newChild = {
            [overContainer]: arrayMove(itemsRender[overContainer], activeIndex, overIndex),
          }
          setItemsRender((itemsRender) => ({
            ...itemsRender,
            [overContainer]: arrayMove(itemsRender[overContainer], activeIndex, overIndex),
          }));

          handelChangePostionChild(newChild);
        }
        setActiveId(null);


      }}
      cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
      modifiers={modifiers}
    >
      <div
        style={{
          width: '100%',
          display: 'inline-grid',
          boxSizing: 'border-box',
          gridAutoFlow: vertical ? 'row' : 'column',
          // padding: '10px',
        }}
      >
        <SortableContext
          items={[...containers, PLACEHOLDER_ID]}
          strategy={
            vertical
              ? verticalListSortingStrategy
              : horizontalListSortingStrategy
          }
        >
          {containers.map((containerId) => {
            const title = dataBase.find((x: UnitQuiz) => {
              return x.groupId === Number(containerId.toString().replace('quiz-', ''))
            })?.title;
            return (
              <DroppableContainer
                collapsible={collapsible}
                key={containerId}
                id={containerId}
                label={minimal ? undefined : `${title}`}
                columns={columns}
                items={itemsRender[containerId]}
                scrollable={scrollable}
                style={containerStyle}
                unstyled={minimal}
                onRemove={() => handleRemove(containerId)}
                handleAddQuestion={(id: number) => {
                  handleAddGroupQuestion(id);
                }}
                handleAddQuestionFromBank={(id: number) => {
                  handleAddQuestionFromBank(id)
                }}
                handleEditNameGroup={(id: number) => {
                  handleEditNameGroup(id);
                }}
                handleDeleteGroup={(id: number) => {
                  handleDeleteGroup(id);
                }}
              >
                <SortableContext items={itemsRender[containerId]} strategy={strategy}>
                  {itemsRender[containerId].map((value, index) => {
                    const child = findQuizById(dataBase, Number(value));
                    let contentChild = convertQuillToString(child?.question);
                    return (
                      <SortableItem
                        activeQuestion={activeQuestion}
                        disabled={isSortingContainer}
                        key={value}
                        id={value}
                        index={index}
                        handle={handle}
                        style={getItemStyles}
                        wrapperStyle={wrapperStyle}
                        renderItem={renderItem}
                        containerId={containerId}
                        getIndex={getIndex}
                        content={contentChild}
                        onClick={(id) => {
                          handleClickChild(id)
                        }}
                        onDropDownClick={(key, id) => {
                          handleClickDropDownChild(key, id)
                        }}
                      />
                    );
                  })}
                </SortableContext>
              </DroppableContainer>
            )
          })}
        </SortableContext>
      </div>
      {createPortal(
        <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation}>
          {activeId
            ? containers.includes(activeId)
              ? renderContainerDragOverlay(activeId)
              : renderSortableItemDragOverlay(activeId)
            : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
}
