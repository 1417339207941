import { Button, Col, Form, Input, Layout, Row, Select } from "antd";
import { PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { useState } from "react";
import PageHeader from "../../../../page-header/PageHeader";
import "./create-question.scss";
import SafeLink from "../../../../link/SafeLink";
import { routesConfig } from "../../../../../config/routes";
import AddQuestionCourseModal from "../../../../modal/add-question-course";
import QuestionItem from "../../../../course-construction/question-item";
const imgPath = "/images/";
const { Content } = Layout;
const { Option } = Select;
type CreateQuestionProps = {
  title: string,
}
const CreateQuestion = ({ title }: CreateQuestionProps) => {
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [addQuestionModal, setAddQuestionModal] = useState<boolean>(false);
  const [courseBlockGroupIdClick, setCourseBlockGroupId] = useState<number>(0);
  const [selectedQuestionType, setSelectedQuestionType] = useState<string | null>(null);
  const handleAddQuestion = () => {
    setAddQuestionModal(true);
    };
    
  const listBreadcrumb = [
    {
      title:"Thư viện",
    },
    {
      title:"Kho đề cá nhân",
    },
    {
      title:
      <SafeLink to={`/${routesConfig.courseLibrary}/${routesConfig.personalExamRepository}/${routesConfig.listQuestionRepository}`}>
        <span>Danh sách câu hỏi</span>
      </SafeLink>
    },
    {
      title:'Thêm câu hỏi',
      
    },
  ]
  return (
    <>
      <Content className='add-question'>
        <PageHeader
          title={title}
          headerGroupButton={true}
          positionItem="column"
          listBreadcrumb={listBreadcrumb}
          breadcrumb ={true}
          arrowLeft={imgPath + 'arrowLeft.svg'}
          childrenGroupButton={
            <div className='button-container'>
              <Button
                type='primary'
                icon={<SaveOutlined />}
                loading={loadingSubmit}
              >
                Lưu
              </Button>
            </div>
          }
        />
        <Row className="body-create-question">
          <Col span={4} style={{ backgroundColor: "#FFF" }}>
            <Form className="body-create-left" layout="vertical">
              <Form.Item
                label="Mã câu hỏi"
                name={"codeQuestion"}
                rules={[{ required: true, message: "Vui lòng nhập mã câu hỏi!" }]}
              >
                <Input placeholder="Nhập mã câu hỏi" />
              </Form.Item>

              <Form.Item
                label="Độ khó"
                name={"difficult"}
                rules={[{ required: true, message: "Vui lòng chọn độ khó!" }]}
              >
                <Select placeholder="Chọn độ khó">
                  <Option value="easy">Dễ</Option>
                  <Option value="medium">Trung bình</Option>
                  <Option value="hard">Khó</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Tác giả"
                name={"author"}
                rules={[{ required: true, message: "Vui lòng chọn tác giả!" }]}
              >
                <Select placeholder="Chọn người dùng">
                  <Option value="author1">Tác giả 1</Option>
                  <Option value="author2">Tác giả 2</Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Chủ đề"
                name={"topic"}
                rules={[{ required: true, message: "Vui lòng chọn chủ đề!" }]}
              >
                <Select placeholder="Chọn chủ đề">
                  <Option value="subject1">Chủ đề 1</Option>
                  <Option value="subject2">Chủ đề 2</Option>
                </Select>
              </Form.Item>

              <Form.Item label="Nhãn">
                <Select placeholder="Thêm nhãn" />
              </Form.Item>

              <Form.Item>
                <Button onClick={handleAddQuestion}>
                  <PlusOutlined /> Chọn loại câu hỏi
                </Button>
              </Form.Item>
            </Form>
          </Col>
          <Col span={16} style={{ backgroundColor: "#grey",marginLeft:'100px'}}>
          {selectedQuestionType && <QuestionItem />}
          </Col>
        </Row>
        <AddQuestionCourseModal 
        courseBlockGroupIdClick={courseBlockGroupIdClick}
        open={addQuestionModal}
        title={"Thêm câu hỏi"}
        closeIcon={true}
        okText={"Xác nhận"}
        cancelText={"Huỷ"}
        icon={null}
        closeModal={() => setAddQuestionModal(false)}
        onSelectQuestionType={(type:any) => setSelectedQuestionType(type)}
        />
      </Content>
    </>
  )
};
export default CreateQuestion;