// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.publish-exam-library .ant-modal-header {
  padding-bottom: 15px;
  border-bottom: 1px solid #F0F0F0; }

.publish-exam-library .ant-modal-body {
  padding: 10px 0 0 30px; }
  .publish-exam-library .ant-modal-body .ant-form-item {
    margin-bottom: 10px; }
  .publish-exam-library .ant-modal-body .date-time-picker {
    display: flex;
    align-items: center;
    justify-content: center; }

.publish-exam-library .dateTime {
  display: flex;
  justify-content: center; }

.publish-exam-library .footer {
  display: flex;
  justify-content: center;
  width: 100%; }
`, "",{"version":3,"sources":["webpack://./src/components/course-library/personal-exam-repository/list-exam/publish-exam/publish-exam.scss"],"names":[],"mappings":"AAAA;EAEQ,oBAAoB;EACpB,gCAAgC,EAAA;;AAHxC;EAMQ,sBAAsB,EAAA;EAN9B;IASY,mBAAmB,EAAA;EAT/B;IAaY,aAAa;IACb,mBAAmB;IACnB,uBAAuB,EAAA;;AAfnC;EAmBQ,aAAa;EACb,uBAAuB,EAAA;;AApB/B;EAuBQ,aAAa;EACb,uBAAuB;EACvB,WAAW,EAAA","sourcesContent":[".publish-exam-library {\n    .ant-modal-header {\n        padding-bottom: 15px;\n        border-bottom: 1px solid #F0F0F0;\n    }\n    .ant-modal-body {\n        padding: 10px 0 0 30px;\n\n        .ant-form-item {\n            margin-bottom: 10px;     \n        }\n        \n        .date-time-picker {\n            display: flex;\n            align-items: center;\n            justify-content: center;\n        }\n    }\n    .dateTime{\n        display: flex;\n        justify-content: center;\n    }\n    .footer{\n        display: flex;\n        justify-content: center;\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
