import React from 'react';
import {Col, Row, Spin, TableColumnsType} from "antd";
import StatisticalReportCard from "../../StatisticalReportCard";
import TableWithTotals from "../../../../../../components/table-with-totals/TableWithTotals";
import Empty from "../../../../../../components/empty";
import {handleExportReport} from "../../../../../../utils/staticReport";
import {
  exportTableCourseByIndustry,
  exportTableCourseFormatByUniversity,
  exportTableReferenceSourceByType,
  exportTableUnitFormatByIndustry,
  exportTableUniversityMultiMediaUnit,
  exportTableUniversityScomXapiUnit,
  exportTableUniversityTestByType
} from "../../../../../../service/statistical-report";
import {BarStackedChartProps, PieChart} from "../../../../../../types/static-report";
import dayjs from "dayjs";
import {staticReportStore} from "../../../../../../stores/stores";
import {createTableData} from "../../../../for-instructors/components/table/tableConvertData";

type Props = {
  loading: boolean,
  courseFormatUniversityList: BarStackedChartProps[],
  courseByIndustry: PieChart[],
  unitByIndustry: PieChart[],
  universityByTestType: PieChart[],
  universityReferenceSource: PieChart[],
  universityScormXapiUnit: PieChart[],
  universityMultiMediaUnit: PieChart[],
}


const ResourceTable = ({
                         loading, courseFormatUniversityList,
                         courseByIndustry,
                         unitByIndustry,
                         universityByTestType,
                         universityReferenceSource,
                         universityScormXapiUnit,
                         universityMultiMediaUnit
                       }: Props) => {
  const {staticReportType, filterValues, tab} = staticReportStore();


  const exportPayload = {
    from: dayjs(filterValues.dateFrom).toISOString(),
    to: dayjs(filterValues.dateTo).toISOString(),
    courseLevelIds: filterValues?.courseLevelIds,
    industryGroupIds: filterValues?.industryGroupIds,
    courseIds: filterValues?.courseIds,
    timeUnit: filterValues.timeUnit,
    universityIds: filterValues?.universityIds,
  };


  const tableColumnCourseUnit: TableColumnsType<any> = [
    {
      title: "Khoa",
      dataIndex: "criteria",
      key: "criteria",
      render: (text: any) => <div className="font-weight-5">{text}</div>,
    },
    {
      title: "Số lượng",
      dataIndex: "count",
      key: "count",
      align: "right"
    },
  ]

  const tableColumnQuantity: TableColumnsType<any> = [
    {
      title: "Định dạng tài liệu",
      dataIndex: "criteria",
      key: "criteria",
      render: (text: any) => <div className="font-weight-5">{text}</div>,
    },
    {
      title: "Số lượng",
      dataIndex: "count",
      key: "count",
      align: "right"
    },
  ]


  const tableColumn: TableColumnsType<any> = [
    {
      title: "Loại đánh giá",
      dataIndex: "criteria",
      key: "criteria",
      render: (text: any) => <div className="font-weight-5">{text}</div>,
    },
    {
      title: "Số lượng",
      dataIndex: "count",
      key: "count",
      align: "right"
    },
  ]


  return <>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê khoá học
        </p>
      </div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng khóa học theo hình thức xuất bản"
            content={
              <Spin spinning={loading}>
                {courseFormatUniversityList.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={createTableData(courseFormatUniversityList).dataSource}
                    columns={[
                      {
                        title: "Trường",
                        dataIndex: "name",
                        key: "name",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                      },
                      ...createTableData(courseFormatUniversityList, 'right').columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                        align: "right"
                      },
                    ]}
                    keyColumn="criteria"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableCourseFormatByUniversity,
                    "Số lượng khoá học theo phân công",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableCourseFormatByUniversity,
                    "Số lượng khoá học theo phân công",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng khóa học theo khoa"
            content={
              <Spin spinning={loading}>
                {courseByIndustry.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={courseByIndustry}
                    columns={tableColumnCourseUnit}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableCourseByIndustry,
                    "Số lượng khóa học theo khoa",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableCourseByIndustry,
                    "Số lượng khóa học theo khoa",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng bài giảng theo khoa"
            content={
              <Spin spinning={loading}>
                {unitByIndustry.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={unitByIndustry}
                    columns={tableColumnCourseUnit}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUnitFormatByIndustry,
                    "Số lượng bài giảng theo khoa",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUnitFormatByIndustry,
                    "Số lượng bài giảng theo khoa",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
      </Row>
    </section>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê số lượng học liệu
        </p>
      </div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng bài kiểm tra"
            content={
              <Spin spinning={loading}>
                {universityByTestType.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={universityByTestType}
                    columns={tableColumn}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUniversityTestByType,
                    "Số lượng bài kiểm tra",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUniversityTestByType,
                    "Số lượng bài kiểm tra",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng tài liệu tham khảo"
            content={
              <Spin spinning={loading}>
                {universityReferenceSource.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={universityReferenceSource}
                    columns={tableColumnQuantity}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableReferenceSourceByType,
                    "Số lượng tài liệu tham khảo",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableReferenceSourceByType,
                    "Số lượng tài liệu tham khảo",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng Scorm & xAPI"
            content={
              <Spin spinning={loading}>
                {universityScormXapiUnit.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={universityScormXapiUnit}
                    columns={tableColumnQuantity}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUniversityScomXapiUnit,
                    "Số lượng Scorm & xAPI",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUniversityScomXapiUnit,
                    "Số lượng Scorm & xAPI",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng học liệu đa phương tiện"
            content={
              <Spin spinning={loading}>
                {universityMultiMediaUnit.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={universityMultiMediaUnit}
                    columns={tableColumnQuantity}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUniversityMultiMediaUnit,
                    "Số lượng học liệu đa phương tiện",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUniversityMultiMediaUnit,
                    "Số lượng học liệu đa phương tiện",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
      </Row>
    </section>
  </>;
};

export default ResourceTable;
