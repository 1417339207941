// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body-create-question {
  border-top: 1px solid #F0F0F0; }
  .body-create-question .body-create-left {
    height: 100vh; }
    .body-create-question .body-create-left .ant-form-item-control-input {
      width: 95%; }
      .body-create-question .body-create-left .ant-form-item-control-input button {
        width: 100%; }
    .body-create-question .body-create-left .ant-form-item {
      padding-left: 20px;
      padding-top: 10px; }
  .body-create-question .question-content-box {
    margin-top: 20px; }
`, "",{"version":3,"sources":["webpack://./src/components/course-library/personal-exam-repository/list-question/create-question/create-question.scss"],"names":[],"mappings":"AAAA;EACI,6BAA6B,EAAA;EADjC;IAGQ,aAAa,EAAA;IAHrB;MAKY,UAAU,EAAA;MALtB;QAOgB,WAAW,EAAA;IAP3B;MAWY,kBAAkB;MAClB,iBAAiB,EAAA;EAZ7B;IAgBQ,gBAAgB,EAAA","sourcesContent":[".body-create-question{\n    border-top: 1px solid #F0F0F0;\n    .body-create-left{\n        height: 100vh;\n        .ant-form-item-control-input{\n            width: 95%;\n            button{\n                width: 100%;\n            }\n        }\n        .ant-form-item{\n            padding-left: 20px;\n            padding-top: 10px;\n        }\n    }\n    .question-content-box{\n        margin-top: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
