import React, { useEffect, useState } from "react";
import AreaStackedChart from "../../../../../../components/types-of-charts/AreaStackedChart";
import { Col, Empty, Row, Select, Spin } from "antd";
import BarStackedChart from "../../../../../../components/types-of-charts/BarStackedChart";
import ComboDualAxesChart from "../../../../../../components/types-of-charts/ComboDualAxesChart";
import ColumnStackedPercentChart from "../../../../../../components/types-of-charts/ColumnStackedPercentChart";
import StatisticalReportCard from "../../StatisticalReportCard";
import { BarStackedChartProps, DualChart, PieChart } from "../../../../../../types/static-report";
import { staticReportStore } from "../../../../../../stores/stores";
import useChartExport from "../../../../for-instructors/components/chart/useChartExport";

type Props = {
  loading: Record<string, boolean>;
  unitDiscussForMinistry: BarStackedChartProps[];
  discussCourseActionForMinistry: BarStackedChartProps[];
  unitReviewAndAccessForMinistry: DualChart[];
  rateUnitBySequenceForMinistry: BarStackedChartProps[];
  rateUnitByCourseForMinistry: BarStackedChartProps[];
  rateUnitByModuleForMinistry: BarStackedChartProps[];
  setModuleGroup: (data: number) => void;
};

const FeedbackAndEvaluationChartTab = (props: Props) => {
  const {
    loading,
    unitDiscussForMinistry,
    discussCourseActionForMinistry,
    unitReviewAndAccessForMinistry,
    rateUnitBySequenceForMinistry,
    rateUnitByCourseForMinistry,
    rateUnitByModuleForMinistry,
    setModuleGroup
  } = props;

  // Hook for managing chart exports
  const { createChartRef, handleExportImage, handleExportAllImagesAsZip } =
    useChartExport();

  const { tab, setHandleExportAllImagesAsZip, exportType, setFilterValues, filterValues } =
    staticReportStore();

  useEffect(() => {
    setHandleExportAllImagesAsZip(() => handleExportAllImagesAsZip(tab));
  }, [tab, exportType]);

  return (
    <div className="feedbackAndEvaluationChartTab">
      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
        <Col span={12} className="chart-card">
          <StatisticalReportCard
            chartName="Số lượng phản hồi theo thời gian"
            content={
              <Spin spinning={loading.unitDiscussForMinistry}>
                {unitDiscussForMinistry.length > 0 ? (
                  <AreaStackedChart
                    data={unitDiscussForMinistry}
                    colors={["#69C0FF", "#5CDBD3", "#FFC069", "#FF7875"]}
                    title=""
                    yAxisTitle="Số lượng phản hồi"
                    hasTotal={true}
                    ref={createChartRef(tab, "Số lượng phản hồi theo thời gian")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Số lượng phản hồi theo thời gian", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(tab, "Số lượng phản hồi theo thời gian", "application/pdf"),
              },
            ]}
          />
        </Col>
        <Col span={12} className="chart-card">
          <StatisticalReportCard
            chartName="Số lượng phản hồi theo bài giảng"
            content={
              <Spin spinning={loading.discussCourseActionForMinistry}>
                {discussCourseActionForMinistry.length > 0 ? (
                  <BarStackedChart
                    data={discussCourseActionForMinistry}
                    title=""
                    showDataLabels={false}
                    colors={["#69C0FF", "#FFC069", "#5CDBD3", "#FF7875"]}
                    ref={createChartRef(tab, "Số lượng phản hồi theo bài giảng")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Số lượng phản hồi theo bài giảng", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(tab, "Số lượng phản hồi theo bài giảng", "application/pdf"),
              },
            ]}
          />
        </Col>
        <Col span={12} className="chart-card">
          <StatisticalReportCard
            chartName="Số lượt đánh giá vs Số lượt hoàn thành học liệu"
            content={
              <Spin spinning={loading.unitReviewAndAccessForMinistry}>
                {unitReviewAndAccessForMinistry.length > 0 ? (
                  <ComboDualAxesChart
                    data={unitReviewAndAccessForMinistry}
                    columnName="Lượt làm đánh giá"
                    columnColor="#69c0ff"
                    lineName="Lượt hoàn thành học liệu"
                    lineColor="#f87572"
                    hasTotal={false}
                    ref={createChartRef(tab, "Số lượt đánh giá vs Số lượt hoàn thành học liệu")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Số lượt đánh giá vs Số lượt hoàn thành học liệu", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(tab, "Số lượt đánh giá vs Số lượt hoàn thành học liệu", "application/pdf"),
              },
            ]}
            moreOption={
              <Select
                className="mr-2"
                defaultValue={3}
                style={{ minWidth: 160 }}
                onSelect={(value) => {
                  setModuleGroup(value);
                }}
                options={[
                  { value: 3, label: 'Bài kiểm tra' },
                  { value: 2, label: 'Tài liệu tham khảo' },
                  { value: 1, label: 'Đa phương tiện' },
                  { value: 4, label: 'SCORM & xAPI' },
                ]}
              />
            }
          />
        </Col>
        <Col span={12} className="chart-card">
          <StatisticalReportCard
            chartName="Tỷ lệ đánh giá khóa học"
            content={
              <Spin spinning={loading.rateUnitByCourseForMinistry}>
                {rateUnitByCourseForMinistry.length > 0 ? (
                  <ColumnStackedPercentChart
                    data={rateUnitByCourseForMinistry}
                    title=""
                    showDataLabels={false}
                    colors={["#ff7875", "#ffc069", "#5cdbd3", "#69c0ff", "#b37feb"]}
                    ref={createChartRef(tab, "Tỷ lệ đánh giá khóa học")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Tỷ lệ đánh giá khóa học", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(tab, "Tỷ lệ đánh giá khóa học", "application/pdf"),
              },
            ]}
          />
        </Col>
        <Col span={12} className="chart-card">
          <StatisticalReportCard
            chartName="Tỷ lệ đánh giá bài giảng"
            content={
              <Spin spinning={loading.rateUnitBySequenceForMinistry}>
                {rateUnitBySequenceForMinistry.length > 0 ? (
                  <ColumnStackedPercentChart
                    data={rateUnitBySequenceForMinistry}
                    title=""
                    showDataLabels={false}
                    colors={["#ff7875", "#ffc069", "#5cdbd3", "#69c0ff", "#b37feb"]}
                    ref={createChartRef(tab, "Tỷ lệ đánh giá bài giảng")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Tỷ lệ đánh giá bài giảng", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(tab, "Tỷ lệ đánh giá bài giảng", "application/pdf"),
              },
            ]}
          />
        </Col>
        <Col span={12} className="chart-card">
          <StatisticalReportCard
            chartName="Tỷ lệ đánh giá học liệu theo phân loại"
            content={
              <Spin spinning={loading.rateUnitByModuleForMinistry}>
                {rateUnitByModuleForMinistry.length > 0 ? (
                  <ColumnStackedPercentChart
                    data={rateUnitByModuleForMinistry}
                    title=""
                    showDataLabels={false}
                    colors={["#ff7875", "#ffc069", "#5cdbd3", "#69c0ff", "#b37feb"]}
                    ref={createChartRef(tab, "Tỷ lệ đánh giá học liệu theo phân loại")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Hình ảnh (PNG)",
                action: () =>
                  handleExportImage(tab, "Tỷ lệ đánh giá học liệu theo phân loại", "image/png"),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportImage(tab, "Tỷ lệ đánh giá học liệu theo phân loại", "application/pdf"),
              },
            ]}
          />
        </Col>
      </Row>
    </div>
  )
};

export default FeedbackAndEvaluationChartTab;
