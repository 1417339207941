import React from 'react';
import DrawerCustom from "../../../../drawer/Drawer";
import {Button, Col, Form, Radio, Row, Space, Typography} from "antd";
import {CloseOutlined, SaveOutlined} from "@ant-design/icons";
import './index.scss'

type EditPermissionsProps = {
  isOpened: boolean
  closeForm: () => void
  onFinish: (value: any) => void
}


const radioItemList = [
  {
    value: 'Công khai',
    title: "Công khai",
    describe: "Nội dung này cần Cấp bộ kiểm duyệt trước khi công khai",
  },
  {
    value: 'Nội bộ',
    title: "Nội bộ",
    describe: "Nội dung này cần QTCS kiểm duyệt trước khi công khai nội",
  },
  {
    value: 'Giới hạn',
    title: "Giới hạn",
    describe: "Chỉ hiển thị nội dung với đối tượng được chia sẻ",
  },
];


const EditPermissions = ({isOpened, closeForm}: EditPermissionsProps) => {

  const [form] = Form.useForm()

  return (
    <>
      <DrawerCustom
        title={"Sửa quyền truy cập"}
        open={isOpened}
        onClose={closeForm}
        extraNode={
          <Space>
            <Button
              onClick={closeForm}
              className="btn btn-outlined"
            >
              <CloseOutlined/>
              <Typography.Text>Hủy</Typography.Text>
            </Button>
            <Button
              className="btn btn-primary"
              htmlType="submit"
              onClick={() => form?.submit()}
            >
              <SaveOutlined/>
              <Typography.Text>Gửi yêu cầu</Typography.Text>
            </Button>
          </Space>
        }
        widthDrawer={736}
      >
        <Form
          layout={"vertical"}
          form={form}
          name="editPermissions"
          initialValues={{permissions: "Công khai"}}
        >
          <Row gutter={16}>
            <Col className="mb-24" span={24}>
              <div className="form-item--info_publish">
                <Typography.Title level={5}>
                  Quyền truy cập
                </Typography.Title>
                <Form.Item
                  name="permissions"
                >
                  <Radio.Group className="options-notification--type">
                    {radioItemList.map((item, index) => (
                      <div key={item.value} className="wrapper-radio">
                        <Radio value={item.value}>
                          <Typography.Text
                            className="setting-chapter-title"
                          >
                            {item.title}
                          </Typography.Text>
                        </Radio>
                        <div className="message">
                          <Typography.Text className="sub-title">
                            {item.describe}
                          </Typography.Text>
                        </div>
                      </div>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </div>

            </Col>
          </Row>
        </Form>
      </DrawerCustom>
    </>
  );
};

export default EditPermissions;