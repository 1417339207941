import React, { FunctionComponent, useEffect, useState } from 'react';
import { GetProp, Pagination, PaginationProps, Table, TableColumnType, TablePaginationConfig, TableProps } from "antd";

import './table-data.scss'
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';
import { FormInstance } from "antd/lib";

type TableDataProps = {
  dataTable?: Array<any>,
  dataColumns?: TableColumnType<any>[],
  totalRecords?: number,
  pageNumber?: number,
  pageSize?: number,
  scroll?: { x: number, y: number },
  loadingTable?: boolean,
  checkEdit?: boolean,
  hideSelection?: boolean,
  pagination?: boolean,
  setSelectedRowKeysProps?: (selectedRow: React.Key[]) => void,
  setPageNumber?: (pageNumber: number) => void,
  setPageSize?: (pageNumber: number) => void,
  handleTableChangeProps: (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) => void,
  form?: FormInstance
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const locale = {
  emptyText: (
    <span>
      <p>
        <img className='image-empty-data' src="/images/empty-img-gray.png" alt="empty-img"></img>
      </p>
      <p className='nodata-text'>Không tìm thấy</p>
    </span>
  ),
  triggerDesc: 'Sắp xếp theo thứ tự Z-A',
  triggerAsc: 'Sắp xếp thứ tự A-Z',
  cancelSort: 'Huỷ sắp xếp'
};

const TableData: FunctionComponent<TableDataProps> = ({
  dataTable,
  dataColumns,
  totalRecords,
  loadingTable,
  checkEdit,
  scroll,
  setPageNumber,
  setPageSize,
  pageNumber,
  handleTableChangeProps,
  pageSize,
  hideSelection,
  pagination,
  setSelectedRowKeysProps,
  form
}) => {

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [totalPage, setTotalPage] = useState(totalRecords)


  useEffect(() => {
    setTotalPage(totalRecords)
  }, [totalRecords]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      if (setSelectedRowKeysProps) {
        setSelectedRowKeysProps(selectedRows)
      }
      if (form) {
        form.setFieldsValue({ "nguoiDungs": selectedRowKeys })
      }
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onChange: PaginationProps['onChange'] = (page, pageSize) => {
    if (setPageNumber && setPageSize) {
      setPageNumber(page);
      setPageSize(pageSize)
      setTotalPage(totalRecords)
    }
  };

  const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
    if (type === 'prev') {
      return <LeftOutlined />;
    }
    if (type === 'next') {
      return <RightOutlined />;
    }
    return originalElement;
  };

  const handleTableChange: TableProps['onChange'] = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) => {
    handleTableChangeProps(pagination, filters, sorter, extra)
  };


  return (
    <div className="table-wrapper">
      <Table size="middle" tableLayout="fixed"
        rowSelection={hideSelection ? { type: 'checkbox', ...rowSelection, columnWidth: 10 } : undefined}
        columns={dataColumns}
        loading={loadingTable}
        className="table-data"
        locale={locale}
        dataSource={dataTable && [...dataTable]} pagination={false} scroll={scroll} rowKey={obj => obj.id}
        onChange={handleTableChange}
      />
      {
        pagination ?
          <Pagination total={totalPage} showSizeChanger
            pageSize={pageSize}
            current={pageNumber}
            className="pagination-table"
            onChange={onChange}
            itemRender={itemRender}
            locale={{
              items_per_page: "/ trang",
              jump_to: 'Đi đến trang',
              page: '',
            }}
            showQuickJumper showTotal={(total) =>
            (
              (selectedRowKeys && selectedRowKeys.length) > 0 ? `Đã chọn ${selectedRowKeys.length}/${total} tài khoản` : `Tổng số ${total} bản ghi`
            )
            } /> : null
      }
    </div>
  );
};

export default TableData;
