import { DownOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd';
import React, { ChangeEvent, KeyboardEvent } from 'react';
import FormInput from '../../../../form-input/FormInput';
import { useLocation, useNavigate } from 'react-router-dom';

type FilterExamTableProps = {
  handleOpen: () => void,
  searchValue: string,
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void,
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void,
  setTypeQuestion: (value: boolean) => void,
  typeQuestion: boolean,
  handleSubmitSearch: () => void,
  handleOpenFilter: () => void,
}


const FilterExamTable = ({
  handleOpen,
  searchValue,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  typeQuestion,
  setTypeQuestion,
}: FilterExamTableProps) => {
  return (
    <>
      <div className="heading-extra">
        <Row gutter={[{ md: 8, xl: 8, xs: 8 }, 8]} wrap={false}>
          <Col md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 7 }}>
            <div className="heading-filter">
              <Button className="filter-button" onClick={handleOpenFilter}>
                <Typography.Text>
                  Bộ lọc
                </Typography.Text>
                <DownOutlined />
              </Button>
            </div>
          </Col>
          {!typeQuestion ? 
          <Col md={{ span: 10 }} lg={{ span: 10 }} xl={{ span: 10 }} xs={{ span: 19 }}>
            <div className="heading-search">
              <FormInput placeholder="Nhập từ khoá cần tìm" value={searchValue}
                onChange={handleChangeInput}
                onKeyPress={handleSearch}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
            </div>
          </Col> :
           <Col md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }} xs={{ span: 19 }}>
            <div className="heading-search">
              <FormInput placeholder="Nhập từ khoá cần tìm"
              value={searchValue}
              onChange={handleChangeInput}
              onKeyPress={handleSearch}
              afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch}
              />}
              />
            </div>
          </Col> }
          {!typeQuestion ?
          <Col md={{ span: 5 }} lg={{ span: 5 }} xl={{ span: 5 }} xs={{ span: 21 }}>
            <div className="heading-resigter">
              <Button onClick={handleOpen} className="btn btn-primary">
                <PlusOutlined />
                <Typography.Text>Thêm đề</Typography.Text>
              </Button>
            </div>
          </Col> :''}
          {!typeQuestion ? 
          <Col md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }} xs={{ span: 21 }}>
          <div className="heading-resigter">
            <Button className="btn" onClick={() =>
              setTypeQuestion(!typeQuestion)}>
              <Typography.Text>
              Đề lưu trữ
              </Typography.Text>
            </Button>
          </div>
        </Col> :
        <Col md={{ span: 7 }} lg={{ span: 7 }} xl={{ span: 7 }} xs={{ span: 21 }}>
        <div className="heading-list">
          <Button className="btn btn-primary" onClick={() =>
              setTypeQuestion(!typeQuestion)}>
            <Typography.Text>
            Danh sách đề
            </Typography.Text>
          </Button>
        </div>
      </Col>
        }
        </Row>
      </div>
    </>
  );
};

export default FilterExamTable;