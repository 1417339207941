import { Breadcrumb, Button, Col, Form, Layout, Row, Space, Typography } from 'antd';
import React, { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routesConfig } from '../../../config/routes';
import FilterBankExam from '../../../components/course-library/bank-exam/filterBankExam/FilterBankExam';
import PageHeader from '../../../components/page-header/PageHeader';
import './bankExam.scss';
import FormInput from '../../../components/form-input/FormInput';
import { SearchOutlined } from '@ant-design/icons';

type BankExamProps = {
}


const BankExam = ({ }: BankExamProps) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(
      `${routesConfig.bankExamList}`,
    )
  };
  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(value);
  };
  const handleSubmitSearch = () => {

  }

  const topicList = [
    {
      title: 'An ninh, quoc phong',
      list: ['Trinh sát an ninh', 'Trinh sát an ninh', 'Biên phòng', 'Trinh sát an ninh', 'Biên phòng', 'Biên phòng', 'Trinh sát an ninh', 'Biên phòng', 'Trinh sát an ninh', 'Biên phòng'],
    },
    {
      title: 'An ninh, quoc phong',
      list: ['Trinh sát an ninh', 'Biên phòng', 'Trinh sát an ninh'],
    },
    {
      title: 'An ninh, quoc phong',
      list: ['Trinh sát an ninh', 'Biên phòng', 'Trinh sát an ninh', 'Biên phòng', 'Trinh sát an ninh', 'Biên phòng', 'Trinh sát an ninh', 'Biên phòng', 'Trinh sát an ninh', 'Biên phòng'],
    },
    {
      title: 'An ninh, quoc phong',
      list: ['Trinh sát an ninh', 'Biên phòng', 'Trinh sát an ninh', 'Biên phòng', 'Trinh sát an ninh', 'Biên phòng', 'Trinh sát an ninh', 'Biên phòng', 'Trinh sát an ninh', 'Biên phòng'],
    },
  ]

  const listBreadcrumb = [
    {
      title: 'Thư viện',
    },
    {
      title: 'Ngân hàng đề',
    },
    {
      title: 'Danh sách chủ đề',
    },
  ]

  return (
    <Layout className="page-header-group bank-exam">
      <Form.Provider>
        <div
          className={`page-header-group--layout`}
        >
          <Breadcrumb items={listBreadcrumb} />
          <div className='table-header--wrapper'>
            <div className='table-header--left' >
              <PageHeader title={"Quản lý ngân hàng đề"} />
            </div>
            <div className="heading-extra">
              <FormInput
                placeholder='Nhập từ khóa cần tìm'
                value={searchValue}
                onChange={handleChangeInput}
                afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />}
              />
            </div>
          </div>
        </div>
        <div className="tabs-heading">
          <div className='tabs-heading-title'>
            <Typography.Text className='tabs-heading-title-text'>Danh mục chủ đề</Typography.Text>
          </div>
          <div className="topic-list">
            <Row gutter={[30, 30]}>
              {topicList.map((topic) => (
                <Col span={12}>
                  <Typography.Text className='topic-title'>{topic.title}</Typography.Text>
                  <div>
                    {topic.list?.map((item, index) => (
                      <Space className='topic-space-button'>
                        <Button
                          key={index} shape="round"
                          onClick={() => handleNavigate()}
                          style={{ color: '#40a9ff', borderColor: '#40a9ff', }}
                        >
                          {item}
                        </Button>
                      </Space>
                    ))}
                  </div>
                </Col>
              ))}
            </Row>
          </div>

        </div>
      </Form.Provider>
    </Layout>

  )
}

export default BankExam;
