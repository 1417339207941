import { AxiosResponse } from "axios";
import { axiosConfigV2 } from "../../../config/api/configApiv2";

export const getAllMinisterialApprove: (data: any) => Promise<AxiosResponse<any>> = (data) => {
    return axiosConfigV2.post(`question-bank/censor/get-all-ministerial-approve`, data)
};

export const quickBrowseApprove: (payload: any) => Promise<AxiosResponse<any>> = (payload) => {
    return axiosConfigV2.put(`question-bank/censor/approve`, payload);
};

