import {EyeOutlined, MoreOutlined} from "@ant-design/icons";
import {Button, Dropdown, Form, Layout, MenuProps, Modal, Space, TableColumnsType, TableProps,} from "antd";
import React, {ChangeEvent, KeyboardEvent, useEffect, useState} from "react";
import PageHeader from "../../../../components/page-header/PageHeader";
import {formatOriginTimev3} from "../../../../utils/converDate";
import TableData from "../../../../components/table-data/TableData";
import FilterExamTable
  from "../../../../components/course-library/personal-exam-repository/list-exam/filter-table-exam";
import FilterGroup from "../../../../components/course-library/personal-exam-repository/list-exam/filter-group";
import {FilterData} from "../../../../components/course-library/personal-exam-repository/list-exam/filter-group/filter";
import CreateExam from "../../../../components/course-library/personal-exam-repository/list-exam/create-exam";
import DetailExam from "../../../../components/course-library/personal-exam-repository/list-exam/detail-exam";
import EditPermission from "../../../../components/course-library/personal-exam-repository/list-exam/edit-permission";
import {
  accessUnitBank,
  censorSendApprove,
  createUnitBank,
  editUnitBank,
  getDataDetailUnitBank,
  getPageUnitBank,
  publishUnitBank,
  removeUnitBank,
  updateUnitBank
} from '../../../../service/library';
import {message} from "antd/lib";
import {getAllValueByFilter} from "../../../../service/category-management/portfolio-value-management";
import {useAuthStore} from "../../../../stores/stores";
import {getAccountUsers} from "../../../../service/list-account-users/listAccountUsersApi";
import {get} from "lodash";
import SchedulePublishModal
  from '../../../../components/course-library/personal-exam-repository/list-exam/publish-exam/publishExam';
import HistoryCensorship
  from "../../../../components/course-library/personal-exam-repository/list-exam/history-censorship";
import SubmitReview from '../../../../components/course-library/personal-exam-repository/list-exam/submit-review';
import {useNavigate} from 'react-router-dom';
import {routesConfig} from '../../../../config/routes';
import dayjs from 'dayjs';

export interface IDataDetail {
  id: number;
  code: string;
  title: string;
  topics: string;
  numberQuestion: number;
  description: string;
  tags: string;
  numberOfUse: number;
  authors: string;
  access: string;
  createdBy: string;
  publishingUnit: string;
  hierarchy: string;
}

export interface IDataHistory {
  id: number;
  code: string;
  name: string;
  description: string;
  status: boolean;
  productUnitId: string;
  productUnitName: string;
  typeProduction: number;
  action: string;
  actionById: number;
  actionByName: string;
  approvedDate: string;
  publishDate: string;
  publishStatus: number;
  createdDate: string;
}

const ListExamPersonal = () => {
  const {user} = useAuthStore()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isOpenedDetail, setIsOpenedDetail] = useState<boolean>(false);
  const [isOpenedPermission, setIsOpenedPermission] = useState<boolean>(false);
  const [isOpenedHistoryCensorship, setIsOpenedHistoryCensorship] =
    useState<boolean>(false);
  const [filtersCleared, setFiltersCleared] = useState(false);

  const [idDetailData, setIdDetailData] = useState<number | null>(null);
  const [idEdit, setIdEdit] = useState<number | null>(null);
  const [idBank, setIdBank] = useState<number | null>(null);

  const [page, setPage] = useState<number>(1);
  const [size, setPageSize] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [searchValue, setSearchValue] = useState<string>("");
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [typeQuestion, setTypeQuestion] = useState<boolean>(false);
  const [fieldSortOrder, setFieldSortOrder] = useState<string>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [dataTable, setDataTable] = useState([]);
  const [dataDetail, setDataDetail] = useState<IDataDetail | null>(null);
  const [dataDetailHistory, setDataDetailHistory] =
    useState<IDataHistory | null>(null);
  const [dataEdit, setDataEdit] = useState<any>({});
  const [statusCode, setStatusCode] = useState();
  const [idPublish, setIdPublish] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitReview, setIsSubmitReview] = useState<boolean>(false);
  const [selectedRecordStatus, setSelectedRecordStatus] = useState<string>("");

  const navigate = useNavigate();

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [dataOptions, setDataOptions] = useState({
    dataTopic: [],
    dataPermission: [],
    dataStatus: [],
    dataUsers: [],
    dataUnit: [],
    dataLevel: [
      {
        id: 3,
        name: "Cấp Bộ",
      },
      {
        id: 2,
        name: "Cấp Trường",
      },
      {
        id: 1,
        name: "Cấp Giảng viên",
      },
    ],
  });

  const [filterData, setFilterData] = useState<FilterData>({
    topic: null,
    permission: null,
    updatedDate: null,
    status: null,
  });

  const fetchPublishData = async (values: any) => {
    const dataReq = {
      id: idPublish,
      publishDate:
        values.select === 3
          ? dayjs(values.date)
            .set("hour", dayjs(values.time).hour())
            .set("minute", dayjs(values.time).minute())
            .set("second", dayjs(values.time).second())
            .toISOString()
          : null,
      scheduleType: values.select,
    };
    try {
      const res = await publishUnitBank(dataReq);
      if (res.status === 200) {
        message.success("Xuất bản đề thành công!");
        fetchDataUnit();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onFinish = (values: any) => {
    setIsModalOpen(false);
    fetchPublishData(values);
  };

  const fetchDataUnit = async () => {
    setLoading(true);
    const dataReq = {
      keyword: searchValue,
      page: page,
      size: size,
      access: filterData.permission ? filterData.permission : [],
      topics: filterData.topic ? [Number(filterData.topic)] : [],
      dateFrom: filterData.updatedDate ? filterData.updatedDate : "",
      publicStatus: filterData.status ? [Number(filterData.status)] : [],
      type: "unit_quiz",
    };
    try {
      const response = await getPageUnitBank(dataReq);
      const {data} = response.data;
      setLoading(false);
      setPageSize(data.size);
      setTotalRecords(data.totalElements);
      setDataTable(data.content);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchDetailData = async (id: number) => {
    try {
      const response = await getDataDetailUnitBank(id);
      const {data} = response.data;
      setDataDetail(data);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchEditData = async (id: number) => {
    try {
      const response = await editUnitBank(id)
      const {data} = response.data
      setDataEdit(data)
    } catch (error) {
    }
  }

  const fetchDataOptions = async () => {
    const dataReq = {page: 1, size: 1000};
    try {
      const response = await Promise.allSettled([
        getAllValueByFilter(dataReq),
        getAccountUsers(dataReq),
      ]);

      // @ts-ignore
      setDataOptions({
        ...dataOptions,
        dataTopic:
          response[0].status === "fulfilled"
            ? response[0].value.data.data.moocPortfolioValueData.map(
              (value: { name: string; portfolio: number }) => ({
                label: value.name,
                code: value.portfolio,
              })
            )
            : [],
        dataUsers:
          response[1].status === "fulfilled"
            ? response[1].value.data.data.data
              .filter(
                (i: { donViCongTac: any }) =>
                  i.donViCongTac === get(user, "donViCongTac", "")
              )
              .map((value: any) => ({
                label: value.fullName,
                code: value.id,
              }))
              .concat({
                label: get(user, "username", ""),
                code: get(user, "id", ""),
              })
            : [],
      });
    } catch (e) {
      console.error(e);
    }
  };

  const deleteUnitBank = async (record: any) => {
    await removeUnitBank(record.id).then((res) => {
      if (res.status === 200) {
        message.success("Đề được xoá thành công");
        fetchDataUnit();
      }
    });
  };

  const createOptions = (record: any) => {
    const options1: MenuProps["items"] = [
      ...(record.publishStatus === 0 ||
      record.publishStatus === 3 ||
      record.publicStatus === 4
        ? [
          {
            key: "0",
            label: "Gửi kiểm duyệt",
          },
        ]
        : []),
      ...(record.publishStatus === 7 || record.publishStatus === 5
        ? [
          {
            key: "1",
            label: "Xuất bản",
          },
        ]
        : []),
      ...(record.publishStatus !== 1 || record.publishStatus !== 2
        ? [
          {
            key: "2",
            label: "Chỉnh sửa",
          },
        ]
        : []),
      {
        key: "3",
        label: "Trình phát đề",
      },
      {
        key: "4",
        label: "Sửa quyền truy cập",
      },
      ...(record.publishStatus !== 0
        ? [
          {
            key: "5",
            label: "Lịch sử kiểm duyệt",
          },
        ]
        : []),
      {
        key: "6",
        label: "Xóa",
      },
    ];
    return options1;
  };

  const convertStatus = (status: number): string => {
    switch (status) {
      case 0:
        return "Bản nháp";
      case 1:
        return "Chờ QTCS duyệt";
      case 2:
        return "Chờ Cấp bộ duyệt";
      case 3:
        return "QTCS không duyệt";
      case 4:
        return "Cấp Bộ không duyệt";
      case 5:
        return "Đã lên lịch";
      case 6:
        return "Đã xuất bản";
      case 7:
        return "Chưa xuất bản";
      case 8:
        return "Đề xuất chỉnh sửa";
      default:
        return "";
    }
  };

  const dataColumns: TableColumnsType<any> = [
    {
      title: "STT",
      dataIndex: "numericalOrder",
      key: "numericalOrder",
      width: 3,
      render: (_: any, record, index: number) => {
        return (
          <span className="table-order-header">{record?.numericalOrder}</span>
        );
      },
    },
    {
      title: "Mã đề",
      dataIndex: "code",
      key: "code",
      width: 3,
    },
    {
      title: "Tiêu đề",
      dataIndex: "question",
      key: "question",
      width: 15,
      sorter: true,
    },
    ...(!typeQuestion
      ? [
        {
          title: "Số câu hỏi",
          dataIndex: "totalQuiz",
          key: "totalQuiz",
          width: 5,
          sorter: true,
        },
      ]
      : [
        {
          title: "Số câu hỏi",
          dataIndex: "numberOfQuestion",
          key: "numberOfQuestion",
          width: 5,
        },
      ]),

    {
      title: "Chủ đề",
      dataIndex: "topic",
      key: "topic",
      width: 5,
    },
    ...(!typeQuestion
      ? [
        {
          title: "Lượt dùng",
          dataIndex: "numberOfUse",
          key: "numberOfUse",
          width: 5,
          sorter: true,
        },
      ]
      : [
        {
          title: "Phân cấp",
          dataIndex: "type_production",
          key: "type_production",
          width: 5,
        },
      ]),
    ...(!typeQuestion
      ? [
        {
          title: "Quyền truy cập",
          dataIndex: "access",
          key: "access",
          width: 5,
        },
      ]
      : [
        {
          title: "Lượt dùng",
          dataIndex: "usage",
          key: "usage",
          width: 5,
        },
      ]),

    ...(!typeQuestion
      ? [
        {
          title: "Trạng thái",
          dataIndex: "publishStatus",
          key: "publishStatus",
          width: 5,
          render: (_: any, record: any) => {
            return convertStatus(record.publishStatus);
          },
        },
      ]
      : [
        {
          title: "Đơn vị xuất bản",
          dataIndex: "production_unit",
          key: "unit",
          width: 10,
        },
      ]),

    {
      title: "Ngày câp nhật",
      dataIndex: "updateDate",
      key: "updateDate",
      sorter: true,
      width: 8,
      render: (_: any, record) => {
        return (
          <>
            {record.updatedDate ? formatOriginTimev3(record.updatedDate) : null}
          </>
        );
      },
    },
    {
      title: "Thao tác",
      dataIndex: "action",
      key: "operation",
      width: 5,
      render: (_, record) => {
        const location = {
          id: `${record.id}`,
        };

        return (
          <>
            <Space>
              <a
                className="action-table"
                onClick={() => {
                  setIsOpenedDetail(true);
                  setIdDetailData(record.id);
                }}
              >
                <EyeOutlined/>
              </a>

              <Dropdown
                menu={{
                  items: createOptions(record),
                  onClick: ({key}) => {
                    if (key === "0") {
                      setIdDetailData(record.id);
                      handleSubmitReview(convertStatus(record.publishStatus));
                    }
                    if (key === "1") {
                      setIdPublish(record.id);
                      setIsModalOpen(true);
                    }
                    if (key === "2") {
                      //  change here
                      setIsOpened(true);
                      setIdEdit(record.id)
                      fetchEditData(record.id);
                    }
                    if (key === "3") {
                      //  change here
                      navigate(
                        `/${routesConfig.personalExamRepository}/${routesConfig.previewExam}?id=${record.id}`,
                        {
                          state: {
                            backUrl: `/${routesConfig.courseLibrary}/${routesConfig.personalExamRepository}/${routesConfig.listExamRepository}`,
                            mode: "0",
                            titleBreadCrumb: "Danh sách ngân hàng đề của tôi",
                          },
                        }
                      );
                    }
                    if (key === "4") {
                      setIsOpenedPermission(true);
                      setIdBank(record.id);
                    }
                    if (key === "5") {
                      setIsOpenedHistoryCensorship(true);
                    }
                    if (key === "6") {
                      Modal.confirm({
                        title: `Xoá đề ${record.question}`,
                        content: `Đề đã xoá sẽ không thể khôi phục. Bạn có chắc chắn muốn xoá đề?`,
                        onOk: () => deleteUnitBank(record),
                        centered: true,
                        okText: "Xác nhận",
                        cancelText: "Huỷ bỏ",
                        footer: (_, {OkBtn, CancelBtn}) => (
                          <>
                            <CancelBtn/>
                            <OkBtn/>
                          </>
                        ),
                      });
                    }
                  },
                  // else if (key === '2')handleCloseTask
                }}
                placement="bottomRight"
              >
                <Button>
                  <MoreOutlined/>
                </Button>
              </Dropdown>
            </Space>
          </>
        );
      },
      fixed: "right",
    },
  ];


  useEffect(() => {
    fetchDataUnit();
  }, [page, size, searchValue, filtersCleared]);

  useEffect(() => {
    if (idDetailData) {
      fetchDetailData(idDetailData);
    }
  }, [idDetailData]);

  useEffect(() => {
    fetchDataOptions();
  }, []);

  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const handleOpen = () => {
    setIsOpened(true);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter: any
  ) => {
    const data =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
          ? "desc"
          : "";
    setStatusOrder(data);
    setFieldSortOrder(sorter.field);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setSearchValue(value);
  };

  const handleSearch = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      if (page === 1) {
        fetchDataUnit();
      } else {
        setPage(1);
      }
    }
  };

  const handleSubmitSearch = () => {
    if (page === 1) {
      fetchDataUnit();
    } else {
      setPage(1);
    }
  };

  const handleSubmitReview = (status: string) => {
    setSelectedRecordStatus(status);
    setIsSubmitReview(true);
  };

  const handleChangeFilter = (value: any, name: string) => {
    setFilterData({...filterData, [name]: value});
  };

  const clearFilter = () => {
    form.resetFields();
    setFilterData({
      ...filterData,
      topic: null,
      status: null,
      updatedDate: null,
      permission: null,
    });
    setFiltersCleared((prev) => !prev);
  };


  const closeForm = () => {
    setDataDetail(null);
    setIsOpened(false);
    setIsOpenedPermission(false);
    setIdDetailData(null);
    setIdBank(null);
    setIdEdit(null);
    form.resetFields();
  };


  const onFinishCreate = async (values: any) => {
    const dataForm = {
      code: values.code,
      authors: values.authors,
      title: values.title,
      topics: values.topics,
      description: values.description,
      tags: values.tags,
    };
    const dataFormUpdate = {
      id: idEdit,
      title: values.title,
      description: values.description,
      code: values.code,
      authors: values.authors,
      topics: values.topics,
      tags: values.tags,
    };
    if (idEdit) {
      try {
        const response = await updateUnitBank(dataFormUpdate);
        const {data, success} = response.data;
        if (success) {
          message.success(" Cập nhật đề thành công");
          closeForm();
          fetchDataUnit();
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        const response = await createUnitBank(dataForm);
        const {success} = response.data;
        if (success) {
          message.success("Thêm mới đề thành công");
          closeForm();
          fetchDataUnit();
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const onFinishEdit = async (values: any) => {
    const dataForm = {
      bankId: idBank,
      access: values.permissions,
    };
    try {
      const response = await accessUnitBank(dataForm);
      if (response.status === 200) {
        message.success("Sửa quyền truy cập thành công");
        closeForm();
        fetchDataUnit();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const closeDetail = () => setIsOpenedDetail(false);
  const closeHistoryCensorship = () => setIsOpenedHistoryCensorship(false);

  const callSubmitReviewApi = async (values: any) => {
    let publishDate = null;

    if (values.scheduleType === 3) {
      publishDate = dayjs(values.date)
        .set("hour", dayjs(values.time).hour())
        .set("minute", dayjs(values.time).minute())
        .set("second", dayjs(values.time).second())
        .toISOString();
    }

    const payload = {
      id: idDetailData,
      access: values.access,
      contentEdit: values.contentEdit,
      publishDate: publishDate,
      scheduleType: values.scheduleType,
      user: null,
    };

    try {
      const res = await censorSendApprove(payload);
      if (res.status === 200) {
        message.success("Gửi kiểm duyệt thành công");
        setIsSubmitReview(false);
      }
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <Layout className="page-header-group">
      <Form.Provider onFormFinish={(name, {values, forms}) => {

        if (name === "createUnitBank") {
          const {createUnitBank} = forms;

          const data = createUnitBank.getFieldsValue(['code', 'authors', 'title', 'topics', 'description', 'tags']);
          onFinishCreate(data);
        }
        if (name === 'filterForm') {
          fetchDataUnit()
        }
        if (name === 'editPermissions') {
          const {editPermissions} = forms;
          const dataEditPermissions = editPermissions.getFieldsValue(['permissions'])
          onFinishEdit(dataEditPermissions)
        }
      }}
      >
        <div
          className={`page-header-group--layout ${
            showFilter ? "open-filter" : ""
          }`}
        >
          <div className="table-header--wrapper">
            <div className="table-header--left">
              {!typeQuestion ? (
                <PageHeader title="Danh sách ngân hàng đề của tôi"/>
              ) : (
                <PageHeader title="Danh sách đề lưu trữ"/>
              )}
            </div>
            <FilterExamTable
              handleSearch={handleSearch}
              handleOpen={handleOpen}
              handleSubmitSearch={handleSubmitSearch}
              handleChangeInput={handleChange}
              handleOpenFilter={handleOpenFilter}
              searchValue={searchValue}
              typeQuestion={typeQuestion}
              setTypeQuestion={setTypeQuestion}
            />
          </div>
          <FilterGroup
            form={form}
            showFilter={showFilter}
            clearFilter={clearFilter}
            setFilterData={handleChangeFilter}
            filterData={filterData}
            dataOptions={dataOptions}
            typeQuestion={typeQuestion}
          />
        </div>
        <div className="tabs-heading">
          <TableData dataColumns={dataColumns}
                     dataTable={dataTable}
                     setPageNumber={setPage}
                     setPageSize={setPageSize}
                     pageNumber={page}
                     pageSize={size}
                     loadingTable={loading}
                     scroll={{x: 1500, y: 600}}
                     totalRecords={totalRecords}
                     setSelectedRowKeysProps={setSelectedRowKeys}
                     pagination={true}
                     handleTableChangeProps={handleTableChange}/>
          <CreateExam isOpened={isOpened} id={idEdit}
                      dataDetail={dataEdit} dataOptions={dataOptions} user={user}
                      closeForm={closeForm}
          />
          <DetailExam isOpened={isOpenedDetail} dataDetail={dataDetail} closeForm={closeDetail}/>
          <HistoryCensorship isOpened={isOpenedHistoryCensorship} dataDetail={dataDetailHistory}
                             closeForm={closeHistoryCensorship}/>
          <EditPermission isOpened={isOpenedPermission} closeForm={closeForm}
                          onFinish={onFinishEdit}/>
          <SubmitReview
            isOpened={isSubmitReview}
            closeForm={() => setIsSubmitReview(false)}
            status={selectedRecordStatus}
            onSubmit={callSubmitReviewApi}
            onFinish={callSubmitReviewApi}
          />
        </div>
      </Form.Provider>
      <SchedulePublishModal
        open={isModalOpen}
        onClose={handleCancel}
        onFinish={onFinish}
      />
    </Layout>
  );
};

export default ListExamPersonal;
