import React, { ChangeEvent, FunctionComponent, KeyboardEvent, useState } from 'react';
import { Button, Col, Dropdown, Input, MenuProps, Modal, Progress, Row, Space, Typography, Upload, UploadProps, message } from 'antd';
import { DownOutlined, LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import FormInput from '../../../../../components/form-input/FormInput';
import './filter-table.scss';
import { useAuthStore } from '../../../../../stores/stores';
import { BASE_URL_V2 } from '../../../../../config/api/configApiv2';

type FilterTableProps = {
  handleOpen: () => void,
  searchValue: string,
  isRendering: boolean,
  handleChangeInput: (e: ChangeEvent<HTMLInputElement>) => void,
  handleSearch: (e: KeyboardEvent<HTMLSpanElement>) => void,
  handleSubmitSearch: () => void,
  handleOpenFilter: () => void,
  exportExcel: () => void,
  handleDownloadFile: () => void,
  importExcel: (file: any) => void,
  setOpenImportUniversityModal: (value: boolean) => void,
}

const FilterTable: FunctionComponent<FilterTableProps> = ({
  handleOpen,
  searchValue,
  isRendering,
  handleChangeInput,
  handleSearch,
  handleSubmitSearch,
  handleOpenFilter,
  exportExcel,
  handleDownloadFile,
  importExcel,
  setOpenImportUniversityModal,
}) => {
  const [fileName, setFileName] = useState<string>("");
  const [progress, setProgress] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(true);
  const [file, setFile] = useState<any>();
  const accessToken = useAuthStore((state) => state.accessToken);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleImportExcel = () => {
    const formData = new FormData();
    formData.append('file', file);
    importExcel(formData);
    setIsModalOpen(false);
    setFileName("");
    setShowProgress(false);
    setIsUploading(true);
  }

  const handleOk = () => {
    Modal.confirm({
      title: `Xác nhận tải dữ liệu`,
      content: ``,
      onOk: () => handleImportExcel(),
      centered: true,
      okText: "Xác nhận",
      cancelText: "Huỷ bỏ",
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
    });
  };

  const handleCancel = () => {
    setFileName("");
    setIsUploading(true);
    setShowProgress(false);
    setIsModalOpen(false);
  };

  const items: MenuProps['items'] = [
    {
      label: 'Tạo mới',
      key: '1',
      onClick: handleOpen,
    },
    {
      label: 'Import dữ liệu',
      key: '2',
      onClick: showModal,
    },
    {
      label: 'Thêm từ CSDL ngành',
      key: '3',
      onClick: () => setOpenImportUniversityModal(true)
    },
    {
      label: 'Tải file mẫu',
      key: '4',
      onClick: handleDownloadFile,
    },
  ];

  const menuProps = {
    items,
  };

  const props: UploadProps = {
    name: 'file',
    action: BASE_URL_V2 + `/mooc-course-block-quiz/upload-file`,
    headers: {
      Authorization: "Bearer " + accessToken,
    },
    beforeUpload: (file) => {
      if (!isCorrectFile(file)) {
        message.error("Bạn phải tải lên file excel !");
        return Upload.LIST_IGNORE;
      }
    },
    onChange(info) {
      setProgress(0);
      setFileName(info.file.name);
      setShowProgress(true);
      setIsUploading(true);
      if (info.file.status === "uploading") {

      }
      if (info.file.status === "done") {
        setFile(info.file.originFileObj);
        setProgress(Math.round(100));
        setIsUploading(false);
      }
    },
  };

  const isCorrectFile = (file: any) => {
    const acceptFile = [
      // excel
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    const isJpgOrPng = acceptFile.includes(file.type);
    // const isCorrectFile = file.size < MAX_FILE_UPLOAD_SIZE;
    if (isJpgOrPng) {
      return true;
    }
    return false;
  }

  const handleExportExcel = () => {
    Modal.confirm({
      title: `Xác nhận kết xuất danh sách trường đại học`,
      content: ``,
      onOk: () => exportExcel(),
      centered: true,
      okText: "Xác nhận",
      cancelText: "Huỷ bỏ",
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn />
        </>
      ),
    });
  }

  return (
    <>
      <div className="filter-table-university">
        <div className="heading-item heading-filter">
          <Button className="filter-button" onClick={handleOpenFilter}>
            <Typography.Text> Bộ lọc </Typography.Text>
            <DownOutlined />
          </Button>
        </div>
        <div className="heading-item heading-search">
          <FormInput placeholder="Nhập tên để tìm kiếm" value={searchValue}
            onChange={handleChangeInput} onKeyPress={handleSearch}
            afterPrefixIcon={<SearchOutlined onClick={handleSubmitSearch} />} />
        </div>
        <div className="heading-item heading-resigter">
          <Button className="btn btn-primary" onClick={handleExportExcel} disabled={isRendering}>
            <Typography.Text>{isRendering ? <LoadingOutlined /> : ''} Kết xuất</Typography.Text>
          </Button>
        </div>
        <div className="heading-item heading-resigter">
          <Dropdown menu={menuProps}>
            <Button className='btn-add-new'>
              <Space> Thêm trường <DownOutlined /> </Space>
            </Button>
          </Dropdown>
        </div>

      </div>
      <Modal className="university-import-modal" title="Import cơ sở đào tạo" open={isModalOpen} onOk={handleOk}
        onCancel={handleCancel} okText="Tải lên" okButtonProps={{ disabled: isUploading }} cancelText="Hủy">
        <Space.Compact>
          <Input className="name-label" placeholder="Chọn file từ máy tính" value={fileName} readOnly />
          <Upload showUploadList={false} {...props} maxCount={1}>
            <Button className="btn-primary">Chọn tệp</Button>
          </Upload>
        </Space.Compact>
        <div className={`upload-progress ${showProgress ? "progress-on" : "progress-off"}`}>
          <Typography.Text className="progress-label">
            {progress !== 100 ? 'Đang tải lên' : 'Đã tải lên'}({progress}%)
          </Typography.Text>
          <Progress percent={progress} showInfo={false} />
        </div>
      </Modal>
    </>
  );
};

export default FilterTable;
