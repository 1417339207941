import { Button, Col, DatePicker, message, Modal, Radio, RadioChangeEvent, Row, Space, Tabs, TabsProps, TimePicker, Typography } from "antd";
import React, { ReactNode, useState } from "react";
import "./index.scss";
import { FilePdfOutlined, FontColorsOutlined } from "@ant-design/icons";
import { Dayjs } from "dayjs";
import { viLocale } from "../../../utils/pickerType";
import { postPublishQuestion } from "../../../service/library/question-list";
import { formatDateTime } from "../../../utils/converDate";

type Props = {
  open: boolean;
  closeModal: () => void;
  idDetailData: number | undefined
  getListData: () => void
};

export default function PublishQuestionModal(props: Props) {
  const [value, setValue] = useState(2);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [startTime, setStartTime] = useState<Dayjs | null>(null);

  const {
    open,
    closeModal,
    idDetailData,
    getListData
  } = props;

  const onOk = async () => {
    const data = {
      id: idDetailData,
      publishDate: value === 2 ? null : formatDateTime(startDate, startTime),
      scheduleType: value
    }
    await postPublishQuestion(data).then((res) => {
      if (res.status === 200) {
        message.success("Xuất bản thành công")
        clearData()
      }
    })
  };

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const handleChangeStartDate = (date: any) => {
    setStartDate(date);
    setStartTime(date);
  };

  const handleChangeStartTime = (time: any | null) => {
    setStartTime(time);
  };

  const clearData = () => {
    setValue(1)
    setStartDate(null)
    setStartTime(null)
    closeModal()
  };

  return (
    <Modal
      className="add-lession-modal"
      title="Xuất bản nội dung"
      closeIcon={true}
      centered
      open={open}
      okText={"Xuất bản"}
      cancelText={"Huỷ"}
      onOk={onOk}
      onCancel={clearData}
    >
      <Row gutter={16}>
        <Col
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <Radio.Group onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio value={2}>Xuất bản ngay</Radio>
              <Radio value={3}>Hẹn lịch</Radio>
            </Space>
          </Radio.Group>
          {
            value === 3 ?
              <div className="time-picker flex mt-1">
                <div className="time-picker-content">
                  <DatePicker
                    onChange={handleChangeStartDate}
                    locale={viLocale}
                    format={"DD/MM/YYYY"}
                    value={startDate}
                  />
                </div>
                <div className="time-picker-content ml-4">
                  <TimePicker
                    locale={viLocale}
                    value={startTime}
                    onChange={handleChangeStartTime}
                    format={"HH:mm:ss"}
                  />
                </div>
              </div> : null
          }
        </Col>
      </Row>
    </Modal >
  );
}
