import React, {useState} from 'react';
import {Col, Empty, Row, Spin, TableColumnsType} from "antd";
import './index.scss'
import {BarStackedChartProps, DualChart, PieChart} from "../../../../../../types/static-report";
import TableWithTotals from "../../../../../../components/table-with-totals/TableWithTotals";
import {handleExportReport} from "../../../../../../utils/staticReport";
import dayjs from "dayjs";
import {staticReportStore} from "../../../../../../stores/stores";
import {
  exportTableCourseByUniversitySponsor,
  exportTableStudentIndustryMinistry,
  exportTableTeacherIndustryMinistry,
  exportTableUnitRecourses,
  exportTableUniversityRelation,
  exportTableVisitorMinistry,
  exportTableVisitsMinistry
} from "../../../../../../service/statistical-report";
import StatisticalReportCard from "../../StatisticalReportCard";
import {createTableData} from "../../../../for-instructors/components/table/tableConvertData";
import {formatDateV2} from '../../../../../../utils/converDate';
import {isNull} from "lodash";


type Props = {
  loading: boolean;
  visitsAndVisitors: DualChart[];
  visitsAndVisitorsByRegister: PieChart[];
  universityRelationList: Array<any>;
  adminVisits: Array<any>;
  adminVisitors: Array<any>;
  teacherByUniversity: BarStackedChartProps[];
  studentByUniversity: BarStackedChartProps[];
  courseByUniversitySponsor: BarStackedChartProps[];
  unitResources: Array<any>;
  unitResourceTable: Array<any>;
};

const OverviewTable = ({
                         loading,
                         visitsAndVisitors,
                         visitsAndVisitorsByRegister,
                         universityRelationList,
                         adminVisits,
                         adminVisitors,
                         teacherByUniversity,
                         studentByUniversity,
                         courseByUniversitySponsor,
                         unitResources,
                         unitResourceTable
                       }: Props) => {
  const {staticReportType, filterValues, tab} = staticReportStore();

  const [statusOrder, setStatusOrder] = useState("")
  const [fieldSortOrder, setFieldSortOrder] = useState("")


  const exportPayload = {
    from: dayjs(filterValues.dateFrom).toISOString(),
    to: dayjs(filterValues.dateTo).toISOString(),
    courseLevelIds: filterValues?.courseLevelIds,
    industryGroupIds: filterValues?.industryGroupIds,
    courseIds: filterValues?.courseIds,
    timeUnit: filterValues.timeUnit,
    universityIds: filterValues?.universityIds,
  };


  const tableColumn: TableColumnsType<any> = [
    {
      title: "Tỉnh/TP",
      dataIndex: "criteria",
      key: "criteria",
      render: (text: any) => <div className="font-weight-5">{text}</div>,
    },
    {
      title: "Số lượng",
      align: 'right',
      dataIndex: "count", key: "count"
    },
  ]


  return <>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê lượt truy cập
        </p>
      </div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượt truy cập"
            content={
              <Spin spinning={loading}>
                {adminVisits.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={createTableData(adminVisits).dataSource}
                    columns={[
                      {
                        title: "Ngày",
                        dataIndex: "name",
                        key: "name",
                        render: (text: any) => <div className="font-weight-5">{formatDateV2(text)}</div>,
                      },
                      ...createTableData(adminVisits, 'right').columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                        align: "right"
                      },
                    ]}
                    keyColumn="criteria"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableVisitsMinistry,
                    "Số lượt truy cập",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableVisitsMinistry,
                    "Số lượt truy cập",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số người truy cập"
            content={
              <Spin spinning={loading}>
                {!isNull(adminVisitors) && adminVisitors.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={createTableData(adminVisitors).dataSource}
                    columns={[
                      {
                        title: "Ngày",
                        dataIndex: "name",
                        key: "name",
                        render: (text: any) => <div className="font-weight-5">{formatDateV2(text)}</div>,
                      },
                      ...createTableData(adminVisitors, 'right').columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                        align: "right"
                      },
                    ]}
                    keyColumn="criteria"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableVisitorMinistry,
                    "Số người truy cập",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableVisitorMinistry,
                    "Số người truy cập",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />

        </Col>
      </Row>
    </section>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê trường - giảng viên - học viên - khóa học
        </p>
      </div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 6}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng trường liên kết"
            content={
              <Spin spinning={loading}>
                {universityRelationList.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={universityRelationList}
                    columns={tableColumn}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUniversityRelation,
                    "Số lượng trường liên kết",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUniversityRelation,
                    "Số lượng trường liên kết",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 18}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng giảng viên"
            content={
              <Spin spinning={loading}>
                {teacherByUniversity.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={createTableData(teacherByUniversity).dataSource}
                    columns={[
                      {
                        title: "Trường",
                        dataIndex: "name",
                        key: "name",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                      },
                      ...createTableData(teacherByUniversity, 'right').columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",
                        align: "right",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                      },
                    ]}
                    keyColumn="criteria"
                    // height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableTeacherIndustryMinistry,
                    "Số lượng giảng viên",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableTeacherIndustryMinistry,
                    "Số lượng giảng viên",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
      </Row>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng học viên"
            content={
              <Spin spinning={loading}>
                {studentByUniversity.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={createTableData(studentByUniversity).dataSource}
                    columns={[
                      {
                        title: "Trường",
                        dataIndex: "name",
                        key: "name",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                      },
                      ...createTableData(studentByUniversity, 'right').columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                        align: "right"
                      },
                    ]}
                    keyColumn="criteria"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableStudentIndustryMinistry,
                    "Số lượng học viên",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableStudentIndustryMinistry,
                    "Số lượng học viên",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
        <Col sm={{span: 12}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng khoá học theo phân công"
            content={
              <Spin spinning={loading}>
                {courseByUniversitySponsor.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={createTableData(courseByUniversitySponsor).dataSource}
                    columns={[
                      {
                        title: "Trường liên kết",
                        dataIndex: "name",
                        key: "name",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,

                      },
                      ...createTableData(courseByUniversitySponsor, 'right').columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                        align: "right"
                      },
                    ]}
                    keyColumn="criteria"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableCourseByUniversitySponsor,
                    "Số lượng khoá học theo phân công",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableCourseByUniversitySponsor,
                    "Số lượng khoá học theo phân công",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
      </Row>
    </section>
    <section className="section-table--chart">
      <div className="table-chart--label">
        <p className="text">
          Thống kê tài nguyên
        </p>
      </div>
      <Row gutter={{xs: 8, sm: 8, md: 16, lg: 16}}>
        <Col sm={{span: 24}} xs={{span: 24}}>
          <StatisticalReportCard
            chartName="Số lượng tài nguyên"
            content={
              <Spin spinning={loading}>
                {unitResourceTable?.length > 0 ? (
                  <TableWithTotals
                    totalLabel={"Tổng"}
                    data={createTableData(unitResourceTable).dataSource}
                    columns={[
                      {
                        title: "Trường",
                        dataIndex: "name",
                        key: "name",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                      },
                      ...createTableData(unitResourceTable, 'right').columns,
                      {
                        title: "Tổng",
                        dataIndex: "Tổng",
                        key: "Tổng",
                        render: (text: any) => <div className="font-weight-5">{text}</div>,
                        align: "right"
                      },
                    ]}
                    keyColumn="type"
                    height={275}
                  />
                ) : (
                  <Empty description="Không có dữ liệu"/>
                )}
              </Spin>
            }
            exportOptions={[
              {
                label: "Excel",
                action: () =>
                  handleExportReport(
                    exportTableUnitRecourses,
                    "Số lượng tài nguyên",
                    "excel",
                    exportPayload
                  ),
              },
              {
                label: "PDF",
                action: () =>
                  handleExportReport(
                    exportTableUnitRecourses,
                    "Số lượng tài nguyên",
                    "pdf",
                    exportPayload
                  ),
              },
            ]}
          />
        </Col>
      </Row>
    </section>
  </>;
};

export default OverviewTable;
