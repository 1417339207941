import {DatePicker, Form, Layout, Modal, Select, Tabs} from "antd";
import PageHeader from "../../../components/page-header/PageHeader";
import {FormInstance} from "antd/lib";
import React, {useEffect, useState} from "react";
import FilterTable from "./components/filter-table/FilterTable";
import FormFilter from "./components/filter-group/FormFilter";
import "./index.scss";
import {AppstoreOutlined, BarChartOutlined} from "@ant-design/icons";
import {
  exportAllTabBehaviorMinistry,
  exportAllTabOverviewMinistry,
  exportAllTabResourceMinistry,
  exportAllTabReviewMinistry,
  getCourseFilterMinistry,
  getCourseLevel,
  getIndustryGroupMinistry,
  getUniversityEnrollList
} from "../../../service/statistical-report";
import {staticReportStore} from "../../../stores/stores";
import dayjs from "dayjs";
import OverviewTab from "./components/tab/OverviewTab";
import ResourceTab from "./components/tab/ResourceTab";
import FeedbackAndEvaluationTab from "./components/tab/FeedbackAndEvaluationTab";
import UserBehaviorTab from "./components/tab/UserBehaviorTab";

type Props = {
  title: string;
};
const {Option} = Select;
const StatisticalReportForMinistry = (props: Props) => {
  const {title} = props;
  const [form] = Form.useForm();

  const [filterData, setFilterData] = useState({
    university: null,
    level: null,
    unit: null,
    course: null,
  });
  const {tab, setTab, callExportAllImages, exportType, setExportType} = staticReportStore();
  const [isRendering, setIsRendering] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [dataType, setDataType] = useState<string>("1");
  const {filterValues, setFilterValues} = staticReportStore();
  const [selectionTime, setSelectionTime] = useState("");
  const [isDisplayTime, setIsDisplayTime] = useState<boolean>(false);
  const [lastSelectedTime, setLastSelectedTime] = useState("");
  const [dataOptions, setDataOptions] = useState({
    dataUniversity: [],
    dataLevel: [],
    dataUnit: [],
    dataCourse: [],
  });
  const handlFromDateChange = (date: any) => {
    setFromDate(date);
    if (toDate && date > toDate) {
      setToDate(date);
    }
  };
  const handleToDateChange = (date: any) => {
    setToDate(date);
  };

  const items = [
    {
      value: "7day",
      label: "7 ngày trước",
    },
    {
      value: "1month",
      label: "1 tháng trước",
    },
    {
      value: "3month",
      label: "3 tháng gần đây",
    },
    {
      value: "option",
      label: "Tùy chọn",
    },
  ];

  const handleMenuClick = (value: any) => {
    let dateFrom, dateTo = dayjs();
    let timeUnit = "";

    if (value === "option") {
      setIsModalVisible(true);
    } else {
      setIsModalVisible(false);
      setIsDisplayTime(false);
      setLastSelectedTime(value);
      switch (value) {
        case "7day":
          dateFrom = dayjs().subtract(7, "day");
          timeUnit = "day";
          break;
        case "1month":
          dateFrom = dayjs().subtract(1, "month");
          timeUnit = "week";
          break;
        case "3month":
          dateFrom = dayjs().subtract(3, "month");
          timeUnit = "month";
          break;
        default:
          dateFrom = dayjs().subtract(7, "day");
      }

      setFilterValues({
        timeUnit: timeUnit,
        dateFrom: dateFrom,
        dateTo: dateTo,
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  }

  const handleOk = () => {
    setIsDisplayTime(true);
    form.submit();
    setIsModalVisible(false);
  }

  // 1: chart
  // 2: table
  const tabItems = [
    {
      label: "Tổng quan",
      key: "1",
      children: <OverviewTab dataType={dataType}/>,
    },
    {
      label: "Tài nguyên",
      key: "2",
      children: <ResourceTab dataType={dataType}/>,
    },
    {
      label: "Phản hồi & đánh giá",
      key: "3",
      children: <FeedbackAndEvaluationTab dataType={dataType}/>,
    },
    {
      label: "Hành vi người dùng",
      key: "4",
      children: <UserBehaviorTab dataType={dataType}/>,
    },
  ];

  const tabItemsTimeFilter = [
    {
      label: <BarChartOutlined/>,
      key: "1",
    },
    {
      label: <AppstoreOutlined/>,
      key: "2",
    },
  ];

  const handleChangeType = (e: any) => {
    setDataType(e);
  }

  const dropdownMenu = (
    <>
      <div style={{alignSelf: "center", color: "#1677FF", display: isDisplayTime ? "" : "none"}}>{selectionTime}</div>
      <Select
        defaultValue={"7day"}
        style={{minWidth: 150, alignSelf: "center"}}
        onSelect={(value) => {
          handleMenuClick(value)
        }}
        options={items}
        variant="borderless"
      />
      <Tabs
        className="tabs-time-filter"
        defaultActiveKey="1"
        items={tabItemsTimeFilter}
        onChange={handleChangeType}
        centered={true}
      />
    </>
  );

  const onChangeTab = (key: string) => {
    setTab(key);
  };

  // Filter Search
  const handleOpenFilter = () => {
    setShowFilter((showFilter) => !showFilter);
  };

  const clearFilter = (form: FormInstance) => {
    form.resetFields();
    setFilterValues({
      universityIds: [],
      courseLevelIds: [],
      industryGroupIds: [],
      courseIds: [],
      // timeUnit: "day",
      // dateFrom: dayjs().subtract(7, "day"),
      // dateTo: dayjs(),
    });
  };

  const handleFinish = (values: any) => {
    setFilterValues({
      ...filterValues,
      universityIds: values.university ? values.university : [],
      courseLevelIds: values.level ? values.level : [],
      industryGroupIds: values.unit ? values.unit : [],
      courseIds: values.course ? values.course : [],
    })
  };

  useEffect(() => {
    const reqData = {
      keyword: "",
      page: 1,
      size: 10,
      sort: [],
    }
    Promise.all([getUniversityEnrollList(), getIndustryGroupMinistry(), getCourseFilterMinistry(), getCourseLevel()]).then((res) => {
      const dataUniversityEnrollList = res[0].data.data;
      const dataIndustryList = res[1].data.data;
      const dataCourseList = res[2].data.data;
      const dataCourseLevelList = res[3].data.data;
      setDataOptions({
        ...dataOptions,
        dataUniversity: dataUniversityEnrollList,
        dataUnit: dataIndustryList,
        dataCourse: dataCourseList,
        dataLevel: dataCourseLevelList,
      })
    })
  }, [])

  const handleFinishTimeOptionForm = (values: any) => {
    if (Object.keys(values).length > 0) {
      setFilterValues({
        ...filterValues,
        timeUnit: values.unitTime ? values.unitTime : "day",
        dateFrom: values.dateFrom,
        dateTo: values.dateTo,
      })
    }
  }

  const handleExportAll = async (type: string) => {
    if (dataType === "1") {
      await callExportAllImages(tab);
    } else {
      const payloads = {
        from: dayjs(filterValues.dateFrom).toISOString(),
        to: dayjs(filterValues.dateTo).toISOString(),
        courseLevelIds: filterValues?.courseLevelIds,
        industryGroupIds: filterValues?.industryGroupIds,
        courseIds: filterValues?.courseIds,
        timeUnit: filterValues?.timeUnit,
        exportType: type === "excel" ? "excel" : "pdf",
      };
      try {
        setIsRendering(true);
        const exportApiUrl =
          tab === "1"
            ? exportAllTabOverviewMinistry({...payloads})
            : tab === "2"
              ? exportAllTabResourceMinistry({...payloads})
              : tab === "3"
                ? exportAllTabReviewMinistry({
                  ...payloads,
                  moduleGroup: filterValues?.moduleGroup,
                  classId: filterValues?.classId,
                })
                : tab === "4"
                  ? exportAllTabBehaviorMinistry({
                    ...payloads,
                    courseStructureType:
                    filterValues?.searchKeywordCourseStructureType,
                    moduleGroup: filterValues?.searchKeywordModuleGroup,
                    downloadModuleGroup: filterValues?.downloadModuleGroup,
                    downloadCourseStructureType:
                    filterValues?.downloadCourseStructureType,
                    viewModuleGroup: filterValues?.viewModuleGroup,
                    viewCourseStructureType: filterValues?.viewCourseStructureType,
                    shareModuleGroup: filterValues?.shareModuleGroup,
                    shareCourseStructureType:
                    filterValues?.shareCourseStructureType,
                  })
                  : exportAllTabOverviewMinistry({...payloads});

        const res = await exportApiUrl;
        const fileName =
          tab === "1"
            ? `Tong-quan.${exportType}`
            : tab === "2"
              ? `Tai-nguyen.${exportType}`
              : tab === "3"
                ? `Phan-hoi-danh-gia.${exportType}`
                : tab === "4"
                  ? `Hanh-vi-nguoi-dung.${exportType}`
                  : "excel.${type}";
        const fileData = new Blob([res.data], {
          type:
            type === "excel"
              ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              : "application/pdf",
        });

        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(fileData);
        downloadLink.download = fileName;

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } catch (error) {
        console.error("Error downloading Excel file:", error);
      } finally {
        setIsRendering(false);
      }
    }
  };

  useEffect(() => {
    const timeUnitLabel =
      filterValues.timeUnit === "day"
        ? "Biểu đồ ngày"
        : filterValues.timeUnit === "week"
          ? "Biểu đồ tuần"
          : filterValues.timeUnit === "month"
            ? "Biểu đồ tháng"
            : filterValues.timeUnit === "year"
              ? "Biểu đồ năm"
              : "Chọn tùy chọn thời gian";

    const dateFrom = filterValues.dateFrom
      ? dayjs(filterValues.dateFrom).format("DD/MM/YYYY")
      : "";
    const dateTo = filterValues.dateTo
      ? dayjs(filterValues.dateTo).format("DD/MM/YYYY")
      : "";

    setSelectionTime(`${timeUnitLabel} - từ ${dateFrom} đến ${dateTo}`);
  }, [filterValues]);

  return (
    <Layout className="page-header-group statistical-report-for-ministry">
      <Form.Provider onFormFinish={(name, {values, forms}) => {
      }}>
        <div className={`page-header-group--layout ${showFilter ? "open-filter" : ""}`}>
          <div className="table-header--wrapper">
            <div className="table-header--left">
              <PageHeader title={title}/>
            </div>
            <FilterTable
              handleOpenFilter={handleOpenFilter}
              isRendering={isRendering}
              setExportType={setExportType}
              reportType={dataType}
              handleExportAll={handleExportAll}
            />
          </div>
          <FormFilter
            clearFilter={clearFilter}
            dataOptions={dataOptions}
            onFinish={handleFinish}
            showFilter={showFilter}
          />
        </div>
        <div className="chart-table-content-group">
          <Tabs
            defaultActiveKey="1"
            items={tabItems}
            onChange={onChangeTab}
            tabBarExtraContent={
              <div className="filter-button-group">
                {dropdownMenu}
              </div>
            }
          />
        </div>
        <Modal
          title="Tùy chọn"
          open={isModalVisible}
          onCancel={handleCancel}
          okText="Xác nhận"
          cancelText="Huỷ"
          onOk={handleOk}
          okButtonProps={{
            disabled: !fromDate || !toDate,
          }}
        >
          <Form name="time-option-form" labelCol={{span: 6}} onFinish={handleFinishTimeOptionForm} form={form}>
            <Form.Item name="unitTime" label="Loại biểu đồ">
              <Select defaultValue="day" style={{width: '100%'}}>
                <Option value="day">Biểu đồ ngày</Option>
                <Option value="week">Biểu đồ tuần</Option>
                <Option value="month">Biểu đồ tháng</Option>
                <Option value="year">Biểu đồ năm</Option>
              </Select>
            </Form.Item>
            <Form.Item name="dateFrom" label="Từ ngày">
              <DatePicker style={{width: '100%'}} format="DD/MM/YYYY" placeholder="Chọn ngày" value={fromDate}
                          onChange={handlFromDateChange}/>
            </Form.Item>
            <Form.Item name="dateTo" label="Đến ngày">
              <DatePicker style={{width: '100%'}} format="DD/MM/YYYY" placeholder="Chọn ngày" value={toDate}
                          onChange={handleToDateChange}
                          disabledDate={(current: any) => current && fromDate && current < fromDate}/>
            </Form.Item>
          </Form>
        </Modal>
      </Form.Provider>
    </Layout>
  );
};

export default StatisticalReportForMinistry;
