import {
  AndroidOutlined,
  ApartmentOutlined,
  AuditOutlined,
  BarChartOutlined,
  BellOutlined,
  BookOutlined,
  BulbOutlined,
  ControlOutlined,
  FolderOpenOutlined,
  FormOutlined,
  FundOutlined,
  GlobalOutlined,
  LayoutOutlined,
  ReadOutlined,
  ReconciliationOutlined,
  ScheduleOutlined,
  SettingFilled,
  SettingOutlined,
  SnippetsOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Key, ReactNode } from "react";
import SafeLink from "../../components/link/SafeLink";
import { routesConfig } from "../../config/routes";
import { MenuItem } from "../../types/sidebar";
import { Permission } from "./permission.enum";

function getItem(
  label: ReactNode,
  key: Key,
  icon?: ReactNode,
  children?: MenuItem[],
  type?: string,
  permission?: string[]
): MenuItem {
  return {
    key,
    label,
    icon,
    children,
    type,
    permission,
  } as MenuItem;
}

export const menuItems: MenuItem[] = [
  getItem(
    "Quản lý tài khoản",
    routesConfig.accountSystem,
    <TeamOutlined />,
    [
      getItem(
        <SafeLink
          to={`/${routesConfig.accountSystem}/${routesConfig.schoolManagementParticipates}`}
        >
          Trường tham gia nền tảng
        </SafeLink>,
        `/${routesConfig.accountSystem}/${routesConfig.schoolManagementParticipates}`,
        undefined,
        undefined,
        undefined,
        [Permission.ProcessRequestUpdate]
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.accountSystem}/${routesConfig.listUsers}`}
        >
          Tài khoản quản trị cơ sở
        </SafeLink>,
        `/${routesConfig.accountSystem}/${routesConfig.listUsers}`,
        undefined,
        undefined,
        undefined,
        [
          Permission.LockUnlockFAAccountUpdate,
          Permission.ChangeFAAccountUpdate,
          Permission.DeleteFAAccountUpdate,
          Permission.CreateFAAccountUpdate,
        ]
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.accountSystem}/${routesConfig.listTeachers}`}
        >
          Tài khoản giảng viên
        </SafeLink>,
        `/${routesConfig.accountSystem}/${routesConfig.listTeachers}`,
        undefined,
        undefined,
        undefined,
        [
          Permission.LockUnlockLecturerAccountUpdate,
          Permission.ChangeLecturerAccountUpdate,
          Permission.DeleteLecturerAccountUpdate,
          Permission.CreateLecturerAccountUpdate,
        ]
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.accountSystem}/${routesConfig.listMinistryLevels}`}
        >
          Tài khoản người dùng cấp bộ
        </SafeLink>,
        `/${routesConfig.accountSystem}/${routesConfig.listMinistryLevels}`,
        undefined,
        undefined,
        undefined,
        [
          Permission.LockUnlockMUAccountUpdate,
          Permission.ChangeMUAccountUpdate,
          Permission.DeleteMUAccountUpdate,
          Permission.CreateMUAccountUpdate,
        ]
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.accountSystem}/${routesConfig.listStudents}`}
        >
          Tài khoản sinh viên
        </SafeLink>,
        `/${routesConfig.accountSystem}/${routesConfig.listStudents}`,
        undefined,
        undefined,
        undefined,
        [
          Permission.LockUnlockStudentAccountUpdate,
          Permission.ChangeStudentAccountUpdate,
          Permission.DeleteStudentAccountUpdate,
          Permission.ApproveStudentAccountVerificationUpdate,
          Permission.ReapproveStudentAccountVerificationUpdate,
          Permission.CreateStudentAccountUpdate,
        ]
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.accountSystem}/${routesConfig.accountRegistrationReport}`}
        >
          Báo cáo đăng ký tài khoản
        </SafeLink>,
        `/${routesConfig.accountSystem}/${routesConfig.accountRegistrationReport}`,
        undefined,
        undefined,
        undefined,
        [Permission.StudentAccountOverviewView]
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.accountSystem}/${routesConfig.accountConfiguration}`}
        >
          Cấu hình tài khoản
        </SafeLink>,
        `/${routesConfig.accountSystem}/${routesConfig.accountConfiguration}`,
        undefined,
        undefined,
        undefined,
        [Permission.AccountSettingsUpdate]
      ),
    ],
    undefined,
    [
      Permission.ProcessRequestUpdate,
      Permission.LockUnlockFAAccountUpdate,
      Permission.ChangeFAAccountUpdate,
      Permission.DeleteFAAccountUpdate,
      Permission.CreateFAAccountUpdate,
      Permission.LockUnlockLecturerAccountUpdate,
      Permission.ChangeLecturerAccountUpdate,
      Permission.DeleteLecturerAccountUpdate,
      Permission.CreateLecturerAccountUpdate,
      Permission.LockUnlockMUAccountUpdate,
      Permission.ChangeMUAccountUpdate,
      Permission.DeleteMUAccountUpdate,
      Permission.CreateMUAccountUpdate,
      Permission.LockUnlockStudentAccountUpdate,
      Permission.ChangeStudentAccountUpdate,
      Permission.DeleteStudentAccountUpdate,
      Permission.ApproveStudentAccountVerificationUpdate,
      Permission.ReapproveStudentAccountVerificationUpdate,
      Permission.CreateStudentAccountUpdate,
      Permission.StudentAccountOverviewView,
    ]
  ),

  getItem(
    <SafeLink to={routesConfig.roleManagement}>Quản lý vai trò</SafeLink>,
    routesConfig.roleManagement,
    <ApartmentOutlined />,
    undefined,
    undefined,
    [
      Permission.AddRoleUpdate,
      Permission.EditRoleUpdate,
      Permission.DeleteRoleUpdate,
      Permission.AssignPermissionsUpdate,
      Permission.AssignAccountUpdate,
    ]
  ),

  getItem(
    "Tham gia khảo sát",
    "survey-student",
    <TeamOutlined />,
    [
      getItem(
        <SafeLink to="/survey-student/training-need">
          Khảo sát nhu cầu đào tạo
        </SafeLink>,
        "training-need",
        undefined,
        undefined,
        undefined,
        [Permission.CanAccessParticipateSurvey]
      ),
    ],
    undefined,
    [Permission.CanAccessParticipateSurvey]
  ),

  getItem(
    "Khảo sát nhu cầu đào tạo",
    "survey-training",
    <SnippetsOutlined />,
    [
      getItem(
        <SafeLink to={`/survey-training/list-survey`}>
          Quản lý đợt khảo sát
        </SafeLink>,
        `/survey-training/list-survey`,
        undefined,
        undefined,
        undefined,
        [Permission.CanAccessManageSurvey]
      ),
      getItem(
        <SafeLink to={`/survey-training/browse-survey`}>
          Duyệt khảo sát
        </SafeLink>,
        `/survey-training/browse-survey`,
        undefined,
        undefined,
        undefined,
        [Permission.CanAccessApprovalSurvey]
      ),
      getItem(
        <SafeLink to={`/survey-training/${routesConfig.surverList}`}>
          Tổng hợp kết quả khảo sát
        </SafeLink>,
        `/survey-training/${routesConfig.surverList}`,
        undefined,
        undefined,
        undefined,
        [Permission.CanAccessResultSurvey]
      ),
    ],
    undefined,
    [
      Permission.CanAccessManageSurvey,
      Permission.CanAccessResultSurvey,
      Permission.CanAccessApprovalSurvey,
    ]
  ),

  getItem(
    "Kế hoạch xây dựng khóa học",
    "course-plan",
    <ScheduleOutlined />,
    [
      getItem(
        <SafeLink
          to={`/${routesConfig.coursePlan}/${routesConfig.registerCoursePlan}/${routesConfig.moocCourseConstructionPlan}`}
        >
          Đăng ký kế hoạch
        </SafeLink>,
        `/${routesConfig.coursePlan}/${routesConfig.registerCoursePlan}/${routesConfig.moocCourseConstructionPlan}`,
        undefined,
        undefined,
        undefined,
        [Permission.CanAccessRegisterCoursePlan]
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.coursePlan}/${routesConfig.browseSubscriptionPlans}`}
        >
          Duyệt đăng ký kế hoạch
        </SafeLink>,
        `/${routesConfig.coursePlan}/${routesConfig.browseSubscriptionPlans}`,
        undefined,
        undefined,
        undefined,
        [Permission.CanAccessApprovalRegisterCoursePlan]
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.coursePlan}/${routesConfig.planningAssignment}`}
        >
          Phân công lập kế hoạch
        </SafeLink>,
        `/${routesConfig.coursePlan}/${routesConfig.planningAssignment}`,
        undefined,
        undefined,
        undefined,
        [Permission.CanAccessCoursePlanAssignment]
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.coursePlan}/${routesConfig.organizeCoursePlan}/${routesConfig.planningCourseConstruction}`}
        >
          Lập kế hoạch
        </SafeLink>,
        `/${routesConfig.coursePlan}/${routesConfig.organizeCoursePlan}/${routesConfig.planningCourseConstruction}`,
        undefined,
        undefined,
        undefined,
        [Permission.CanAccessCoursePlanning]
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.coursePlan}/${routesConfig.planningApproval}`}
        >
          Duyệt lập kế hoạch
        </SafeLink>,
        `/${routesConfig.coursePlan}/${routesConfig.planningApproval}`,
        undefined,
        undefined,
        undefined,
        [Permission.CanAccessPlanningApproval]
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.coursePlan}/${routesConfig.specializedGroupList}`}
        >
          Lập tổ chuyên môn
        </SafeLink>,
        `/${routesConfig.coursePlan}/${routesConfig.specializedGroupList}`,
        undefined,
        undefined,
        undefined,
        [Permission.CanAccessSpecializedGroup]
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.coursePlan}/${routesConfig.organizeAppraisalBoard}`}
        >
          Lập hội đồng thẩm định
        </SafeLink>,
        `/${routesConfig.coursePlan}/${routesConfig.organizeAppraisalBoard}`,
        undefined,
        undefined,
        undefined,
        [Permission.CanAccessAppraisalBoard]
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.coursePlan}/${routesConfig.submitApprovalPlan}`}
        >
          Gửi phê duyệt kế hoạch
        </SafeLink>,
        `/${routesConfig.coursePlan}/${routesConfig.submitApprovalPlan}`,
        undefined,
        undefined,
        undefined,
        [Permission.CanAccessApprovalCoursePlanningSending]
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.coursePlan}/${routesConfig.submitAppraisalPlan}`}
        >
          Gửi thẩm định kế hoạch
        </SafeLink>,
        `/${routesConfig.coursePlan}/${routesConfig.submitAppraisalPlan}`,
        undefined,
        undefined,
        undefined,
        [Permission.CanAccessSubmitPlanAppraisal]
      ),
      getItem(
        <SafeLink to={`/${routesConfig.coursePlan}/${routesConfig.approval}`}>
          Phê duyệt kế hoạch
        </SafeLink>,
        `/${routesConfig.coursePlan}/${routesConfig.approval}`,
        undefined,
        undefined,
        undefined,
        [Permission.CanAccessApprovalCoursePlan]
      ),
      getItem(
        <SafeLink to={`/${routesConfig.coursePlan}/${routesConfig.appraisal}`}>
          Thẩm định kế hoạch
        </SafeLink>,
        `/${routesConfig.coursePlan}/${routesConfig.appraisal}`,
        undefined,
        undefined,
        undefined,
        [Permission.CanAccessPlanAppraisal]
      ),
    ],
    undefined,
    [
      Permission.CanAccessRegisterCoursePlan,
      Permission.CanAccessApprovalRegisterCoursePlan,
      Permission.CanAccessCoursePlanAssignment,
      Permission.CanAccessCoursePlanning,
      Permission.CanAccessPlanningApproval,
      Permission.CanAccessSpecializedGroup,
      Permission.CanAccessAppraisalBoard,
      Permission.CanAccessApprovalCoursePlanningSending,
      Permission.CanAccessSubmitPlanAppraisal,
      Permission.CanAccessApprovalCoursePlan,
      Permission.CanAccessPlanAppraisal,
    ]
  ),

  getItem(
    "Xây dựng khoá học",
    "course-construction",
    <BookOutlined />,
    [
      //   getItem(<SafeLink to={`/course-construction/${routesConfig.courseConstructionManagement}`}>
      //   Quản lý xây dựng khoá học
      // </SafeLink>, `/course-construction/${routesConfig.courseConstructionManagement}`),
      getItem(
        <SafeLink
          to={`/course-construction/${routesConfig.assignCourseConstruction}`}
        >
          Phân công xây dựng khoá học
        </SafeLink>,
        `/course-construction/${routesConfig.assignCourseConstruction}`,
        undefined,
        undefined,
        undefined,
        [Permission.AssignCourseConstructionUpdate]
      ),
      getItem(
        <SafeLink
          to={`/course-construction/${routesConfig.contentConstruction}`}
        >
          Xây dựng nội dung
        </SafeLink>,
        `/course-construction/${routesConfig.contentConstruction}`,
        undefined,
        undefined,
        undefined,
        [Permission.ManageCourseStructureUpdate]
      ),
      getItem(
        "Kiểm định nội dung",
        "course-browse",
        null,
        [
          getItem(
            <SafeLink
              to={`/course-construction/course-browse/${routesConfig.contentBrowse}`}
            >
              QTKH duyệt
            </SafeLink>,
            `/course-construction/course-browse/${routesConfig.contentBrowse}`,
            undefined,
            undefined,
            undefined,
            [Permission.ReviewCoursePresentationPageUpdate]
          ),
          getItem(
            <SafeLink
              to={`/course-construction/course-browse/${routesConfig.courseEvaluation}`}
            >
              Hội đồng thẩm định
            </SafeLink>,
            `/course-construction/course-browse/${routesConfig.courseEvaluation}`,
            undefined,
            undefined,
            undefined,
            [Permission.AssessCourseContentUpdate]
          ),
          getItem(
            <SafeLink
              to={`/course-construction/course-browse/${routesConfig.courseApproval}`}
            >
              Tổ chuyên môn duyệt
            </SafeLink>,
            `/course-construction/${routesConfig.courseApproval}`,
            undefined,
            undefined,
            undefined,
            [Permission.ApproveCourseContentUpdate]
          ),
          getItem(
            <SafeLink
              to={`/course-construction/course-browse/${routesConfig.publishCourse}`}
            >
              Xuất bản khoá học
            </SafeLink>,
            `/course-construction/${routesConfig.publishCourse}`,
            undefined,
            undefined,
            undefined,
            [Permission.PublishCourseUpdate]
          ),
        ],
        undefined,
        undefined
      ),

      getItem(
        <SafeLink to={`/course-construction/${routesConfig.surveyPlan}`}>
          Kế hoạch khảo sát
        </SafeLink>,
        `/course-construction/${routesConfig.surveyPlan}`,
        undefined,
        undefined,
        undefined,
        undefined
      ),
    ],
    undefined,
    [
      Permission.AssignCourseConstructionUpdate,
      Permission.ManageCourseStructureUpdate,
      Permission.ReviewCoursePresentationPageUpdate,
      Permission.AssessCourseContentUpdate,
      Permission.ApproveCourseContentUpdate,
      Permission.PublishCourseUpdate,
    ]
  ),

  getItem("Thư viện", "course-library", <SettingOutlined />, [
    getItem(
      <SafeLink to={`/course-library/${routesConfig.bankExam}`}>
        Ngân hàng đề
      </SafeLink>,
      `/course-library/${routesConfig.bankExam}`,
      undefined,
      undefined,
      undefined
    ),
    getItem(
      <SafeLink to={`/course-library/${routesConfig.bankQuestion}`}>
        Ngân hàng câu hỏi
      </SafeLink>,
      `/course-library/${routesConfig.bankQuestion}`,
      undefined,
      undefined,
      undefined
    ),
    getItem(
      <SafeLink to={`/course-library/${routesConfig.feedbackSample}`}>
        Mẫu khảo sát
      </SafeLink>,
      `/course-library/${routesConfig.feedbackSample}`,
      undefined,
      undefined,
      undefined
    ),
    getItem(
      "Kho đề cá nhân",
      "personal-exam-repository",
      null,
      [
        getItem(
          <SafeLink
            to={`/course-library/personal-exam-repository/${routesConfig.listExamRepository}`}
          >
            Danh sách đề
          </SafeLink>,
          `/course-library/personal-exam-repository/${routesConfig.listExamRepository}`,
          undefined,
          undefined,
          undefined
        ),
        getItem(
          <SafeLink
            to={`/course-library/personal-exam-repository/${routesConfig.listQuestionRepository}`}
          >
            Danh sách câu hỏi
          </SafeLink>,
          `/course-library/personal-exam-repository/${routesConfig.listQuestionRepository}`,
          undefined,
          undefined,
          undefined
        ),
      ],
      undefined
    ),
    getItem(
      "Kiểm duyệt nội dung cấp Bộ",
      "ministry-content-moderation",
      null,
      [
        getItem(
          <SafeLink
            to={`/course-library/${routesConfig.ministryContentModeration}/${routesConfig.listExamRepository}`}
          >
            Danh sách đề
          </SafeLink>,
          `/course-library/${routesConfig.ministryContentModeration}/${routesConfig.listExamRepository}`,
          undefined,
          undefined,
          undefined
        ),
        getItem(
          <SafeLink
            to={`/course-library/${routesConfig.ministryContentModeration}/${routesConfig.listQuestionRepository}`}
          >
            Danh sách câu hỏi
          </SafeLink>,
          `/course-library/${routesConfig.ministryContentModeration}/${routesConfig.listQuestionRepository}`,
          undefined,
          undefined,
          undefined
        ),
      ],
      undefined
    ),
    getItem(
      "Kiểm duyệt nội dung cấp Trường",
      "university-content-moderation",
      null,
      [
        getItem(
          <SafeLink
            to={`/course-library/${routesConfig.universityContentModeration}/${routesConfig.listExamRepository}`}
          >
            Danh sách đề
          </SafeLink>,
          `/course-library/${routesConfig.universityContentModeration}/${routesConfig.listExamRepository}`,
          undefined,
          undefined,
          undefined
        ),
        getItem(
          <SafeLink
            to={`/course-library/${routesConfig.universityContentModeration}/${routesConfig.listQuestionRepository}`}
          >
            Danh sách câu hỏi
          </SafeLink>,
          `/course-library/${routesConfig.universityContentModeration}/${routesConfig.listQuestionRepository}`,
          undefined,
          undefined,
          undefined
        ),
      ],
      undefined
    ),
  ]),

  getItem(
    <SafeLink to={`/${routesConfig.portalAdministration}`}>
      Quản trị cổng thông tin
    </SafeLink>,
    routesConfig.portalAdministration,
    <GlobalOutlined />,
    undefined,
    undefined,
    undefined
  ),

  getItem(
    "Cấu hình",
    "configuration",
    <ControlOutlined />,
    [
      //   getItem(<SafeLink to={`/course-construction/${routesConfig.courseConstructionManagement}`}>
      //   Quản lý xây dựng khoá học
      // </SafeLink>, `/course-construction/${routesConfig.courseConstructionManagement}`),
      getItem(
        <SafeLink to={`/configuration/${routesConfig.approvalConfiguration}`}>
          Cấu hình cấp phê duyệt
        </SafeLink>,
        `/configuration/${routesConfig.approvalConfiguration}`
      ),
    ],
    undefined,
    undefined
  ),

  getItem(
    "Quản lý danh mục",
    "category-management",
    <FolderOpenOutlined />,
    [
      getItem(
        <SafeLink
          to={`/category-management/${routesConfig.sharedCategoriesManagement}`}
        >
          Quản lý danh mục dùng chung
        </SafeLink>,
        `/category-management/${routesConfig.sharedCategoriesManagement}`,
        undefined,
        undefined,
        undefined,
        [Permission.CommonCategoryManagementUpdate]
      ),
      getItem(
        <SafeLink
          to={`/category-management/${routesConfig.portfolioValueManagement}`}
        >
          Quản lý giá trị danh mục
        </SafeLink>,
        `/category-management/${routesConfig.portfolioValueManagement}`,
        undefined,
        undefined,
        undefined,
        [Permission.CategoryValueManagementUpdate]
      ),
      getItem(
        <SafeLink
          to={`/category-management/${routesConfig.universityManagement}`}
        >
          Quản lý Trường đại học
        </SafeLink>,
        `/category-management/${routesConfig.universityManagement}`,
        undefined,
        undefined,
        undefined,
        [Permission.UniversityManagementUpdate]
      ),
      getItem(
        <SafeLink
          to={`/category-management/${routesConfig.lecturerManagement}`}
        >
          Quản lý giảng viên
        </SafeLink>,
        `/category-management/${routesConfig.lecturerManagement}`,
        undefined,
        undefined,
        undefined,
        [Permission.LecturerManagementUpdate]
      ),
      getItem(
        <SafeLink to={`/category-management/${routesConfig.studentManagement}`}>
          Quản lý sinh viên
        </SafeLink>,
        `/category-management/${routesConfig.studentManagement}`,
        undefined,
        undefined,
        undefined,
        [Permission.StudentManagementUpdate]
      ),
      getItem(
        <SafeLink
          to={`/category-management/${routesConfig.ministryUsersManagement}`}
        >
          Quản lý người dùng cấp bộ
        </SafeLink>,
        `/category-management/${routesConfig.ministryUsersManagement}`,
        undefined,
        undefined,
        undefined,
        [Permission.MinistryUserManagementUpdate]
      ),
    ],
    undefined,
    [
      Permission.CommonCategoryManagementUpdate,
      Permission.CategoryValueManagementUpdate,
      Permission.UniversityManagementUpdate,
      Permission.LecturerManagementUpdate,
      Permission.StudentManagementUpdate,
      Permission.MinistryUserManagementUpdate,
    ]
  ),

  getItem("Quản trị blog & diễn đàn", "blog-and-forum", <FormOutlined />, [
    getItem(
      <SafeLink
        to={`/${routesConfig.blogAndForum}/${routesConfig.blogContent}`}
      >
        Quản trị nội dung blog
      </SafeLink>,
      `/${routesConfig.blogAndForum}/${routesConfig.blogContent}`,
      undefined,
      undefined,
      undefined,
      [Permission.BlogForumManagementBlogContentUpdate]
    ),
    getItem(
      <SafeLink
        to={`/${routesConfig.blogAndForum}/${routesConfig.designThemeBlogContent}`}
      >
        Thiết lập giao diện
      </SafeLink>,
      `/${routesConfig.blogAndForum}/${routesConfig.designThemeBlogContent}`,
      undefined,
      undefined,
      undefined,
      [Permission.BlogForumManagementSetupInterfaceUpdate]
    ),
    getItem(
      <SafeLink
        to={`/${routesConfig.blogAndForum}/${routesConfig.manageContentForum}`}
      >
        Quản lý chủ đề diễn đàn
      </SafeLink>,
      `/${routesConfig.blogAndForum}/${routesConfig.manageContentForum}`,
      undefined,
      undefined,
      undefined,
      [Permission.BlogForumManagementForumTopicsUpdate]
    ),
  ]),

  getItem(
    <SafeLink to={`/${routesConfig.adminInterface}`}>
      Thiết lập giao diện quản trị
    </SafeLink>,
    `/${routesConfig.adminInterface}`,
    <LayoutOutlined />,
    undefined,
    undefined,
    [Permission.InterfaceSetupAdminUpdate]
  ),

  getItem(
    <SafeLink to={routesConfig.certificate}>
      Thiết lập chứng chỉ/chứng nhận
    </SafeLink>,
    routesConfig.certificate,
    <SettingOutlined />,
    undefined,
    undefined,
    [Permission.CertificateSetupUpdate]
  ),

  getItem(
    "Quản lý kết nối hệ thống",
    routesConfig.managementData,
    <SettingFilled />,
    [
      getItem(
        <SafeLink
          to={`/${routesConfig.managementData}/${routesConfig.settingManagementData}`}
        >
          Thiết lập kết nối, chia sẻ dữ liệu
        </SafeLink>,
        `/${routesConfig.managementData}/${routesConfig.settingManagementData}`,
        undefined,
        undefined,
        undefined,
        [Permission.DataSharingSetupUpdate]
      ),
      getItem(
        <SafeLink
          to={`/${routesConfig.managementData}/${routesConfig.historyManagementData}`}
        >
          Lịch sử kết nối, chia sẻ dữ liệu
        </SafeLink>,
        `/${routesConfig.managementData}/${routesConfig.historyManagementData}`
      ),
    ],
    undefined,
    [Permission.DataSharingSetupUpdate]
  ),

  getItem(
    "Hệ thống gợi ý khoá học",
    "system-suggest-course",
    <BulbOutlined />,
    [
      getItem(
        <SafeLink
          to={`/system-suggest-course/${routesConfig.learningDocumentationSystem}`}
        >
          Hệ thống học liệu
        </SafeLink>,
        `/system-suggest-course/${routesConfig.learningDocumentationSystem}`
      ),
      getItem(
        <SafeLink
          to={`/system-suggest-course/${routesConfig.synthesisReportSystem}`}
        >
          Báo cáo tổng hợp
        </SafeLink>,
        `/system-suggest-course/${routesConfig.synthesisReportSystem}`,
        undefined,
        undefined,
        undefined,
        [Permission.SummaryReportUpdate]
      ),
      getItem(
        <SafeLink
          to={`/system-suggest-course/${routesConfig.listStudentSystem}`}
        >
          Danh sách sinh viên
        </SafeLink>,
        `/system-suggest-course/${routesConfig.listStudentSystem}`,
        undefined,
        undefined,
        undefined,
        [Permission.ListOfStudentsWithSimilarInterestsUpdate]
      ),
    ]
  ),

  getItem(
    "Mô hình dự đoán xu hướng",
    "prediction-model",
    <FundOutlined />,
    [
      getItem(
        <SafeLink
          to={`/prediction-model/${routesConfig.managementGroupContent}`}
        >
          Quản lý nhóm nội dung
        </SafeLink>,
        `/prediction-model/${routesConfig.managementGroupContent}`,
        undefined,
        undefined,
        undefined,
        [Permission.MenuContentGroupManagementUpdate]
      ),
      getItem(
        <SafeLink to={`/prediction-model/${routesConfig.settingPrediction}`}>
          Cài đặt phân tích
        </SafeLink>,
        `/prediction-model/${routesConfig.settingPrediction}`,
        undefined,
        undefined,
        undefined,
        [Permission.MenuAnalysisSettingUpdate]
      ),
    ],
    undefined,
    [
      Permission.MenuContentGroupManagementUpdate,
      Permission.MenuAnalysisSettingUpdate,
    ]
  ),
  getItem(
    <SafeLink to={`${routesConfig.scoringListCourse}`}>
      Quản lý chấm điểm
    </SafeLink>,
    routesConfig.scoringListCourse,
    <AuditOutlined />,
    undefined,
    undefined,
    [Permission.GradingManagementUpdate]
  ),
  getItem(
    <SafeLink to={`${routesConfig.notifications}`}>Quản lý thông báo</SafeLink>,
    routesConfig.notifications,
    <BellOutlined />,
    undefined,
    undefined,
    [Permission.NotificationManagementUpdate]
  ),
  getItem(
    "Cài đặt nâng cao",
    "setting-high-level",
    <SettingOutlined />,
    [
      getItem(
        <SafeLink to={`/setting-high-level/${routesConfig.settingIdentify}`}>
          Cài đặt định danh
        </SafeLink>,
        `/setting-high-level/${routesConfig.settingIdentify}`,
        undefined,
        undefined,
        undefined,
        [Permission.MenuIdentitySettingUpdate]
      ),
      getItem(
        <SafeLink
          to={`/setting-high-level/${routesConfig.controlQualityCourse}`}
        >
          Kiểm soát chất lượng khoá học
        </SafeLink>,
        `/setting-high-level/${routesConfig.controlQualityCourse}`,
        undefined,
        undefined,
        undefined,
        [Permission.CourseQualityControlUpdate]
      ),
      getItem(
        <SafeLink
          to={`/setting-high-level/${routesConfig.controlQualityVideo}`}
        >
          Kiểm soát chất lượng tiết học video
        </SafeLink>,
        `/setting-high-level/${routesConfig.controlQualityVideo}`,
        undefined,
        undefined,
        undefined,
        [Permission.MenuLessonQualityControlUpdate]
      ),
      // getItem(
      //   <SafeLink to={`/${routesConfig.scoringManagement}`}>
      //     Quản lý bài thi
      //   </SafeLink>,
      //   `/${routesConfig.scoringManagement}`,
      //   undefined,
      //   undefined,
      //   undefined,
      //   undefined
      // ),
      getItem(
        <SafeLink
          to={`/setting-high-level/${routesConfig.managementRoleCourse}`}
        >
          Phân quyền theo khóa học
        </SafeLink>,
        `/setting-high-level/${routesConfig.managementRoleCourse}`,
        undefined,
        undefined,
        undefined,
        [Permission.MenuPermissionByCourseUpdate]
      ),
      getItem(
        <SafeLink to={`/setting-high-level/${routesConfig.logsAudit}`}>
          Nhật ký người dùng
        </SafeLink>,
        `/setting-high-level/${routesConfig.logsAudit}`,
        undefined,
        undefined,
        undefined,
        [Permission.MenuActivityLogUpdate]
      ),
    ],
    undefined,
    [
      Permission.MenuIdentitySettingUpdate,
      Permission.CourseQualityControlUpdate,
      Permission.MenuLessonQualityControlUpdate,
      Permission.MenuPermissionByCourseUpdate,
      Permission.MenuActivityLogUpdate,
    ]
  ),
  getItem(
    "Quản lý khoá học",
    `${routesConfig.courseManagement}`,
    <ReadOutlined />,
    [
      getItem(
        <SafeLink to={`${routesConfig.courseManagement}/course-list`}>
          Danh sách khoá học
        </SafeLink>,
        `${routesConfig.courseManagement}/course-list`,
        undefined,
        undefined,
        undefined,
        [Permission.MenuCourseListUpdate]
      ),
      getItem(
        <SafeLink to={`/management-study/${routesConfig.settingRemindCheckIn}`}>
          Cài đặt điểm danh
        </SafeLink>,
        `/management-study/${routesConfig.settingRemindCheckIn}`,
        undefined,
        undefined,
        undefined,
        [Permission.MenuAttendanceSettingUpdate]
      ),
    ],
    undefined,
    [Permission.MenuCourseListUpdate, Permission.MenuAttendanceSettingUpdate]
  ),
  getItem(
    "Báo cáo thống kê",
    `${routesConfig.statisticalReport}`,
    <BarChartOutlined />,
    [
      getItem(
        <SafeLink to={`${routesConfig.statisticalReportForInstructors}`}>
          BCTK cho Giảng viên
        </SafeLink>,
        `${routesConfig.statisticalReportForInstructors}`,
        undefined,
        undefined,
        undefined,
        [Permission.LecturerStatisticsReportView]
      ),
      getItem(
        <SafeLink to={`${routesConfig.statisticalReportForMinistry}`}>
          BCTK dành cho cấp Bộ
        </SafeLink>,
        `${routesConfig.statisticalReportForMinistry}`,
        undefined,
        undefined,
        undefined,
        [Permission.MinistryStatisticsReportView]
      ),
      getItem(
        <SafeLink to={`${routesConfig.statisticalReportForUniversity}`}>
          BCTK dành cho Trường
        </SafeLink>,
        `${routesConfig.statisticalReportForUniversity}`,
        undefined,
        undefined,
        undefined,
        [Permission.SchoolStatisticsReportView]
      ),
    ],
    undefined,
    [
      Permission.LecturerStatisticsReportView,
      Permission.MinistryStatisticsReportView,
      Permission.SchoolStatisticsReportView,
    ]
  ),
  getItem(
    <a target="_blank" href={`https://mooc-ai-staging.tunnel.techainer.com`}>
      Quản lý trợ lý ảo
    </a>,
    "https://mooc-ai-staging.tunnel.techainer.com",
    <AndroidOutlined />
  ),
  getItem(
    <SafeLink to={`${routesConfig.enrollmentConfirmation}`}>
      Xác nhận ghi danh
    </SafeLink>,
    routesConfig.enrollmentConfirmation || routesConfig.trainingLocations,
    <ReconciliationOutlined />,
    undefined,
    undefined,
    [Permission.TransactionManagement]
  ),
];
