import { Col, Row, Empty, Spin, Button } from "antd";
import BarChart from "../../../../../../components/types-of-charts/BarChart";
import BarStackedChart from "../../../../../../components/types-of-charts/BarStackedChart";
import ColumnsChart from "../../../../../../components/types-of-charts/ColumnsChart";
import DonutChart from "../../../../../../components/types-of-charts/DonutChart";
import {
  BarStackedChartProps,
  PieChart,
} from "../../../../../../types/static-report";
import StatisticalReportCard from "../../StatisticalReportCard";
import useChartExport from "../useChartExport";
import React, { useEffect } from "react";
import { staticReportStore } from "../../../../../../stores/stores";

type Props = {
  loading: Record<string, boolean>;
  coursesByFormatList: PieChart[];
  sequenceByAvailableStatus: PieChart[];
  sequenceByDepartment: PieChart[];
  scormAndXAPI: PieChart[];
  multiMaterialsByType: PieChart[];
  unitByCourse: BarStackedChartProps[];
  testByType: PieChart[];
  referenceSourceByType: PieChart[];
  courseAssignTeacher: BarStackedChartProps[];
};

const ResourcesChartTab = React.memo((props: Props) => {
  const {
    loading,
    coursesByFormatList,
    sequenceByAvailableStatus,
    sequenceByDepartment,
    scormAndXAPI,
    multiMaterialsByType,
    unitByCourse,
    testByType,
    referenceSourceByType,
    courseAssignTeacher,
  } = props;

  // Hook for managing chart exports
  const { createChartRef, handleExportImage, handleExportAllImagesAsZip } =
    useChartExport();

  const { tab, setHandleExportAllImagesAsZip, exportType } =
    staticReportStore();

  useEffect(() => {
    setHandleExportAllImagesAsZip(() => handleExportAllImagesAsZip(tab));
  }, [tab, exportType]);

  return (
    <div>
      {/* Section for course statistics */}
      <div className="chart-title">Thống kê số lượng khoá học</div>
      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Số lượng khoá học theo phân công"
            content={
              <Spin spinning={loading.courseAssignTeacher}>
                {courseAssignTeacher.length > 0 ? (
                  <ColumnsChart
                    data={courseAssignTeacher}
                    colors={["#69C0FF", "#FFC069"]}
                    ref={createChartRef(
                      tab,
                      "Số lượng khoá học theo phân công"
                    )}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={
              courseAssignTeacher.length > 0
                ? [
                    {
                      label: "Hình ảnh (PNG)",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Số lượng khoá học theo phân công",
                          "image/png"
                        ),
                    },
                    {
                      label: "PDF",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Số lượng khoá học theo phân công",
                          "application/pdf"
                        ),
                    },
                  ]
                : []
            }
          />
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Số lượng khoá học theo hình thức xuất bản"
            content={
              <Spin spinning={loading.coursesByFormatList}>
                {coursesByFormatList.length > 0 ? (
                  <DonutChart
                    data={coursesByFormatList}
                    title=""
                    colors={["#5CDBD3", "#FF7875", "#FFC069"]}
                    height={280}
                    ref={createChartRef(
                      tab,
                      "Số lượng khoá học theo hình thức xuất bản"
                    )}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={
              coursesByFormatList.length > 0
                ? [
                    {
                      label: "Hình ảnh (PNG)",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Số lượng khoá học theo hình thức xuất bản",
                          "image/png"
                        ),
                    },
                    {
                      label: "PDF",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Số lượng khoá học theo hình thức xuất bản",
                          "application/pdf"
                        ),
                    },
                  ]
                : []
            }
          />
        </Col>
      </Row>

      {/* Section for lecture statistics */}
      <div className="chart-title">Thống kê số lượng bài giảng</div>
      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Số lượng bài giảng theo khoa"
            content={
              <Spin spinning={loading.sequenceByDepartment}>
                {sequenceByDepartment.length > 0 ? (
                  <BarChart
                    data={sequenceByDepartment}
                    colors={["#69C0FF"]}
                    ref={createChartRef(tab, "Số lượng bài giảng theo khoa")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={
              sequenceByDepartment.length > 0
                ? [
                    {
                      label: "Hình ảnh (PNG)",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Số lượng bài giảng theo khoa",
                          "image/png"
                        ),
                    },
                    {
                      label: "PDF",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Số lượng bài giảng theo khoa",
                          "application/pdf"
                        ),
                    },
                  ]
                : []
            }
          />
        </Col>
        <Col sm={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Số lượng bài giảng theo trạng thái xuất bản"
            content={
              <Spin spinning={loading.sequenceByAvailableStatus}>
                {sequenceByAvailableStatus.length > 0 ? (
                  <DonutChart
                    data={sequenceByAvailableStatus}
                    title=""
                    colors={["#FFC069", "#FF7875", "#5CDBD3"]}
                    height={280}
                    ref={createChartRef(
                      tab,
                      "Số lượng bài giảng theo trạng thái xuất bản"
                    )}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={
              sequenceByAvailableStatus.length > 0
                ? [
                    {
                      label: "Hình ảnh (PNG)",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Số lượng bài giảng theo trạng thái xuất bản",
                          "image/png"
                        ),
                    },
                    {
                      label: "PDF",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Số lượng bài giảng theo trạng thái xuất bản",
                          "application/pdf"
                        ),
                    },
                  ]
                : []
            }
          />
        </Col>
      </Row>

      {/* Section for materials statistics */}
      <div className="chart-title">Thống kê số lượng học liệu</div>
      <Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
        <Col xl={{ span: 6 }} lg={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Số lượng bài kiểm tra theo loại"
            content={
              <Spin spinning={loading.testByType}>
                {testByType.length > 0 ? (
                  <DonutChart
                    data={testByType}
                    title=""
                    colors={["#5CDBD3", "#FFC069", "#FF7875"]}
                    height={280}
                    ref={createChartRef(tab, "Số lượng bài kiểm tra theo loại")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={
              testByType.length > 0
                ? [
                    {
                      label: "Hình ảnh (PNG)",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Số lượng bài kiểm tra theo loại",
                          "image/png"
                        ),
                    },
                    {
                      label: "PDF",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Số lượng bài kiểm tra theo loại",
                          "application/pdf"
                        ),
                    },
                  ]
                : []
            }
          />
        </Col>
        <Col xl={{ span: 6 }} lg={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Số lượng tài liệu tham khảo theo loại"
            content={
              <Spin spinning={loading.referenceSourceByType}>
                {referenceSourceByType.length > 0 ? (
                  <DonutChart
                    data={referenceSourceByType}
                    title=""
                    colors={["#FFC069", "#FF7875", "#5CDBD3"]}
                    height={280}
                    ref={createChartRef(
                      tab,
                      "Số lượng tài liệu tham khảo theo loại"
                    )}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={
              referenceSourceByType.length > 0
                ? [
                    {
                      label: "Hình ảnh (PNG)",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Số lượng tài liệu tham khảo theo loại",
                          "image/png"
                        ),
                    },
                    {
                      label: "PDF",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Số lượng tài liệu tham khảo theo loại",
                          "application/pdf"
                        ),
                    },
                  ]
                : []
            }
          />
        </Col>
        <Col xl={{ span: 6 }} lg={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Số lượng Scorm & xAPI"
            content={
              <Spin spinning={loading.scormAndXAPI}>
                {scormAndXAPI.length > 0 ? (
                  <DonutChart
                    data={scormAndXAPI}
                    title=""
                    colors={["#5CDBD3", "#FFC069"]}
                    height={280}
                    ref={createChartRef(tab, "Số lượng Scorm & xAPI")}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={
              scormAndXAPI.length > 0
                ? [
                    {
                      label: "Hình ảnh (PNG)",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Số lượng Scorm & xAPI",
                          "image/png"
                        ),
                    },
                    {
                      label: "PDF",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Số lượng Scorm & xAPI",
                          "application/pdf"
                        ),
                    },
                  ]
                : []
            }
          />
        </Col>
        <Col xl={{ span: 6 }} lg={{ span: 12 }} xs={{ span: 24 }}>
          <StatisticalReportCard
            chartName="Số lượng học liệu đa phương tiện theo loại"
            content={
              <Spin spinning={loading.multiMaterialsByType}>
                {multiMaterialsByType.length > 0 ? (
                  <DonutChart
                    data={multiMaterialsByType}
                    title=""
                    colors={["#69C0FF", "#FFC069", "#FF7875", "#5CDBD3"]}
                    height={280}
                    ref={createChartRef(
                      tab,
                      "Số lượng học liệu đa phương tiện theo loại"
                    )}
                  />
                ) : (
                  <Empty description="Không có dữ liệu" />
                )}
              </Spin>
            }
            exportOptions={
              multiMaterialsByType.length > 0
                ? [
                    {
                      label: "Hình ảnh (PNG)",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Số lượng học liệu đa phương tiện theo loại",
                          "image/png"
                        ),
                    },
                    {
                      label: "PDF",
                      action: () =>
                        handleExportImage(
                          tab,
                          "Số lượng học liệu đa phương tiện theo loại",
                          "application/pdf"
                        ),
                    },
                  ]
                : []
            }
          />
        </Col>
      </Row>
      <div className="mt-2">
        <StatisticalReportCard
          chartName="Số lượng học liệu theo khoá học"
          content={
            <Spin spinning={loading.unitByCourse}>
              {unitByCourse.length > 0 ? (
                <BarStackedChart
                  data={unitByCourse}
                  colors={["#69C0FF", "#FFC069", "#FF7875", "#5CDBD3"]}
                  ref={createChartRef(tab, "Số lượng học liệu theo khoá học")}
                />
              ) : (
                <Empty description="Không có dữ liệu" />
              )}
            </Spin>
          }
          exportOptions={
            unitByCourse.length > 0
              ? [
                  {
                    label: "Hình ảnh (PNG)",
                    action: () =>
                      handleExportImage(
                        tab,
                        "Số lượng học liệu theo khoá học",
                        "image/png"
                      ),
                  },
                  {
                    label: "PDF",
                    action: () =>
                      handleExportImage(
                        tab,
                        "Số lượng học liệu theo khoá học",
                        "application/pdf"
                      ),
                  },
                ]
              : []
          }
        />
      </div>
    </div>
  );
});

export default ResourcesChartTab;
