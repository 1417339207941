// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-submit-review .container-form {
  margin-bottom: 14px; }
  .form-submit-review .container-form .container-form-title {
    margin-bottom: 14px; }
  .form-submit-review .container-form .ant-picker {
    margin-right: 8px; }
  .form-submit-review .container-form .form-input {
    margin-top: 8px; }
`, "",{"version":3,"sources":["webpack://./src/components/course-library/personal-exam-repository/list-exam/submit-review/submitReview.scss"],"names":[],"mappings":"AAAA;EAEM,mBAAmB,EAAA;EAFzB;IAKQ,mBAAmB,EAAA;EAL3B;IASQ,iBAAiB,EAAA;EATzB;IAaQ,eAAe,EAAA","sourcesContent":[".form-submit-review {\n    .container-form {\n      margin-bottom: 14px;\n  \n      .container-form-title {\n        margin-bottom: 14px;\n      }\n  \n      .ant-picker {\n        margin-right: 8px; // Khoảng cách giữa DatePicker và TimePicker\n      }\n\n      .form-input{\n        margin-top: 8px;\n      }\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
