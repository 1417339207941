import {
  Dropdown,
  Flex,
  Form,
  Layout,
  MenuProps,
  Space,
  TableColumnsType,
  TableProps,
  Typography,
} from "antd";
import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useNavigate } from "react-router-dom";

import {
  InfoCircleOutlined,
  MoreOutlined,
  SearchOutlined,
  TagsOutlined,
} from "@ant-design/icons";

import { isEmpty } from "lodash";
import FormInput from "../../../components/form-input/FormInput";
import PageHeader from "../../../components/page-header/PageHeader";
import TableData from "../../../components/table-data/TableData";
import { routesConfig } from "../../../config/routes";
import { getAllStudentFavorite } from "../../../service/system-suggest-course";

type ListStudentSystemProps = {
  title?: string;
};

const ListStudentSystem = ({ title }: ListStudentSystemProps) => {
  const navigate = useNavigate();

  const [listStudentSystem, setListStudentSystem] = useState<any[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");

  const fetchData = async (searchParam: any) => {
    try {
      const response = await getAllStudentFavorite(searchParam);
      const { data } = response.data;
      setListStudentSystem(data?.data);
      setTotalPage(data?.count?._count?.id);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    console.log({pageNumber});
    
    fetchData({
      name: searchValue,
      tags: [],
      take: pageSize,
      skip: (pageNumber - 1) * pageSize,
    });
  }, [pageSize, pageNumber]);

  const dataColumnsReportCheckIn: TableColumnsType<any> = [
    {
      title: "Mã người dùng",
      dataIndex: "id",
      key: "id",
      width: 10,
    },
    {
      title: "Tên người dùng",
      dataIndex: "name",
      key: "name",
      width: 15,
      render: (_: any, record) => {
        return (
          <Flex className="table-order-course-name">
            <img
              className=""
              loading="eager"
              alt=""
              src={record?.image || "/images/ic_school_placeholder.svg"}
            />
            <div className="pl-2">
              <span className="course-title">
                {record?.auth_userprofile?.name}
              </span>
            </div>
          </Flex>
        );
      },
      sorter: {
        compare: (a, b) => a.auth_userprofile?.name.length - b.auth_userprofile?.name.length,
      },
    },
    {
      title: "Thẻ tag",
      dataIndex: "tags",
      key: "tags",
      width: 25,
      render: (_, data: any) => {
        return (
          <div>
            {!isEmpty(data?.mooc_student_favorite) ? (
              <Flex align="center">
                {data?.mooc_student_favorite?.map((data: any, index: number) => {
                  if (index <= 2) {
                    return (
                      <div className="border rounded-md bg-[#FAFAFA] px-2 mr-1">
                        {data?.mooc_recommendation_tag?.name}
                      </div>
                    );
                  }
                })}
                {data?.mooc_student_favorite?.length > 3 && (
                  <div className="border rounded-md bg-[#FAFAFA] px-2 mr-1">
                    +{data?.mooc_student_favorite?.length - 3}
                  </div>
                )}
              </Flex>
            ) : (
              <div>Chưa có dữ liệu</div>
            )}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "action",
      key: "operation",
      render: (_, record) => {
        const idStudent = `${record?.id}`;
        const items: MenuProps["items"] = [
          {
            label: (
              <Flex
                onClick={() =>
                  navigate(
                    `/system-suggest-course/${routesConfig.favoriteStudentSystem}/${idStudent}`
                  )
                }
              >
                <TagsOutlined />
                <Typography.Text className="pl-2">Sở thích</Typography.Text>
              </Flex>
            ),
            key: "1",
          },
          {
            label: (
              <Flex
                onClick={() =>
                  navigate(
                    `/system-suggest-course/${routesConfig.infoSuggestSystem}/${idStudent}`
                  )
                }
              >
                <InfoCircleOutlined />
                <Typography.Text className="pl-2">
                  Thông tin hệ thống gợi ý
                </Typography.Text>
              </Flex>
            ),
            key: "2",
          },
        ];
        return (
          <Space>
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              className="action-table"
            >
              <a onClick={(e) => e.preventDefault()}>
                <MoreOutlined />
              </a>
            </Dropdown>
          </Space>
        );
      },
      fixed: "right",
      width: 8,
    },
  ];

  const defaultCheckedList = dataColumnsReportCheckIn.map(
    (item) => item.key as string
  );

  const [checkedList, setCheckedList] = useState(defaultCheckedList);

  const columnChecked = useMemo(() => {
    return dataColumnsReportCheckIn.map((item) => ({
      ...item,
    }));
  }, [checkedList]);

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setSearchValue(value);
    if (value.length === 0) {
      setSearchValue("");
    }
  };

  const handleSearch = async (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === "Enter") {
      const searchParam = {
        take: 10,
        skip: 0,
        name: searchValue,
        tags: [],
      };
      setPageNumber(1);
      fetchData(searchParam);
    }
  };

  const handleSubmitSearch = () => {
    const searchParam = {
      take: 10,
      skip: 0,
      name: searchValue,
      tags: [],
    };
    setPageNumber(1);
    fetchData(searchParam);
  };

  const handleTableChange: TableProps["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {};

  return (
    <Layout className="page-header-group">
      <Form.Provider>
        <div className={`page-header-group--layout`}>
          <div className="table-header--wrapper justify-between pb-6">
            <div className="table-header--left">
              <PageHeader title={title} />
            </div>
            <Flex>
              <div className="">
                <FormInput
                  placeholder="Nhập tên người dùng"
                  value={searchValue}
                  onChange={handleChangeInput}
                  onKeyPress={handleSearch}
                  afterPrefixIcon={
                    <SearchOutlined onClick={handleSubmitSearch} />
                  }
                />
              </div>
            </Flex>
          </div>
        </div>
        <TableData
          dataTable={listStudentSystem}
          dataColumns={columnChecked}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          pageSize={pageSize}
          pagination={true}
          loadingTable={loading}
          scroll={{ x: 1500, y: 600 }}
          totalRecords={totalPage}
          handleTableChangeProps={handleTableChange}
        />
      </Form.Provider>
    </Layout>
  );
};

export default ListStudentSystem;
